import './MyOpenTasks.scss'
import {getUserSignDocsAPI} from "../../Services/api";
import {filter, get, isEmpty, map, orderBy} from "lodash";
import {useContext, useEffect, useState} from "react";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {SignDocCard} from "../../Components/SignDoc/SignDocCard/SignDocCard";
import {CompanyReactContext} from "../../contexts/CompanyContext";
import {getAllSignDocTypes, SignDocTypes} from "../../Components/SignDoc/SignDocLib";
import {I18nReactContext} from "../../contexts/I18nContext";

export const MyOpenTasks = ({showForceCompletion = false, showNonForceCompletion = false}) => {
    const {i18n} = useContext(I18nReactContext);
    const [signDocsToDo, setSignDocsToDo] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const companyContext = useContext(CompanyReactContext)
    const initSignDocs = async () => {
        const userSignDocs = await getUserSignDocsAPI({
            types: getAllSignDocTypes(),
            showForceCompletion,
            showNonForceCompletion
        })
        const signDocsToDo = orderBy(filter(userSignDocs, (signDoc) => {
            if (signDoc.signDoc.doNotForceCompletion) {
                return true
            }
            if (get(signDoc, 'signDoc.type') === SignDocTypes.REGULAR_COURSE) {
                return signDoc.progressPercent < 100
            } else {
                return !signDoc.signed
            }
        }), ['finishByDate'], ['asc'])
        return {
            signDocsToDo
        }

    }
    const init = async () => {
        let promises = [initSignDocs]
        const promiseRes = await Promise.all(map(promises, (promise) => promise()))
        setSignDocsToDo(get(promiseRes, '[0].signDocsToDo'))

        setIsLoading(false)
    }
    //run init every 60 seconds
    useEffect(() => {
        if (isEmpty(companyContext.featureFlags)) {
            return
        }
        init()
        const interval = setInterval(() => {
            init()
        }, 60000);
        return () => clearInterval(interval);
    }, [companyContext.featureFlags]);

    if (isLoading) {
        return <LoadingSpinner/>
    }

    return <div className="MyOpenTasks">
        <div className="openTasksSection">
            {!signDocsToDo || signDocsToDo.length === 0 ?
                <div className="noOpenTasks">{i18n.translate('learningCenter.noOpenTasks')}</div> : ''}
            <div className="OpenTasksWrapper">
                {map(signDocsToDo, (item) => {
                    const finishByDate = new Date(item.finishByDate).toLocaleDateString('he-IL')
                    return <div className="signDocWrapper" key={item.id}>
                        <SignDocCard {...{...item, ...item.signDoc}} finishByDate={finishByDate} key={item.id}/>
                    </div>
                })}
            </div>

        </div>
    </div>
}