import React, {useContext} from 'react';

import './AlertDialog.scss'
import {Button} from "../../Form/Button/Button";
import {Modal} from "../Modal/Modal";
import {I18nReactContext} from "../../../contexts/I18nContext";

export const AlertTypesEnum = {
    INFO: "INFO",
    ERROR: "ERROR",
    WARNING: "WARNING"
}

export const AlertDialog = ({title, alertMessage, onOk, okText, classes = "", type}) => {
    const {i18n} = useContext(I18nReactContext);
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        onOk && onOk()
        setOpen(false);
    };
    const renderAlertSVG = (type) => {
        return <div></div>;
    }

    return open ? <Modal
            open={open}
            onClose={onOk}>
            <div className="AddOrganizationsModal modalWrapper">
                <div className="modalTitle">
                    <div>{title}</div>
                </div>
                <div className="modalContent">
                    {alertMessage}
                </div>
                <div className="modalFooter">
                    <Button onClick={handleClose} color={"primary"}
                            text={okText || i18n.translate('alertDialog.ok')}/>

                </div>
            </div>
        </Modal> : ''

}

