import {GeneralSettings} from "../GeneralSettings/GeneralSettings";
import {RoutingTabs} from "../../../Utils/RoutingTabs/RoutingTabs";
import {Reminders} from "../Reminders/Reminders";
import {AdminUsers} from "../AdminUsers/AdminUsers";
import {FeatureFlags} from "../../../Utils/Constants";
import {useContext} from "react";
import {I18nReactContext} from "../../../contexts/I18nContext";

export const SettingsTabs = () => {
    let {i18n} = useContext(I18nReactContext)
    const tabs = [
        {label: i18n.translate('settingsPage.generalSettings.title'), subPath: 'general', element: <GeneralSettings/>},
        {label: i18n.translate('settingsPage.reminders.title'), subPath: 'reminders', element: <Reminders/>},
        {
            label: i18n.translate('settingsPage.admins.title'),
            subPath: 'admins',
            element: <AdminUsers/>,
            requiredFeatureFlags: [FeatureFlags.ORG_ADMIN_MGMT]
        },
        // { label: i18n.translate('settingsPage.users.title'), subPath: 'users', element: <Box>Users</Box> },

    ]
    return <RoutingTabs tabs={tabs} baseRoute={'settings'}/>

}