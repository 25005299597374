import {Navigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {markDocAsViewedAPI, markSignDocAsCompleted} from "../../Services/api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {PdfViewer} from "../../Utils/PdfViewer/PdfViewer";
import {get} from "lodash";
import {PageTitle} from "../PageTitle/PageTitle";
import './SignDocPage.scss'
import SignatureCanvas from 'react-signature-canvas';
import {buttonColors} from "../Pickers/InputTheme";
import {Button} from "../Form/Button/Button";
import {signDocReadOrWatchMap, SignDocTypes} from "../SignDoc/SignDocLib";
import {VideoPlayer} from "../../Utils/VideoPlayer/VideoPlayer";
import {Confetti} from "../Smart/Confetti/Confetti";
import {getPageTitleAndSubTitle} from "./SignDocPageLib";
import CanvaEmbed from "../../Utils/Canva/CanvaEmbed";
import {I18nReactContext} from "../../contexts/I18nContext";


export const SignDocPage = () => {
    const {signDocId} = useParams();
    const {i18n} = useContext(I18nReactContext);
    const [isLoading, setIsLoading] = useState(true);
    const [signDoc, setSignDoc] = useState(null);
    const [isReadingCompleted, setIsReadingCompleted] = useState(false);
    const [signatureBase64Temp, setSignatureBase64Temp] = useState(null);
    const [afterServerCompleted, setAfterServerCompleted] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    let signatureCanvas = useRef()
    const init = async () => {
        const signDoc = await markDocAsViewedAPI({signDocId});
        setSignDoc(signDoc);
        setIsLoading(false);
    }

    useEffect(() => {
        init()
    }, []);

    useEffect(() => {
        if (showConfetti) {
            setTimeout(() => {
                setAfterServerCompleted(true)
            }, 5000)
        }
    }, [showConfetti]);

    if (isLoading) {
        return <LoadingSpinner/>
    }
    const onReadingCompleted = () => {
        setIsReadingCompleted(true)
    }

    const onEndDrawing = async () => {
        setSignatureBase64Temp(signatureCanvas.getTrimmedCanvas().toDataURL())
    };

    const onApproval = async () => {
        if (signatureBase64Temp && isReadingCompleted) {
            await markSignDocAsCompleted({signDocId, signatureBase64: signatureBase64Temp})
            setShowConfetti(true)
        }
    }

    const clearCanvas = () => {
        signatureCanvas.clear()
        setSignatureBase64Temp(null)
    }
    if (afterServerCompleted) {
        return <Navigate to={{pathname: '/learningCenter/myTasks'}} replace={true}/>
    }
    const signDocType = get(signDoc, 'signDoc.type')
    const doNotForceCompletion = get(signDoc, 'signDoc.doNotForceCompletion')
    const isSigned = get(signDoc, 'signed')
    const watchOrRead = signDocReadOrWatchMap[signDocType]
    const {title, subTitle} = getPageTitleAndSubTitle({signDoc, i18n})

    return <div className="SignDocPage MenuPage">
        <PageTitle size="minimized"
                   title={title}
                   subTitle={subTitle}/>
        <div className="menuInnerPage">
            <div className="docAndSignatureWrapper">
                <div className={`signDocSectionWrapper ${isSigned || doNotForceCompletion ? 'fullWidth' : ''}`}>
                    <div className={`signDocWrapper ${watchOrRead ? 'watch' : 'read'}`}>
                        {get(signDoc, 'signDoc.type') === SignDocTypes.READ_AND_SIGN_DOC_PDF ?
                            <PdfViewer url={get(signDoc, 'signDoc.signedUrl')}
                                       onReadingCompleted={onReadingCompleted}/> : ''}
                        {get(signDoc, 'signDoc.type') === SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK ?
                            <div>
                                <VideoPlayer videoUrl={get(signDoc, 'signDoc.linkUrl')}
                                             onWatchComplete={onReadingCompleted}/>
                            </div> : ''}
                        {get(signDoc, 'signDoc.type') === SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK ?
                            <div>
                                <VideoPlayer videoUrl={get(signDoc, 'signDoc.linkUrl')}
                                             onWatchComplete={onReadingCompleted}/>
                            </div> : ''}
                        {get(signDoc, 'signDoc.type') === SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK ?
                            <div>
                                <VideoPlayer videoUrl={get(signDoc, 'signDoc.linkUrl')}
                                             onWatchComplete={onReadingCompleted}/>
                            </div> : ''}{
                        get(signDoc, 'signDoc.type') === SignDocTypes.CANVA_PRESENTATION ?
                            <div className="canvaEmbedWrapper">
                                <CanvaEmbed canvaLink={get(signDoc, 'signDoc.linkUrl')}
                                            onReadingCompleted={onReadingCompleted}/>
                            </div> : ''}
                    </div>
                </div>
                {!isSigned && !doNotForceCompletion ? <div className="signatureWrapper">
                    <div
                        className="PageSecondaryTitle">{i18n.translate(`signDocPage.signatureTitle.${watchOrRead}`)}</div>
                    <div className="signatureSubtitle">
                        {isReadingCompleted ? i18n.translate(`signDocPage.signatureSubtitle.${watchOrRead}`) : i18n.translate(`signDocPage.signatureSubtitleNotCompleted.${watchOrRead}`)}
                    </div>
                    <div className="signature">
                        {isReadingCompleted ? <SignatureCanvas
                            onEnd={onEndDrawing}
                            ref={(ref) => {
                                signatureCanvas = ref;
                            }}
                            penColor="blue"
                            canvasProps={{
                                className: 'signature-canvas',
                            }}
                        /> : ''}
                    </div>
                    {isReadingCompleted ? <div className="docButtonsWrapper">
                        <Button color={buttonColors.GREY}
                                disabled={!isReadingCompleted || !signatureBase64Temp}
                                onClick={clearCanvas}
                                style={{width: '100%'}}
                                text={i18n.translate('signDocPage.clear')}/>
                        <Button color={buttonColors.GOLD}
                                disabled={!isReadingCompleted || !signatureBase64Temp}
                                onClick={onApproval}
                                style={{width: '100%'}}
                                text={i18n.translate(`signDocPage.iApproveReadingTheDoc.${watchOrRead}`)}/>

                    </div> : ''}

                </div> : ''}
            </div>
        </div>
        {!doNotForceCompletion ? <Confetti showConfetti={showConfetti}/> : ''}
    </div>
}



