export const SignDocTypes = {
    READ_AND_SIGN_DOC_PDF: 'READ_AND_SIGN_DOC_PDF',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'WATCH_AND_SIGN_YOUTUBE_LINK',
    WATCH_AND_SIGN_VIMEO_LINK: 'WATCH_AND_SIGN_VIMEO_LINK',
    WATCH_AND_SIGN_VIDEO_FILE: 'WATCH_AND_SIGN_VIDEO_FILE',
    REGULAR_COURSE: 'REGULAR_COURSE',
    CANVA_PRESENTATION: 'CANVA_PRESENTATION'
}

export const getAllSignDocTypes = () => {
    return Object.values(SignDocTypes)

}

export const signDocTypeToFeatureFlagMap = {
    READ_AND_SIGN_DOC_PDF: 'signDocsReadAndSignDocPdfFF',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'signDocsWatchAndSignYoutubeLinkFF',
    WATCH_AND_SIGN_VIMEO_LINK: 'signDocsWatchAndSignVimeoLinkFF',
    WATCH_AND_SIGN_VIDEO_FILE: 'signDocsWatchAndSignVideoFileFF',
    REGULAR_COURSE: 'regularCourseFF',
    CANVA_PRESENTATION: 'signDocCanvaPresentationFF'
}
export const signDocReadOrWatchMap = {
    READ_AND_SIGN_DOC_PDF: 'read',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'watch',
    WATCH_AND_SIGN_VIMEO_LINK: 'watch',
    WATCH_AND_SIGN_VIDEO_FILE: 'watch',
    CANVA_PRESENTATION: 'read'

}

export const SignDocIcons = {
    READ_AND_SIGN_DOC_PDF: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/signDocPDF.png`,
    WATCH_AND_SIGN_YOUTUBE_LINK: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`,
    WATCH_AND_SIGN_VIMEO_LINK: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`,
    WATCH_AND_SIGN_VIDEO_FILE: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`,
    CANVA_PRESENTATION: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocCanva.png`

}

export const isYoutubeLink = ({linkUrl}) => {
    return linkUrl.includes('youtube.com/watch') || linkUrl.includes('youtube.com/embed') || linkUrl.includes('youtu.be')
}
export const isVimeoLink = ({linkUrl}) => {
    return linkUrl.includes('vimeo.com')
}
export const isCanvaLink = ({linkUrl}) => {
    return linkUrl.includes('canva.com')
}

export const getSignDocPrefix = ({signDoc, i18n}) => {
    if (signDoc.type === SignDocTypes.REGULAR_COURSE) {
        return i18n.translate('settingsPage.reminders.course')
    } else if (signDoc.type === SignDocTypes.READ_AND_SIGN_DOC_PDF) {
        return i18n.translate('settingsPage.reminders.signDoc')
    } else if (signDoc.type === SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK || signDoc.type === SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK) {
        return i18n.translate('settingsPage.reminders.watchVideo')
    } else if (signDoc.type === SignDocTypes.CANVA_PRESENTATION) {
        return i18n.translate('settingsPage.reminders.canvaPresentation')
    }
}