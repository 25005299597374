import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Faq.scss'
import {useContext} from "react";
import {I18nReactContext} from "../../contexts/I18nContext";

export const FAQs = () => {
    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const {i18n} = useContext(I18nReactContext);
    return (
        <div className="faq-component">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {i18n.translate('faq.question1')}
                </AccordionSummary>
                <AccordionDetails>
                    {i18n.translate('faq.answer1')}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    {i18n.translate('faq.question2')}
                </AccordionSummary>
                <AccordionDetails>
                    {i18n.translate('faq.answer2')}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    {i18n.translate('faq.question3')}
                </AccordionSummary>
                <AccordionDetails>
                    {i18n.translate('faq.answer3')}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}