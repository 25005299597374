import './AppSideMenu.scss'
import {Link, useLocation} from "react-router-dom";
import {getMenuButtons} from "../Main/MenuButtons/MenuButtonsLib";
import {get, map} from "lodash";
import {useContext, useState} from "react";
import {UserReactContext} from "../../contexts/UserContext";
import personIcon from "../../assets/images/personIcon.png";
import {useMediaQuery} from 'react-responsive';
import {CompanyReactContext} from "../../contexts/CompanyContext";
import {I18nReactContext} from "../../contexts/I18nContext";
import {UserIconMenu} from "../UserIconMenu/UserIconMenu";

export const AppSideMenu = ({path, adminOrganizations}) => {
    const {i18n} = useContext(I18nReactContext);
    const isMobile = useMediaQuery({query: `(max-width: 800px)`});
    const [isMenuOpen, setIsMenuOpen] = useState(!isMobile)
    const location = useLocation()
    const userContext = useContext(UserReactContext)
    const companyContext = useContext(CompanyReactContext)
    const featureFlags = companyContext.featureFlags
    const buttons = getMenuButtons({
        adminOrgsLength: get(adminOrganizations, 'length', 0),
        isCompanyAdmin: userContext.isManagingCompany(),
        isSuperAdmin: userContext.isUserSuperAdmin(),
        featureFlags
    })

    return (
        <div className={`AppSideMenu ${isMenuOpen ? 'open' : 'closed'}`}>
            {isMobile ? <div className="hamburgerMenu">
                <div onClick={() => setIsMenuOpen(!isMenuOpen)}>{isMenuOpen ? 'X' : '☰'}</div>
            </div> : ''}
            <div className="AppSideMenuHeader">
                <div className="AppSideMenuHeaderLogoWrapper">
                    <div className="AppSideMenuHeaderLogo">
                        <img src={personIcon} alt=""/>
                    </div>
                </div>
                <div className="AppSideMenuHeaderTitle">
                    <div>{i18n.translate('AppSideMenu.welcome')}</div>
                    <div className="UserNameAndMenu"><UserIconMenu/>{userContext.getUserName()} </div>
                </div>
            </div>
            <div className="AppSideMenuButtons">
                {map(buttons, (button, index) => {
                    let selected = false
                    map(button.detectSelected, (selectedString) => {
                        if (location.pathname.includes(selectedString)) {
                            selected = true
                        }
                    })
                    return <Link className={`AppSideMenuButton ${selected ? 'selected' : ''}`} key={button.nameKey}
                                 to={button.link}>
                        <div className="buttonText">{i18n.translate(button.nameKey)}</div>
                        <div className="buttonIcon">
                            {button.icon()}
                        </div>
                    </Link>
                })}

            </div>
            <div className="AppSideMenuFooter">

                {/*<div className="AppSideMenuFooterButton">*/}
                {/*    <Link to="/logout">*/}
                {/*        <div className="buttonText">{i18n.translate('menuItems.logout')}</div>*/}
                {/*        <div className="buttonIcon">*/}
                {/*            <LogoutOutlinedIcon/>*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div className="AppSideMenuFooterButton">*/}
                {/*    <Link to="/changePassword">*/}
                {/*        <div className="buttonText">{i18n.translate('menuItems.changePassword')}</div>*/}
                {/*        <div className="buttonIcon">*/}
                {/*            <SyncLockOutlinedIcon/>*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}