import { map } from 'lodash';

export const arIL = {
    confirm: 'تأكيد',
    login: {
        identifier: 'مُعرّف',
        password: 'كلمة المرور',
        login: 'تسجيل الدخول',
        verificationCodeFromSMS: 'رمز التحقق الذي استلمته عبر الرسالة النصية',
        messageWasSentTo: (key, { last4digits }) =>
            `تم إرسال رسالة نصية قصيرة (SMS) إلى رقم الهاتف الذي ينتهي بـ ${last4digits}`,
        validate: 'تحقق',
        resendCode: 'إعادة إرسال الرمز',
        sendCode: 'إرسال رمز التحقق',
        helloX: (key, { name }) => `مرحبًا ${name}`,
    },
    changePassword: {
        currentPassword: 'كلمة المرور الحالية',
        password: 'كلمة المرور الجديدة',
        repeatPassword: 'تكرار كلمة المرور الجديدة',
        changePassword: 'تغيير كلمة المرور',
    },
    logout: 'تسجيل الخروج',
    menuItems: {
        logout: 'تسجيل الخروج',
        changePassword: 'تغيير كلمة المرور',
        adminPanel: 'لوحة الإدارة',
        home: 'الصفحة الرئيسية',
        myOrgs: 'الوحدات التنظيمية الخاصة بي',
        learningCenter: 'مركز التعلم',
        users: 'المستخدمون',
        reports: 'التقارير',
        settings: 'الإعدادات',
        learningManagement: 'إدارة التعلم',
        filesManagement: 'إدارة الملفات',
        knowledgeCenter: 'مركز المعرفة',
        superAdmin: {
            courses: 'إدارة الدورات',
            customers: 'إدارة العملاء',
            organizations: 'إدارة الوحدات التنظيمية',
            users: 'إدارة المستخدمين',
        },
    },
    languages: {
        label: 'اختر اللغة',
        languageKeys: {
            heIL: 'עברית',
            enUS: 'English',
            arIL: 'العربية',
            ruRU: 'Русский'
        }
    },
    learningCenter: {
        title: 'مركز التعلم الخاص بي',
        subTitle: 'المهام وحالة إكمالها',
        coursesToDo: 'دورات لم تُستكمل بعد',
        coursesCompleted: 'الدورات التي أكملتها',
        noCoursesToDo: 'لا توجد دورات غير مكتملة',
        noCoursesCompleted: 'لا توجد دورات مكتملة',
        signDocsToDo: 'مستندات للتوقيع',
        noSignDocsToDo: 'لا توجد مستندات للتوقيع',
        noSignDocsCompleted: 'لا توجد مستندات قمت بتوقيعها',
        signDocsCompleted: 'المستندات التي وقّعت عليها',
        noOpenTasks: 'لا توجد مهام مفتوحة',
        tabs: {
            myCourses: 'دوراتي',
            mySignDocs: 'مستندات للتوقيع',
            myTasks: 'مهام مفتوحة',
        },
    },
    settingsPage: {
        title: 'الإعدادات',
        subTitle: 'اضبط النظام',
        subTitleBold: 'وفق احتياجات شركتك',
        buttons: {
            updateLogo: 'تحديث الشعار',
            updateOrgName: 'تحديث أسماء الوحدات التنظيمية',
            updateNotes: 'تحديث الملاحظات للموظفين',
        },
        editNotesModal: {
            title: 'تحرير الملاحظات للموظفين',
        },
        editOrganizationsNames: 'تحرير أسماء الوحدات التنظيمية',
        createNote: 'إنشاء ملاحظة جديدة',
        newNoteTitle: 'ملاحظة جديدة',
        SMSQuota: {
            title: 'رصيد الرسائل',
            quota: 'الرسائل المشتراة',
            sent: (key, { sent, quota }) =>
                `للعلم، حتى الآن تم إرسال ${sent} رسالة وبقي لديك ${quota} رسالة للاستخدام مستقبلًا`,
            lastSMSWasSentOn: (key, { date }) =>
                `آخر الرسائل تم إرسالها من النظام بتاريخ ${date}`,
        },
        generalSettings: {
            title: 'عام',
        },
        reminders: {
            title: 'التذكيرات',
            course: 'دورة',
            signDoc: 'مستند للتوقيع',
            watchVideo: 'مشاهدة فيديو',
            canvaPresentation: 'عرض Canva',
        },
        admins: {
            title: 'المديرون',
            subTitle: 'الإدارة والإعدادات',
        },
    },
    HomePage: {
        courseLegend: {
            completed: 'مكتمل',
            inProgress: 'قيد التقدم',
            notStarted: 'لم يبدأ',
        },
    },
    home: {
        title: 'الدورات المتاحة لك',
        faq: 'الأسئلة الشائعة',
        welcome: 'أهلاً وسهلاً',
        learningSubtitle: 'إلى بيئة التعلم الشخصية الخاصة بك، لشركة',
        userChartLabels: {
            finishedCourses: 'الدورات التي أكملتها',
            inProgressCourses: 'الدورات قيد التقدم',
            notStartedCourses: 'دورات لم تبدأ بعد',
            label: 'تقدّم الدورات',
        },
        superAdmin: 'واجهة مدير النظام',
    },
    faq: {
        question1: 'هل يمكنني العودة إلى الدورات في أي وقت؟',
        answer1:
            'نعم. يمكنك التعلم بوتيرتك الخاصة من أي مكان وفي أي وقت، من هاتفك المحمول أو حاسوبك (نوصي باستخدام متصفح جوجل كروم). انتبه أنه بمجرد بدء الدورة، يجب إكمالها على نفس الجهاز.',
        question2: 'ماذا أفعل عندما أنهي الدورات؟',
        answer2:
            'عندما تكمل دورة، اضغط على زر الإنهاء وأكد أنك أتممت الدورة. تأكد من عدم وجود مهام مفتوحة أخرى.',
        question3: 'هل تحتاج للمساعدة؟',
        answer3:
            'لا تتردد في التواصل معنا عبر الهاتف على 054-3493062 أو عبر البريد الإلكتروني على office@nakarmedic.co.il',
    },
    gender: {
        male: 'ذكر',
        female: 'أنثى',
    },
    OrganizationsPage: {
        title: 'الوحدات التنظيمية الخاصة بي',
        subTitle: 'الإدارة والإعدادات',
        organizations: 'الوحدات التنظيمية التي أديرها',
        importUsersFile: 'استيراد ملف المستخدمين',
        editLogo: 'تحرير الشعار',
        dropLogoHere: 'اسحب الشعار إلى هنا',
        currentLogo: 'الشعار الحالي',
        changeForAllMyOrganizations: 'تغييره لجميع الوحدات التنظيمية التي أديرها',
        logoWillBeChangedForAllOrgs:
            '**سيتم تغيير الشعار لجميع الوحدات التنظيمية تحت إدارتك',
    },
    UsersPage: {
        title: 'المستخدمون',
        subTitle: 'الإدارة والإعدادات',
        userRowCourseMenu: {
            buttons: {
                addToCourse: 'تسجيل المستخدم',
                removeFromCourse: 'إزالة تسجيل المستخدم',
            },
        },
        userRowSignDocMenu: {
            buttons: {
                addToSignDoc: 'تسجيل المستخدم',
                removeFromSignDoc: 'إزالة التسجيل',
                markCompleted: 'تحديد كمكتمل',
            },
        },
    },
    organizationUserTable: {
        headers: {
            name: 'الاسم',
            permissions: 'الصلاحيات',
            identifier: 'المُعرّف',
        },
    },
    userType: {
        ADMIN: 'مدير',
        USER: 'مستخدم',
    },
    note: {
        title: 'العنوان',
        content: 'المحتوى',
        delete: 'حذف الملاحظة',
        deleteNoteConfirmation: {
            title: 'حذف الملاحظة',
            message: 'هل أنت متأكد أنك تريد حذف هذه الملاحظة؟',
            noteName: 'اسم الملاحظة',
            deleteNote: 'حذف الملاحظة',
        },
    },
    organization: {
        organizationName: 'اسم الوحدة التنظيمية',
    },
    courseCard: {
        youAreDone: 'لقد أكملت الدورة',
        finishBy: (key, { finishByDate }) => `يجب إكمالها قبل ${finishByDate}`,
        youAreDoneWatchAgain: 'مشاهدة مرة أخرى',
        goToCourse: 'الانتقال إلى الدورة',
    },
    courseProgress: {
        notStarted: 'لم يبدأ',
        inProgress: 'قيد التقدم',
        finished: 'تم!',
    },
    ReportsPage: {
        title: 'التقارير',
        subTitle: 'عرض حالة إكمال الموظفين',
        courseLegend: {
            completed: 'مكتمل',
            inProgress: 'قيد التقدم',
            notStarted: 'لم يبدأ',
        },
        coursesStats: {
            title: 'حالة الإكمال لكل دورة',
        },
        signDocStats: {
            title: {
                course: 'حالة الإكمال لكل دورة',
                signDocs: 'حالة التوقيع لكل مستند',
            },
            graphs: {
                usersPerformanceNumbers: (key, { taskCount = 0 }) =>
                    `عدد المستخدمين حسب حالة الإكمال ${
                        taskCount ? `(${taskCount} مهام)` : ''
                    }`,
                usersPerformancePercent: (key, { taskCount = 0 }) =>
                    `نسبة المستخدمين حسب حالة الإكمال ${
                        taskCount ? `(${taskCount} مهام)` : ''
                    }`,
                performanceNumberByOrg: (key, { orgsCount = 0 }) =>
                    `عدد الإكمالات في الوحدة التنظيمية ${
                        orgsCount ? `(${orgsCount} وحدات تنظيمية)` : ''
                    }`,
                performancePercentByOrg: (key, { orgsCount = 0 }) =>
                    `نسبة الإكمال في الوحدة التنظيمية ${
                        orgsCount ? `(${orgsCount} وحدات تنظيمية)` : ''
                    }`,
                signDocsCount: ' مستندات للتوقيع',
                activeCourseCount: 'عناصر تعلم نشطة',
                usersCount: 'مستخدمون',
                usersWithActiveTasks: 'مستخدمون لديهم مهمة نشطة',
                allSignDocsOfACompanyCount: 'عناصر التعلم والتدريب',
                info: {
                    activeCourseCount:
                        'إجمالي عناصر التعلم ذات تاريخ نهاية مستقبلي (دورات، مستندات للتوقيع، ومهام المشاهدة)',
                    usersCount: 'إجمالي المستخدمين',
                    usersWithActiveTasks:
                        'إجمالي المستخدمين الذين لديهم على الأقل مهمة واحدة نشطة',
                    allSignDocsOfACompanyCount:
                        'إجمالي عناصر التعلم (الدورات والمستندات للتوقيع ومهام المشاهدة)',
                    performanceNumberByOrg:
                        'إجمالي المهام المكتملة من جميع المهام المتراكمة\n (المهام المتراكمة = عدد عناصر التعلم والتدريب مضروباً في عدد المستخدمين المسجلين لكل عنصر)',
                    performancePercentByOrg:
                        'إجمالي المهام المكتملة في كل وحدة تنظيمية',
                },
            },
        },
        tabs: {
            dashboard: 'لوحة المعلومات',
            courses: 'الدورات',
            signDocs: 'مستندات للتوقيع',
            generatedReports: 'التقارير المُنشأة',
        },
    },
    courseProgressMgmnt: {
        notStarted: 'لم يبدأ',
        inProgress: 'قيد التقدم',
        finished: 'مكتمل',
        completedOn: 'تاريخ التوقيع: ',
        completedOnCourse: 'تاريخ الإكمال: ',
        by: 'من قِبل ',
        manual: 'يدوي',
        finishBy: 'يجب إكماله قبل: ',
    },
    LearningManagementPage: {
        title: 'إدارة التعلم',
        subTitle: 'إضافة، تحرير، وحذف',
        addCourse: 'إضافة دورة',
        editCourse: 'تحرير دورة',
        deleteCourse: 'حذف دورة',
        courseName: 'اسم الدورة',
        courseDescription: 'وصف الدورة',
        courseImage: 'صورة الدورة',
        createKnowledgeSource: 'إنشاء مصدر معرفة',
        courseCard: {
            editCourse: 'تحرير الدورة',
        },
        signDocCard: {
            editSignDoc: {
                READ_AND_SIGN_DOC_PDF: 'تحرير المستند',
                WATCH_AND_SIGN_YOUTUBE_LINK: 'تحرير مهمة المشاهدة',
                WATCH_AND_SIGN_VIMEO_LINK: 'تحرير مهمة المشاهدة',
                WATCH_AND_SIGN_VIDEO_FILE: 'تحرير مهمة المشاهدة',
                REGULAR_COURSE: 'تحرير الدورة',
                CANVA_PRESENTATION: 'تحرير العرض التقديمي',
            },
            deleteModal: {
                title: 'حذف المستند',
                text: 'هل أنت متأكد أنك تريد حذف هذا المستند؟ لا يمكن التراجع عن هذا الإجراء',
                confirm: 'حذف المستند',
                signDocName: 'اسم المستند',
            },
        },
        users: {
            buttons: {
                downloadCsv: 'تصدير إلى CSV',
                downloadCsvForOrganization: 'تصدير المستخدمين للوحدة التنظيمية المحددة',
                downloadCsvForAllOrganizations:
                    'تصدير المستخدمين لجميع الوحدات التنظيمية التي أديرها',
            },
        },
        courseManagement: 'إدارة الدورات',
        signDocManagement: 'إدارة المستندات',
        noSignDocs: 'لا توجد مستندات للعرض',
        tabs: {
            signDocs: 'مستندات للتوقيع',
            courses: 'الدورات',
            users: 'المستخدمون',
            knowledgeCenter: 'مركز المعرفة',
        },
    },
    editCourseModal: {
        title: 'تحرير الدورة',
        courseName: 'اسم الدورة',
        courseDescription: 'وصف الدورة',
        courseImage: 'صورة الدورة',
        save: 'حفظ التغييرات',
        edit: {
            courseName: 'اسم الدورة',
            courseDescription: 'وصف الدورة',
            dateEditNotification:
                'يمكنك تعيين تاريخ انتهاء موحد لجميع المستخدمين المسجلين في الدورة في جميع الوحدات التنظيمية التي تديرها. أي تغيير هنا سيؤثر على التواريخ الفردية للمستخدمين.',
        },
        dateModal: {
            title: 'تعيين تاريخ الانتهاء',
            timeAndTimezone: 'حتى الساعة 00:00 في المنطقة الزمنية: ',
        },
    },
    editUserCourseModal: {
        title: (key, { userName, courseName }) =>
            `تعيين تاريخ انتهاء لدورة "${courseName}" للمستخدم "${userName}"`,
        dateEditNotification:
            'يمكنك تعيين تاريخ انتهاء للمستخدم. سيستمر هذا التغيير ما لم تقم بتغيير تاريخ الانتهاء لجميع الوحدات التنظيمية.',
    },
    AppSideMenu: {
        welcome: 'أهلاً وسهلاً',
    },
    superAdmin: {
        editFFModal: {
            modalTitle: 'تحرير ميزات الشركة',
            editFeatures: 'تحرير الميزات',
            featureFlags: {
                automationFF: 'الأتمتة',
                smsFF: 'الرسائل النصية القصيرة',
                whatsappFF: 'واتساب',
                emailFF: 'البريد الإلكتروني',
                signDocsFF: 'المهام',
                sendWelcomeMessageFF: 'إرسال رسائل ترحيبية للمستخدم الجديد',
                signDocsReadAndSignDocPdfFF: 'توقيع PDF',
                signDocsWatchAndSignYoutubeLinkFF: 'تأكيد مشاهدة Youtube',
                signDocsWatchAndSignVimeoLinkFF: 'تأكيد مشاهدة Vimeo',
                signDocsWatchAndSignVideoFileFF: 'تأكيد مشاهدة ملف فيديو',
                signDocCanvaPresentationFF: 'عرض Canva',
                regularCourseFF: 'دورات',
                notifyNewTaskToUserFF: 'إشعار المستخدم بمهمة جديدة',
                knowledgeCenterFF: 'مركز المعرفة',
                orgAdminMgmtFF: 'إدارة صلاحيات المدير',
            },
        },
        allUsersTable: {
            columns: {
                firstName: 'الاسم الأول',
                lastName: 'اسم العائلة',
                email: 'البريد الإلكتروني',
                phone: 'الهاتف',
                roles: 'الأدوار',
                identifier: 'المُعرّف',
                organization: 'الوحدة التنظيمية',
                id: 'المعرف',
                companyName: 'الشركة',
                yes: 'نعم',
                no: 'لا',
                isSuperAdmin: 'مدير نظام',
                isCompanyAdmin: 'مدير شركة',
                isDeleted: 'محذوف',
                edit: 'تحرير',
                permanentlyDelete: 'حذف نهائي',
                loginUrl: 'رابط الدخول',
            },
            buttons: {
                downloadCsvForOrganization: 'تنزيل ملف Excel للوحدة التنظيمية',
                downloadCsvForAllOrganizations:
                    'تنزيل ملفات Excel لجميع الوحدات التنظيمية التي أديرها',
                downloadCsvForOrganizationSuccess:
                    'سيتم تجهيز ملفاتك قريبًا للتحميل ضمن "القائمة -> إدارة الملفات"',
                deleteUsers: 'حذف المستخدمين',
                edit: 'تحرير',
            },
        },
        allOrgsTable: {
            columns: {
                orgName: 'اسم الوحدة التنظيمية',
                taxIdentifier: 'المعرف',
                companyId: 'معرف الشركة',
                orgAddress: 'العنوان',
                orgContactInfo: 'معلومات الاتصال',
                id: 'المعرف',
                companyName: 'اسم الشركة',
                delete: 'حذف',
            },
            deleteOrg: 'حذف الوحدة التنظيمية',
            deleteOrgTitle: 'حذف الوحدة التنظيمية: ',
            deleteOrgText: 'هل أنت متأكد أنك تريد حذف الوحدة التنظيمية',
            deleteOrgConfirm: 'حذف الوحدة التنظيمية',
        },
        createNewCourse: {
            title: 'إنشاء دورة جديدة',
            docName: 'اسم الدورة',
            courseDescription: 'وصف الدورة',
            create: 'إنشاء الدورة',
            dropZipHere: 'اسحب ملف ZIP الخاص بالدورة هنا أو انقر لتحديد ملف',
            modalTitle: 'إنشاء دورة جديدة',
            organizationsTable: {
                orgName: 'اسم الوحدة التنظيمية',
                addOrganization: 'إضافة الدورة للوحدة التنظيمية',
                removeOrganization: 'إزالة الدورة من الوحدة التنظيمية',
                onlyOneCompanyPerCourse:
                    'يمكن إضافة الدورة لشركة واحدة فقط، لذا لا يمكنك تغيير الشركة الآن',
            },
        },
        createNewCustomer: {
            modalTitle: 'إنشاء عميل جديد',
            companyName: 'اسم الشركة',
            userPrefix: 'بادئة المستخدم (فريدة لكل شركة - بالإنجليزية فقط)',
            identifier: 'المعرف',
            contactEmail: 'بريد التواصل',
            contactName: 'اسم جهة الاتصال',
            contactPhone: 'هاتف جهة الاتصال',
            userCreationTitle: 'إنشاء مستخدم أولي للشركة',
            detailsTitle: 'املأ تفاصيل الشركة',
            userPassword: 'كلمة مرور المستخدم (بالإنجليزية فقط)',
            userIdentifier: 'معرف المستخدم (بالإنجليزية فقط)',
            createCompany: 'إنشاء الشركة',
            userNameNoteNoPrefix:
                '*يجب إدخال بادئة المستخدم قبل إدخال اسم المستخدم',
            userNameNote: (key, { companyUsersPrefix, userIdentifier }) =>
                `*اسم المستخدم الذي سيتم إنشاؤه هو: ${companyUsersPrefix}-${
                    userIdentifier || '"User Identifier"'
                }`,
            userPrefixError: 'يجب أن تكون بادئة المستخدم بالإنجليزية فقط',
            userFirstName: 'الاسم الأول',
            userLastName: 'اسم العائلة',
            contactFirstName: 'الاسم الأول لجهة الاتصال',
            contactLastName: 'اسم العائلة لجهة الاتصال',
        },
        editCourse: {
            courseName: 'اسم الدورة',
            docName: 'اسم الدورة',
            courseDescription: 'وصف الدورة',
            description: 'وصف الدورة',
            dropZipHere:
                'اسحب ملف الـ ZIP الخاص بالدورة هنا أو انقر لتحديد ملف',
            dropZipNote:
                '**انتبه أنك في وضع التحرير، فإذا كان هناك ملف ZIP موجود بالفعل لهذه الدورة، فإن رفع ملف آخر سيستبدله**',
            saveChanges: 'حفظ التغييرات',
            modalTitle: 'تحرير الدورة',
        },
        editCustomer: {
            modalTitle: 'تحرير العميل',
            adminUserDetails: 'تفاصيل المستخدم الرئيسي',
            detailsTitle: 'تحرير تفاصيل الشركة',
            organizationsTitle: 'الوحدات التنظيمية للعميل',
            addOrganizations: 'إضافة وحدات تنظيمية للعميل',
            addOrganizationModal: {
                organizationsList: 'قائمة الوحدات التنظيمية',
                useCommaSeparatedValues:
                    'أدخل قيمًا مفصولة بفواصل، مثال: "المالية, المحاسبة, تطوير البرمجيات"',
                title: 'إضافة وحدات تنظيمية للعميل',
            },
        },
        coursesTable: {
            columns: {
                courseName: 'اسم المهمة',
                description: 'وصف المهمة',
                edit: 'تحرير',
                type: 'نوع المهمة',
                editCourse: 'تحرير الدورة',
                companyName: 'اسم الشركة',
                remove: 'حذف',
            },
        },
        customersTable: {
            columns: {
                companyName: 'اسم الشركة',
                taxIdentifier: 'المعرف',
                edit: 'تحرير',
                editCompany: 'تحرير الشركة',
                smsQuota: 'رصيد الرسائل',
                featureFlags: 'الميزات',
                storageQuotaMB: 'التخزين (MB)',
                editQuota: 'تحرير الحصص',
            },
            quota: {
                editQuota: 'تحرير الحصص',
                editQuotaTitle: 'تحرير حصص الشركة',
                editCredits: 'تحرير الأرصدة',
                MessagesCreditsToEdit: 'أرصدة الرسائل',
                storageQuotaToEdit: 'التخزين بالميغابايت',
            },
        },
    },
    dataGrid: {
        filters: {
            search: {
                input: 'بحث',
            },
        },
        filterLabels: {
            course: 'دورة',
            organization: 'الوحدة التنظيمية',
            company: 'الشركة',
            signDoc: 'المهام',
        },
    },
    importUsersPage: {
        downloadExample: 'تنزيل ملف نموذج لإنشاء المستخدمين',
        downloadUsersAsCSV: 'تنزيل المستخدمين كملف CSV',
    },
    importUsersModal: {
        title: 'استيراد / تحرير المستخدمين والوحدات',
        tabs: {
            importUsers: {
                title: 'استيراد المستخدمين وإضافة وحدات',
            },
            reorganizeUsers: {
                title: 'استيراد المستخدمين وتغيير الوحدات',
            },
        },
        manyUsersMessage:
            'يبدو أن هناك عددًا كبيرًا من المستخدمين في الملف، قد يستغرق الاستيراد بضع دقائق. بعد النقر على إنشاء، يرجى إبقاء النافذة مفتوحة حتى يكتمل التحميل، ثم انتظر بضع دقائق أخرى حتى تنعكس جميع التغييرات في النظام.',
        users: {
            firstName: 'الاسم الأول',
            lastName: 'اسم العائلة',
            identifier: 'المعرف',
            phone: 'رقم الهاتف',
            email: 'البريد الإلكتروني',
            organizations: 'الوحدات التنظيمية',
        },
        createUsers: 'إنشاء/تحرير المستخدمين',
        selectOrganization: 'اختر الوحدات التنظيمية التي سيتم إنشاء المستخدمين لها',
        selectOrganizationsToAdd: 'اختر الوحدات التنظيمية للإضافة',
        organization: 'الوحدة التنظيمية',
        newUsersWillBeCreatedAndOrganizationsWillBeAddedToAllUsers:
            'سيتم إضافة الوحدات التنظيمية لجميع المستخدمين الموجودين في الملف المرفق. المستخدمون غير الموجودين سيتم إنشاؤهم، والمستخدمون الموجودون سيتم تحديثهم.',
        newUsersWillBeCreatedAndOrganizationsWillBeOverride:
            'سيتم تغيير الوحدات التنظيمية لجميع المستخدمين في الملف المرفق (سيتم إزالة المستخدمين من الوحدات التي لم يتم اختيارها). المستخدمون غير الموجودين سيتم إنشاؤهم، والمستخدمون الموجودون سيتم تحديثهم.',
        errors: {
            missingMandatoryField: (key, { row }) =>
                `*حقول إلزامية مفقودة في الصف ${row}:`,
            twoSourcesForOrgs:
                'لا يمكنك اختيار الوحدات التنظيمية من مصدرين. يرجى إزالة الوحدات التنظيمية من الملف المستورد أو من الاختيار في أعلى الصفحة',
            removeOrgsFromFile: 'يرجى إزالة الوحدات التنظيمية من الملف المستورد',
            missingSourceForOrgs:
                'لم يتم اختيار أية وحدات تنظيمية للمستخدمين. يرجى اختيار الوحدات من أعلى الصفحة أو إضافتها في ملف الاستيراد.',
            missingSourceForOrgsSelectFromMultiSelect:
                'لم يتم اختيار أية وحدات تنظيمية للمستخدمين. يرجى اختيار الوحدات من القائمة في أعلى الصفحة.',
        },
    },
    uploadBox: {
        uploading: 'جارٍ رفع الملف...',
        uploaded: 'تم رفع الملف بنجاح',
        uploadFailed:
            'الملف غير صالح. يرجى التحقق من حجم الملف ونوعه وعدم تجاوز الحد المسموح.',
        maxFileSize: (key, { maxFileSize }) =>
            `الحد الأقصى المسموح به لحجم الملف هو ${maxFileSize}`,
        onlyFileTypes: (key, { fileTypes }) =>
            `أنواع الملفات المسموح برفعها: ${map(fileTypes, (fileType) => `${fileType}`).join(', ')}`,
    },
    completedCoursePage: {
        title: 'إكمال الدورة',
        subTitle: 'يرجى تأكيد أنك أنهيت الدورة',
        buttons: {
            confirmCourseCompletion: 'أؤكد أنني أكملت الدورة',
        },
        finishedSuccessfully: 'لقد أكملت الدورة بنجاح',
        oops: 'عذراً...',
        youGotLost: 'يبدو أنك تهت، استخدم القائمة للتنقل',
        courseCompletedSuccessfullyPopup: 'لقد أكملت الدورة بنجاح',
    },
    errors: {
        clientError: 'خطأ في البيانات المقدمة',
        serverError: 'خطأ في طلب الخادم',
        generalServerError:
            'خطأ عام في طلب الخادم، حاول إعادة تحميل الصفحة. إذا استمرت المشكلة، تواصل مع الدعم الفني.',
        notFound: 'المورد غير موجود',
        forbidden: 'ليس لديك صلاحية للوصول إلى هذا المورد',
        privateRouteUserNotLoggedIn:
            'المستخدم غير مسجل دخوله، لذلك لا يمكن عرض المحتوى. يرجى تسجيل الدخول ثم المحاولة مرة أخرى.',
    },
    alertDialog: {
        ok: 'موافق',
        cancel: 'إلغاء',
    },
    createEditUserModal: {
        createUser: 'إنشاء مستخدم',
        titleCreate: 'إنشاء/تحديث مستخدم',
        titleEdit: 'تحرير مستخدم',
        selectOrganization: 'اختر الوحدة التنظيمية',
        firstName: 'الاسم الأول',
        lastName: 'اسم العائلة',
        identifier: 'المعرف',
        phone: 'الهاتف',
        email: 'البريد الإلكتروني',
        editUser: 'تحرير مستخدم',
        deleteUserTitle: 'حذف مستخدم',
        deleteUserText: 'هل أنت متأكد أنك تريد حذف المستخدم',
        deleteOrgConfirm: 'حذف المستخدم',
        deleteUserButton: 'حذف مستخدم',
        userIsCompanyAdmin: 'مدير شركة',
        mfaRequired: 'مطلوب التحقق بخطوتين',
        sendWelcomeMessage: 'إرسال رسالة ترحيب أولية للمستخدم',
        save: 'حفظ/تحديث المستخدم',
        userWillBeEditedIfPhoneExists:
            'إذا كان رقم الهاتف مرتبطًا بمستخدم في الشركة مسبقًا، فسيتم تحديث هذا المستخدم والوحدات التنظيمية المنتسب إليها',
    },
    allUsersTable: {
        columns: {
            firstName: 'الاسم الأول',
            lastName: 'اسم العائلة',
            email: 'البريد الإلكتروني',
            phone: 'الهاتف',
            roles: 'الأدوار',
            identifier: 'المعرف',
            organization: 'الوحدة التنظيمية',
            id: 'المعرف',
            edit: 'تحرير',
            delete: 'حذف',
            progress: 'التقدم',
            notRegistered: 'غير مسجل في الدورة',
        },
    },
    removeCourseFromCompanyCol: {
        deleteCourseButton: 'حذف الدورة',
        removeCourseTitle: 'حذف الدورة',
        removeCourseText: 'هل أنت متأكد أنك تريد حذف الدورة',
        removeCourseConfirm: 'حذف الدورة',
    },
    CourseReminderNotificationButton: {
        alerts: {
            reminderSent: 'التذكيرات في طريقها!',
            reminderNotSent: 'حدث خطأ أثناء إرسال التذكير',
            sentMessages: 'تم إرسال الرسائل',
            notSentMessages: 'لم يتم إرسال الرسائل',
            notSentMessagesInfo:
                '* هناك سببان محتملان لعدم إرسال جميع الرسائل: عدم وجود معلومات اتصال أو رقم هاتف غير صحيح',
            sendToUsersWhoDidNotReceiveNotification:
                'إرسال تذكير للمستخدمين الذين لم يتلقوا تذكيراً بعد',
            sendToAllUsers:
                'إرسال تذكير لجميع مستخدمي الدورة الذين لم يكملوها بعد',
            error: 'خطأ',
            company_has_no_sms_quota:
                'الشركة لا تمتلك رصيدًا كافيًا من الرسائل، يرجى التواصل مع الدعم لشراء حزمة رسائل',
        },
    },
    FilesManagementPage: {
        title: 'إدارة الملفات',
        subTitle: 'عرض، إضافة، وتحرير',
        fileManagementTable: {
            columns: {
                fileName: 'رابط التحميل',
                presentationName: 'الاسم المعروض',
                isReady: 'جاهز للتحميل',
                companyName: 'الشركة',
                organizationNames: 'الوحدات التنظيمية',
                date: 'تاريخ الإنشاء',
                createdByUser: 'تم الإنشاء بواسطة',
            },
            download: 'تنزيل',
            inProgress: 'جاري الإنشاء',
            ready: 'جاهز للتحميل',
            allOrganizations: 'جميع الوحدات التنظيمية',
        },
    },
    SMSNotificationButton: {
        title: 'إرسال تذكير',
        subTitle: 'فورًا',
    },
    sendSMSModal: {
        title: 'لمن نذكّر؟',
        sms: 'رسالة نصية (SMS)',
        whatsapp: 'واتساب',
        email: 'بريد إلكتروني',
        selectMessageDeliveryWay: 'سيتم إرسال الرسائل عبر:',
        sendToOptions: {
            all: 'المستخدمون الذين لم يكملوا مهمة محددة',
            onlyNotNotified: 'جميع الذين لم يكملوا الدورة ولم يتلقوا تذكيراً',
            allWithAnyOpenTasks: 'المستخدمون الذين لديهم مهام مفتوحة',
            pleaseSelect: 'يرجى اختيار المجموعة التي تريد إرسال التذكير إليها',
            label: 'من؟',
        },
        taskOptions: {
            label: 'أي مهمة؟',
            pleaseSelect: 'يرجى اختيار الدورة التي تريد إرسال التذكير بشأنها',
        },
        organizationOptions: {
            label: 'أي وحدة تنظيمية؟',
            pleaseSelect: 'يرجى اختيار الوحدة التنظيمية التي تريد إرسال التذكير لها',
        },
        smsStatusInformation: {
            statusTitle: 'رائع!',
            statusUserToNotify: 'إذن، سنذكّر إجمالي ',
            bySMS: 'عبر الرسائل النصية',
            byWhatsApp: 'عبر الواتساب',
            byEmail: 'عبر البريد الإلكتروني',
            statusCompanyQuota: 'للعلم، بعد الإرسال سيبقى لديك ',
            SMSForUse: 'رسالة متاحة للاستخدام',
            employees: 'الموظفين',
            theyWillReceiveItAsFollowing: 'وسيتلقون الرسائل بالطرق التالية',
        },
        error: {
            noUsersToSendOnlyNotNotified:
                'لا يوجد مستخدمون لإرسال التذكير؛ جميع من لم يكمل الدورة قد تلقى تذكيراً بالفعل',
            notEnoughSMS:
                'ليس لديك رصيد كافٍ من الرسائل، يرجى التواصل مع الدعم لشراء حزمة رسائل',
            noUsersInCourse:
                'لا يوجد مستخدمون لإرسال التذكير؛ جميع المستخدمين في هذه الدورة أكملوها',
            youAreLeftWithQuota: 'لا يزال لديك',
            youNeed: 'وأنت تحتاج إلى ما مجموعه',
            toCompleteTheProcess: 'لإتمام العملية',
        },
        send: 'إرسال',
    },
    SADeleteUsersModal: {
        title: 'حذف المستخدمين',
        firstName: 'الاسم الأول',
        lastName: 'اسم العائلة',
        email: 'البريد الإلكتروني',
        phone: 'الهاتف',
        identifier: 'المعرف',
        message: (key, { userCount }) =>
            `هل أنت متأكد أنك تريد حذف المستخدمين الـ ${userCount} التالية؟`,
        deleteUsers: 'حذف المستخدمين',
    },
    SARemoveUser: {
        permanentlyDeleteUser: 'حذف نهائي',
        deleteUserTitle: 'حذف المستخدم نهائيًا',
        deleteUserText: 'هل أنت متأكد أنك تريد حذف المستخدم نهائيًا',
        deleteOrgConfirm: 'حذف المستخدم نهائيًا',
    },
    automations: {
        title: 'الأتمتة',
        subTitle: 'الإدارة والإعدادات',
        addAutomation: 'إضافة أتمتة',
        editAutomation: 'تحرير الأتمتة',
        deleteAutomation: 'حذف الأتمتة',
        automationName: 'اسم الأتمتة',
        automationDescription: 'وصف الأتمتة',
        automationImage: 'صورة الأتمتة',
        automationCard: {
            editAutomation: 'تحرير الأتمتة',
        },
        notifications: {
            title: 'إنشاء أتمتة',
            subTitle: 'لجدولة التذكيرات',
        },
        createAutomationModal: {
            weWillSendNotificationTo: 'سنرسل تذكيرًا لكل من لم يكمل الدورة ',
            allOrganizations: 'جميع الوحدات التنظيمية',
            organizationOptions: {
                label: 'أي وحدة تنظيمية؟',
                pleaseSelect: 'يرجى اختيار الوحدة التنظيمية التي تريد إرسال التذكير لها',
                error: 'يجب اختيار وحدة تنظيمية لإرسال التذكير',
            },
            taskOptions: {
                label: 'أي مهمة؟',
                pleaseSelect: 'يرجى اختيار المهمة التي تريد إرسال التذكير بشأنها',
                error: 'يجب اختيار دورة لإرسال التذكير',
            },
            selectHourLabel: 'في أي ساعة؟',
            selectHourError: 'يجب اختيار ساعة لإرسال التذكير',
            howManyDaysBefore: 'كم يومًا قبل؟',
            howManyDaysBeforeError: 'يجب تحديد عدد الأيام قبل الموعد النهائي',
            nameLabel: 'اسم الأتمتة',
            descriptionLabel: 'وصف الأتمتة',
            create: 'إنشاء الأتمتة',
        },
    },
    automationsTable: {
        columns: {
            automationName: 'اسم الأتمتة',
            description: 'وصف الأتمتة',
            organization: 'الوحدة التنظيمية',
            task: 'المهمة',
            when: 'متى؟',
            status: 'الحالة',
            how: 'كيف يُرسل؟',
            delete: 'حذف',
            edit: 'تحرير',
        },
        status: {
            SCHEDULED: 'مجدول',
            COMPLETED: 'تم الإرسال',
            sentAndNotSent: (key, { sent, notSent }) =>
                `تم الإرسال لـ ${sent} ولم يُرسل لـ ${notSent}`,
        },
        sendType: {
            SMS: 'رسالة نصية',
            EMAIL: 'بريد إلكتروني',
            WHATSAPP: 'واتساب',
        },
        atHour: 'في الساعة',
        daysBefore: 'أيام قبل موعد انتهاء الدورة',
    },
    allOrganizations: 'جميع الوحدات التنظيمية',
    allSignDocs: 'جميع المهام',
    confirmationModal: {
        confirm: 'تأكيد',
        cancel: 'إلغاء',
    },
    deleteAutomationModal: {
        title: 'حذف الأتمتة',
        message: 'هل أنت متأكد أنك تريد حذف الأتمتة؟',
        deleteAutomation: 'حذف الأتمتة',
        automationName: 'اسم الأتمتة',
    },
    newDocSign: {
        buttonTitle: 'إنشاء مهمة جديدة',
        modal: {
            title: 'إنشاء مستند جديد',
            docNameLabel: 'اسم المستند',
            docDescriptionLabel: 'وصف المستند',
            createDocument: 'إنشاء المستند',
            dropSignDocHere: 'اسحب ملف المستند هنا أو انقر لتحديده',
            selectSignDocType: 'النوع',
            linkUrl: 'رابط',
            youtubeLinkNotAllowed: 'لا يمكنك إدخال رابط Youtube',
            vimeoLinkNotAllowed: 'لا يمكنك إدخال رابط Vimeo',
            invalidLink: 'رابط غير صالح',
            notifyUsersOfNewTask: 'إرسال رسالة للمستخدمين حول المهمة الجديدة',
            forceCompletion: 'مطلوب لإكماله',
            nonForceCompletion: 'غير مطلوب لإكماله',
            noDate: 'بدون تاريخ انتهاء',
        },
    },
    signDocProgress: {
        notStarted: {
            READ_AND_SIGN_DOC_PDF: 'لم أقرأه',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'لم أشاهده',
            WATCH_AND_SIGN_VIMEO_LINK: 'لم أشاهده',
            WATCH_AND_SIGN_VIDEO_FILE: 'لم أشاهده',
            REGULAR_COURSE: 'لم أبدأ',
            CANVA_PRESENTATION: 'لم أشاهده',
        },
        inProgress: {
            READ_AND_SIGN_DOC_PDF: 'قرأتُه',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'شاهدتُه',
            WATCH_AND_SIGN_VIMEO_LINK: 'شاهدتُه',
            WATCH_AND_SIGN_VIDEO_FILE: 'شاهدتُه',
            REGULAR_COURSE: 'بدأت',
            CANVA_PRESENTATION: 'شاهدتُه',
        },
        finished: {
            READ_AND_SIGN_DOC_PDF: 'تم التوقيع!',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'تم التوقيع!',
            WATCH_AND_SIGN_VIMEO_LINK: 'تم التوقيع!',
            WATCH_AND_SIGN_VIDEO_FILE: 'تم التوقيع!',
            REGULAR_COURSE: 'تم!',
            CANVA_PRESENTATION: 'تم التوقيع!',
        },
    },
    signDocProgressMgmnt: {
        notStarted: 'لم يتم فتحه',
        inProgress: 'تم فتحه',
        finished: 'تم التوقيع',
    },
    signDocPage: {
        title: {
            read: {
                sign: 'الموافقة على قراءة المستند',
                noSign: 'قراءة المستند',
                knowledgeOnly: 'قراءة المستند',
            },
            watch: {
                sign: 'الموافقة على مشاهدة الفيديو',
                noSign: 'مشاهدة الفيديو',
                knowledgeOnly: 'مشاهدة الفيديو',
            },
        },
        subTitle: {
            read: {
                sign: 'وافق ووقّع أنك قرأت المستند',
                noSign: 'إعادة القراءة بعد التوقيع',
            },
            watch: {
                sign: 'وافق ووقّع أنك شاهدت الفيديو',
                noSign: 'إعادة المشاهدة بعد التوقيع',
            },
        },
        signatureTitle: {
            read: 'وقّع لتأكيد أنك قرأت المستند',
            watch: 'وقّع لتأكيد أنك شاهدت الفيديو',
        },
        signatureSubtitle: {
            read: 'لتأكيد قراءة المستند، وقّع هنا',
            watch: 'لتأكيد مشاهدة الفيديو، وقّع هنا',
        },
        signatureSubtitleNotCompleted: {
            read: 'بعد القراءة، ستظهر لوحة التوقيع وزر التأكيد',
            watch: 'بعد المشاهدة، ستظهر لوحة التوقيع وزر التأكيد',
        },
        iApproveReadingTheDoc: {
            read: 'لقد قرأت المستند!',
            watch: 'لقد شاهدت الفيديو!',
        },
        clear: 'مسح التوقيع',
    },
    signDocCard: {
        youAreDone: {
            READ_AND_SIGN_DOC_PDF: 'لقد وقّعت المستند',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'لقد شاهدت الفيديو',
            WATCH_AND_SIGN_VIMEO_LINK: 'لقد شاهدت الفيديو',
            WATCH_AND_SIGN_VIDEO_FILE: 'لقد شاهدت الفيديو',
            REGULAR_COURSE: 'أكملت الدورة',
            CANVA_PRESENTATION: 'شاهدت العرض التقديمي',
        },
        finishBy: {
            READ_AND_SIGN_DOC_PDF: (key, { finishByDate }) =>
                `يجب توقيعه قبل ${finishByDate}`,
            WATCH_AND_SIGN_YOUTUBE_LINK: (key, { finishByDate }) =>
                `يجب مشاهدته قبل ${finishByDate}`,
            WATCH_AND_SIGN_VIMEO_LINK: (key, { finishByDate }) =>
                `يجب مشاهدته قبل ${finishByDate}`,
            WATCH_AND_SIGN_VIDEO_FILE: (key, { finishByDate }) =>
                `يجب مشاهدته قبل ${finishByDate}`,
            REGULAR_COURSE: (key, { finishByDate }) =>
                `يجب إكماله قبل ${finishByDate}`,
            CANVA_PRESENTATION: (key, { finishByDate }) =>
                `يجب مشاهدته قبل ${finishByDate}`,
        },
        youAreDoneWatchAgain: {
            READ_AND_SIGN_DOC_PDF: 'اقرأ مرة أخرى',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'شاهد مرة أخرى',
            WATCH_AND_SIGN_VIMEO_LINK: 'شاهد مرة أخرى',
            WATCH_AND_SIGN_VIDEO_FILE: 'شاهد مرة أخرى',
            REGULAR_COURSE: 'شاهد مرة أخرى',
            CANVA_PRESENTATION: 'شاهد مرة أخرى',
        },
        goToCourse: {
            READ_AND_SIGN_DOC_PDF: 'اذهب للقراءة',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'اذهب للمشاهدة',
            WATCH_AND_SIGN_VIMEO_LINK: 'اذهب للمشاهدة',
            WATCH_AND_SIGN_VIDEO_FILE: 'اذهب للمشاهدة',
            REGULAR_COURSE: 'اذهب إلى الدورة',
            CANVA_PRESENTATION: 'اذهب للمشاهدة',
        },
    },
    Errors_409: {
        storage_quota_error: (
            key,
            { bytesCompanyStorageQuota, bytesCompanyUsedStorage, bytesNewUsedStorage }
        ) => {
            const leftQuotaMB = (
                (bytesCompanyStorageQuota - bytesCompanyUsedStorage) /
                (1024 * 1024)
            ).toFixed(2);
            const bytesNewUsedStorageMB = (
                bytesNewUsedStorage /
                (1024 * 1024)
            ).toFixed(2);
            const bytesCompanyStorageQuotaMB = (
                bytesCompanyStorageQuota /
                (1024 * 1024)
            ).toFixed(2);
            return `لا توجد مساحة تخزين كافية. حاول حذف بعض الملفات أو التواصل مع مدير النظام. لديك ${leftQuotaMB}MB متبقية من أصل ${bytesCompanyStorageQuotaMB}MB متاحة، وحجم الملف الذي تحاول رفعه هو ${bytesNewUsedStorageMB}MB.`;
        },
    },
    signDocTypes: {
        READ_AND_SIGN_DOC_PDF: 'مستند PDF للتوقيع',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'رابط Youtube للمشاهدة',
        WATCH_AND_SIGN_VIMEO_LINK: 'رابط VIMEO للمشاهدة',
        WATCH_AND_SIGN_VIDEO_FILE: 'ملف فيديو للمشاهدة',
        REGULAR_COURSE: 'دورة',
        CANVA_PRESENTATION: 'عرض Canva للمشاهدة',
    },
    signDocTypesNotForCompletion: {
        READ_AND_SIGN_DOC_PDF: 'مستند PDF',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'رابط Youtube',
        WATCH_AND_SIGN_VIMEO_LINK: 'رابط VIMEO',
        WATCH_AND_SIGN_VIDEO_FILE: 'ملف فيديو',
        REGULAR_COURSE: 'دورة',
        CANVA_PRESENTATION: 'عرض Canva',
    },
    managementCourseList: {
        signDocButtonTitle: 'إنشاء مهمة مشاهدة',
    },
    isCompanyAdmin: {
        makeAdmin: {
            title: 'تعيين كمدير للشركة',
            message: 'هل أنت متأكد أنك تريد تعيين المستخدم كمدير للشركة؟',
            confirm: 'تعيين كمدير للشركة',
            button: 'تعيين كمدير للشركة',
        },
        removeAdmin: {
            title: 'إزالة مدير الشركة',
            message:
                'هل أنت متأكد أنك تريد إزالة دور مدير الشركة من المستخدم؟',
            confirm: 'إزالة مدير الشركة',
            button: 'إزالة مدير الشركة',
        },
        userName: 'اسم المستخدم',
    },
    general: {
        saveChanges: 'حفظ التغييرات',
        close: 'إغلاق',
        cancel: 'إلغاء',
    },
    knowledgeCenter: {
        title: 'مركز المعرفة',
        subTitle: 'جميع المعلومات في مكان واحد',
    },
    adminUsersTable: {
        columns: {
            firstName: 'الاسم الأول',
            lastName: 'اسم العائلة',
            email: 'البريد الإلكتروني',
            phone: 'الهاتف',
            roles: 'الأدوار',
            identifier: 'المعرف',
            managerOfOrganizations: 'مدير في الوحدات التنظيمية',
            id: 'المعرف',
            edit: 'تحرير',
            isCompanyAdmin: 'مدير الشركة',
            isCompanyAdminYes: 'نعم',
            isCompanyAdminNo: 'لا',
        },
        addAdminUser: 'إضافة مدير',
    },
    editAdminUserModal: {
        title: 'تعيين مدير',
        titleEdit: 'تحرير المدير',
        sendWelcomeMessage: 'إرسال رسالة ترحيب أولية للمدير',
        save: 'حفظ التغييرات',
        selectOrganizationalUnits: 'البحث في الوحدات التنظيمية',
        searchUser: 'البحث عن مستخدم',
        isCompanyAdmin: 'مدير الشركة',
        companyAdminWillBeAdminOfAllOrgs:
            'سيكون مدير الشركة مديرًا لجميع الوحدات التنظيمية',
        selectAllOrgs: 'اختر جميع الوحدات التنظيمية',
    },
    autoComplete: {
        search: 'بحث',
        selectAll: 'اختر الكل',
    },
    UserIconMenu: {
        settings: 'الإعدادات',
    }
};
