import React, {useContext, useEffect, useState} from 'react';
import "./Home.scss"
import {getUserOrganizationsWithNotesAndCourses, getUserSignDocsAPI} from "../../Services/api";
import {get, map} from "lodash";
import {FAQs} from "../FAQ/Faq";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {calcUserChartData, userChartLabels} from "../../LearningCenter/LearningCenterLib";
import {PageTitle} from "../PageTitle/PageTitle";
import {getUniqNotes, userHomeChartLabels} from "./HomeLib";
import {Note} from "../Note/Note";
import {DoughnutChartWrapper} from "../Charts/DoughnutChartWrapper/DoughnutChartWrapper";
import {DoughnutChartLegend} from "../Charts/DoughnutChartLegend/DoughnutChartLegend";
import {getAllSignDocTypes} from "../SignDoc/SignDocLib";
import {MyOpenTasks} from "../../LearningCenter/MyOpenTasks/MyOpenTasks";
import {I18nReactContext} from "../../contexts/I18nContext";

export const UsersHome = () => {
    const {i18n} = useContext(I18nReactContext);
    const [organizations, setOrganizations] = useState()
    const [signDocs, setSignDocs] = useState()
    const [userChartData, setUserChartData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [notes, setNotes] = useState([])
    const init = async () => {
        setIsLoading(true)
        const signDocs = await getUserSignDocsAPI({types: getAllSignDocTypes(), doNotForceCompletion: false})
        setSignDocs(signDocs)
        const newChartData = calcUserChartData({signDocs})
        //if all values of chartData are empty
        if (newChartData[0] === 0 && newChartData[1] === 0 && newChartData[2] === 0) {
            setUserChartData(null)
        } else {
            setUserChartData(newChartData)
        }
        setIsLoading(false)
    }
    const fetchOrgs = async () => {
        let orgs = await getUserOrganizationsWithNotesAndCourses()
        const notes = getUniqNotes({orgs})
        setNotes(notes)
        setOrganizations(orgs)
    }
    useEffect(() => {
        fetchOrgs()
        init()
    }, []);
    return <div className="home MenuPage">
        <PageTitle
            title={i18n.translate('home.welcome')}
            subTitle={i18n.translate('home.learningSubtitle')}
            subTitleBold={get(organizations, '[0].organization.company.companyName')}/>
        <div className="menuInnerPage">
            <div className="homeContent">
                {userChartData || (notes && notes.length) ? <div className="homeNotesRow">
                    {notes ? <div className="Notes">
                        {
                            map(notes, (note, index) => {
                                return <Note note={note} key={note.id}/>
                            })
                        }

                    </div> : ''}
                    {userChartData ? <div className="Chart">
                        <div className="legend">
                            <DoughnutChartLegend
                                items={userHomeChartLabels({i18n})}
                            />
                        </div>
                        {isLoading ? <LoadingSpinner/> :
                            <DoughnutChartWrapper labels={userChartLabels} data={userChartData}
                                                  mainLabel={i18n.translate('home.userChartLabels.label')}/>}
                    </div> : ""}
                </div> : ''}

                <div className="openTasksWrapper">
                    <div className="PageSecondaryTitle">{i18n.translate('learningCenter.tabs.myTasks')}</div>
                    <MyOpenTasks/>
                </div>
                <div className="FAQWrapper">
                    <div className="FAQ">
                        <div className="PageSecondaryTitle">{i18n.translate('home.faq')}</div>
                        <div className="FAQData">
                            <FAQs/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
}

