import React, {createContext, useContext, useEffect, useState} from "react";
import {enUS} from "../Utils/i18n/enUS";
import {heIL} from "../Utils/i18n/heIL";
import {MDText} from "i18n-react";
import {changeUserLanguage} from "../Services/api";
import {UserReactContext} from "./UserContext";
import {arIL} from "../Utils/i18n/arIL";
import {ruRU} from "../Utils/i18n/ruRU";
import LoadingSpinner from "../Components/LoadingSpinner/LoadingSpinner";


const defaultLanguage = 'heIL'

export const languagesMap = {
    'heIL': heIL,
    'enUS': enUS,
    'arIL': arIL,
    'ruRU': ruRU,
};

export const isLanguageRtl = ({language}) => {
    return ['heIL', 'arIL'].includes(language)
}
// Create Context
export const I18nReactContext = createContext(
    {
        language: defaultLanguage,
        i18n: new MDText(languagesMap[defaultLanguage]),
        switchLanguage: () => {
        }
    }
);

// Context Provider Component
export const I18nReactContextProvider = ({children}) => {
    let userContext = useContext(UserReactContext)
    const [language, setLanguage] = useState(); // Default language
    const [i18n, setI18n] = useState();

    const switchLanguage = async ({newSelectedLanguage, skipUpdateUser}) => {
        if (languagesMap[newSelectedLanguage]) {
            if (language !== newSelectedLanguage) {
                setLanguage(newSelectedLanguage); // Update the state
                setI18n(new MDText(languagesMap[newSelectedLanguage]));
                if(!skipUpdateUser){
                    await changeUserLanguage({language:newSelectedLanguage})
                    userContext.refreshUserData()
                }
            }
        } else {
            console.warn(`Language '${newSelectedLanguage}' not found.`);
        }
    };
    useEffect(() => {
        const userPreferences = userContext.getPreferences({attributes: ['language']})
        if(userPreferences.language){
            switchLanguage({newSelectedLanguage: userPreferences.language})
        }
        else if(!language){
            switchLanguage({newSelectedLanguage: defaultLanguage, skipUpdateUser: true})
        }
    }, [userContext.user])
    const isRtl = isLanguageRtl({language})
    if(!i18n){
        return <LoadingSpinner/>
    }
    return <I18nReactContext.Provider value={{language, switchLanguage, i18n, isRtl}}>
        {children}
    </I18nReactContext.Provider>;
};
