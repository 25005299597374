import React, {useContext} from 'react';
import "./ProgressSlider.scss"
import {I18nReactContext} from "../../../contexts/I18nContext";

export const CourseProgressSlider = ({progressPercent, type}) => {
    const {i18n} = useContext(I18nReactContext);
    let actualProgress = progressPercent
    if (progressPercent > 0 && progressPercent < 100) {
        actualProgress = 50
    }
    return <div className="ProgressSlider CourseProgressSlider">
        <div className="progressBar">
            <div className={`progressRound startRound ${actualProgress >= 0 ? 'on' : ''}`}/>
            <div className={`progressRound middleRound ${actualProgress >= 50 ? 'on' : ''}`}/>
            <div className={`progressRound endRound ${actualProgress === 100 ? 'on' : ''}`}/>
        </div>
        <div className="overlayProgressBar" style={{width: `${actualProgress}%`}}/>
        <div className="progressTexts">
            <div
                className={`progressText startText ${actualProgress >= 0 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.notStarted.${type}`)}</div>
            <div
                className={`progressText middleText ${actualProgress >= 50 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.inProgress.${type}`)}</div>
            <div
                className={`progressText endText ${actualProgress === 100 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.finished.${type}`)}</div>
        </div>
    </div>
}

