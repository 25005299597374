import React, {useContext} from 'react';
import "./ProgressSlider.scss"
import {I18nReactContext} from "../../../contexts/I18nContext";

export const SignDocProgressSlider = ({viewed, signed, type}) => {
    const {i18n} = useContext(I18nReactContext);
    const actualProgress = viewed && !signed ? 50 : (signed ? 100 : 0)
    return <div className="ProgressSlider SignDocProgressSlider">
        <div className="progressBar">
            <div className={`progressRound startRound on`}/>
            <div className={`progressRound middleRound ${viewed ? 'on' : ''}`}/>
            <div className={`progressRound endRound ${signed ? 'on' : ''}`}/>
        </div>
        <div className="overlayProgressBar" style={{width: `${actualProgress}%`}}/>
        <div className="progressTexts">
            <div className={`progressText startText on`}>{i18n.translate(`signDocProgress.notStarted.${type}`)}</div>
            <div className={`progressText middleText ${viewed ? 'on' : ''}`}>{i18n.translate(`signDocProgress.inProgress.${type}`)}</div>
            <div className={`progressText endText ${signed ? 'on' : ''}`}>{i18n.translate(`signDocProgress.finished.${type}`)}</div>
        </div>
    </div>
}

