import {DatePicker} from "../Pickers/DatePicker/DatePicker";
import {Modal} from "../Smart/Modal/Modal";
import React, {useContext} from "react";
import {Button} from "../Form/Button/Button";
import {buttonColors} from "../Pickers/InputTheme";
import './SelectFinishDateForUserCourseModal.scss'
import {get} from "lodash";
import {I18nReactContext} from "../../contexts/I18nContext";

export const SelectFinishDateForUserCourseModal = ({
                                                       course,
                                                       signDoc,
                                                       userName,
                                                       userId,
                                                       onSave,
                                                       currentFinishDate,
                                                       isModalOpen,
                                                       closeSelectFinishDateModal
                                                   }) => {
    const {i18n} = useContext(I18nReactContext);
    let [finishDate, setFinishDate] = React.useState(currentFinishDate || new Date())
    const innerOnSave = () => {
        finishDate.setHours(23, 59, 59, 999)
        if (course) {
            onSave({courseId: course.id, userId, finishDate})
        }
        if (signDoc) {
            onSave({signDocId: signDoc.id, userId, finishDate})
        }
    }
    let id
    if (course) {
        id = course.id
    }
    if (signDoc) {
        id = signDoc.id
    }
    return <Modal open={isModalOpen} onClose={closeSelectFinishDateModal} key={userId + id}>
        <div className="SelectFinishDateForUserCourseModal">
            <div className="modalTitle">
                <div>{i18n.translate('editUserCourseModal.title', {
                    context: {
                        userName,
                        courseName: get(course, 'courseName') || get(signDoc, 'docName')
                    }
                })}</div>
            </div>
            <div className="modalContent">
                <div className="datePickerWrapper">
                    <DatePicker onChange={setFinishDate} value={finishDate}/>
                    <div
                        className="message">{i18n.translate('editUserCourseModal.dateEditNotification')}</div>
                </div>
                <div className="saveContainer">
                    <Button
                        color={buttonColors.GREY}
                        onClick={innerOnSave}
                        text={i18n.translate('general.saveChanges')}
                    />
                </div>
            </div>
        </div>

    </Modal>
}