import {buttonColors} from "../../Pickers/InputTheme";
import {SignDocTypes} from "../SignDocLib";

export const getCardDesignValues = ({progressPercent, viewed, signed, finishByDate, type, i18n}) => {
    if(type === SignDocTypes.REGULAR_COURSE){
        return getCourseCardDesignValues({progressPercent, finishByDate, type, i18n})
    }
    else {
        return getSignDocDesignValues({viewed, signed, finishByDate, type, i18n})
    }
}

export const getSignDocDesignValues = ({viewed, signed, finishByDate, type, i18n}) => {
    let buttonColor, message, completionClass, buttonText;
    if(signed && viewed){
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.youAreDone.${type}`)
        buttonText = i18n.translate(`signDocCard.youAreDoneWatchAgain.${type}`)
        completionClass = 'completed'
    }
    else if(viewed && !signed){
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.finishBy.${type}`, {context: {finishByDate}})
        buttonText = i18n.translate(`signDocCard.goToCourse.${type}`)
        completionClass = 'inProgress'

    }
    else {
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.finishBy.${type}`, {context: {finishByDate}})
        buttonText = i18n.translate(`signDocCard.goToCourse.${type}`)
        completionClass = 'notStarted'

    }
    return {buttonColor, message, completionClass, buttonText}
}

const getCourseCardDesignValues = ({progressPercent, finishByDate, type, i18n}) => {
    let buttonColor, message, completionClass, buttonText;
    if(progressPercent === 100){
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.youAreDone.${type}`)
        buttonText = i18n.translate(`signDocCard.youAreDoneWatchAgain.${type}`)
        completionClass = 'completed'
    }
    else if(progressPercent > 0 && progressPercent < 100){
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.finishBy.${type}`, {context: {finishByDate}})
        buttonText = i18n.translate(`signDocCard.goToCourse.${type}`)
        completionClass = 'inProgress'

    }
    else {
        buttonColor = buttonColors.GOLD
        message = i18n.translate(`signDocCard.finishBy.${type}`, {context: {finishByDate}})
        buttonText = i18n.translate(`signDocCard.goToCourse.${type}`)
        completionClass = 'notStarted'

    }
    return {buttonColor, message, completionClass, buttonText}
}