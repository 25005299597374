import React, {useContext} from "react";
import {PageTitle} from "../PageTitle/PageTitle";
import {MyOpenTasks} from "../../LearningCenter/MyOpenTasks/MyOpenTasks";
import {I18nReactContext} from "../../contexts/I18nContext";

export const KnowledgeCenter = () => {
    const {i18n} = useContext(I18nReactContext);

    return <div className="KnowledgeCenter MenuPage">
        <PageTitle title={i18n.translate('knowledgeCenter.title')}
                   subTitle={i18n.translate('knowledgeCenter.subTitle')}/>
        <div className="menuInnerPage">
            <MyOpenTasks
                showForceCompletion={false}
                showNonForceCompletion={true}
            />
        </div>

    </div>
}