import React, {useContext, useState} from "react";
import './Users.scss'
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {buttonColors} from "../Pickers/InputTheme";
import {ImportUsersModal} from "./ImportUsersModal/ImportUsersModal";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {GridReactContext, GridReactContextProvider} from "../../Utils/DataGridWrapper/GridContext";
import OrganizationUsersTable from "./OrganizationUsersTable/OrganizationUsersTable";
import {CreateEditUserModal} from "./CreateEditUserModal/CreateEditUserModal";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useUserAdminOrgs} from "../Hooks/UseUserAdminOrgs";
import {ImportUserContextProvider} from "./ImportUsersModal/ImportUsersModalContext";
import {getAllOrganizationsUsersAsCSV} from "../../Services/api";
import {get} from "lodash";
import {Navigate} from "react-router-dom";
import {I18nReactContext} from "../../contexts/I18nContext";

export const Users = () => {
    const {
        adminOrganizationOptions,
        adminOrganizations,
        isOrgsLoading,
        triggerReloadOrganizations
    } = useUserAdminOrgs({})
    const {i18n} = useContext(I18nReactContext);
    const [importUsersModalOpen, setImportUsersModalOpen] = useState(false)
    const [createEditUserModalOpen, setCreateEditUserModalOpen] = useState(false)
    const [navigateToReports, setNavigateToReports] = useState(false)
    const gridContext = React.useContext(GridReactContext)
    const openImportUsersModal = () => {
        setImportUsersModalOpen(true)
    }
    const closeImportUsersModal = () => {
        setImportUsersModalOpen(false)
    }
    const openCreateEditUserModal = () => {
        setCreateEditUserModalOpen(true)
    }
    const closeCreateEditUserModal = () => {
        setCreateEditUserModalOpen(false)
    }
    const onSuccessCreateEditUser = () => {
        closeCreateEditUserModal()
        gridContext.triggerTableRefresh()
    }
    const onSuccessCreateUsers = () => {
        closeImportUsersModal()
        triggerReloadOrganizations()
        gridContext.triggerTableRefresh()
    }

    const downloadUsersAsCSV = async ({organizationIds}) => {
        getAllOrganizationsUsersAsCSV({organizationIds})
    }
    const buttons = [
        //download csv button
        // {
        //     type: "A-Button",
        //     icon: <CloudDownloadIcon/>,
        //     href: `${process.env.REACT_APP_PUBLIC_FILES_URL}/weezbee-csv-example.csv`,
        //     text: i18n.translate('importUsersPage.downloadExample')
        // },
        {
            icon: <GroupAddOutlinedIcon/>,
            color: buttonColors.GREY,
            text: i18n.translate('importUsersModal.title'),
            onClick: ({paginationModel, filterModel, sortModel}) => {
                openImportUsersModal()
            }
        },
        {
            type: 'MultipleOptionsButton',
            text: i18n.translate('LearningManagementPage.users.buttons.downloadCsv'),
            icon: <CloudDownloadIcon/>,
            options: [
                {
                    text: i18n.translate('LearningManagementPage.users.buttons.downloadCsvForOrganization'),
                    action: async ({paginationModel, filterModel, sortModel}) => {
                        //remove courseId from filterModel
                        let filterModelCopy = JSON.parse(JSON.stringify(filterModel))
                        await downloadUsersAsCSV({organizationIds: [get(filterModelCopy, 'filterValues.organizationId')]})
                        setNavigateToReports(true)
                    }
                },
                {
                    text: i18n.translate('LearningManagementPage.users.buttons.downloadCsvForAllOrganizations'),
                    action: async ({paginationModel, filterModel, sortModel}) => {
                        //remove courseId from filterModel
                        await downloadUsersAsCSV({})
                        setNavigateToReports(true)
                    }
                }
            ]
        },
        {
            icon: <PersonAddIcon/>,
            color: buttonColors.GREY,
            text: i18n.translate('createEditUserModal.createUser'),
            onClick: ({paginationModel, filterModel, sortModel}) => {
                openCreateEditUserModal()
            }
        },
    ]
    if (navigateToReports) {
        return <Navigate to={{pathname: `/reports/generatedReports`}} replace={true}/>
    }

    return <div className="UsersPage">
        <GridReactContextProvider>
            <div className="TableWRapper">
                {adminOrganizationOptions ?
                    <OrganizationUsersTable tableButtons={buttons} organizationsOptions={adminOrganizationOptions}/> :
                    <LoadingSpinner/>}
            </div>
            <ImportUserContextProvider>
                {importUsersModalOpen && <ImportUsersModal closeImportUsersModal={closeImportUsersModal}
                                                           importUserModalOpen={importUsersModalOpen}
                                                           onSuccessCreateUsers={onSuccessCreateUsers}/>}
            </ImportUserContextProvider>
            {createEditUserModalOpen && <CreateEditUserModal closeCreateEditUserModal={closeCreateEditUserModal}
                                                             createEditUserModalOpen={createEditUserModalOpen}
                                                             onSuccess={onSuccessCreateEditUser}/>}
        </GridReactContextProvider>
    </div>
}