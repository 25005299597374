import {filter, find, get, map} from "lodash";
import {EntityTypes} from "../../Notifications/NotificationsLib";
import {getSignDocPrefix} from "../../SignDoc/SignDocLib";


export const getTaskOptionsForNotificationsInOrganization = ({
                                                                 selectedOrgOption,
                                                                 adminOrganizations,
                                                                 showPastSignDocs,
                                                                 i18n
                                                             }) => {
    let signDocOptionsTmp = []
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    if (selectedOrgOption === 'all') {
        //get all courses from all organizations
        let orgSignDocs = []
        map(adminOrganizations, (org) => {
            let tmpOrgSignDocs = get(org, 'organization.signDocs')
            tmpOrgSignDocs = tmpOrgSignDocs.filter((signDoc) => {
                const orgSignDoc = find(signDoc.organizationSignDoc, {OrganizationId: get(org, 'organization.id')})
                if (!showPastSignDocs) {
                    return orgSignDoc.isValid && new Date(orgSignDoc.finishByDate) >= yesterday && !signDoc.doNotForceCompletion
                }
                return orgSignDoc.isValid && !signDoc.doNotForceCompletion
            })
            orgSignDocs = orgSignDocs.concat(tmpOrgSignDocs)
        })

        signDocOptionsTmp = map(orgSignDocs, (signDoc) => {
            let prefix = getSignDocPrefix({signDoc, i18n});
            return {
                value: signDoc.id,
                text: `${prefix} - ${signDoc.docName}`,
                type: EntityTypes.SIGN_DOC
            }
        })


    } else {
        const selectedOrganizationData = find(adminOrganizations, {organization: {id: selectedOrgOption}})
        let orgSignDocs = get(selectedOrganizationData, 'organization.signDocs')

        orgSignDocs = filter(orgSignDocs, (signDoc) => {
            const orgSignDoc = find(signDoc.organizationSignDoc, {OrganizationId: get(selectedOrganizationData, 'organization.id')})
            if (!showPastSignDocs) {
                return orgSignDoc.isValid && new Date(orgSignDoc.finishByDate) >= yesterday && !signDoc.doNotForceCompletion
            }
            return orgSignDoc.isValid && !signDoc.doNotForceCompletion
        })

        signDocOptionsTmp = map(orgSignDocs, (signDoc) => {
            let prefix = getSignDocPrefix({signDoc, i18n});
            return {
                value: signDoc.id,
                text: `${prefix} - ${signDoc.docName}`,
                type: EntityTypes.SIGN_DOC
            }
        })


    }
    //unique signDocs options
    signDocOptionsTmp = filter(signDocOptionsTmp, (signDoc, index, self) => index === self.findIndex((t) => (t.value === signDoc.value)))
    signDocOptionsTmp.unshift({
        value: '', text: ''
    })
    //add empty option to signDocs
    return {signDocOptionsTmp};
}