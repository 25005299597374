import {filter, find, get, map} from "lodash";
import {EntityTypes} from "../../Notifications/NotificationsLib";
import {getSignDocPrefix} from "../../SignDoc/SignDocLib";

export const getSignDocOptions = ({
                                      selectedOrgOption,
                                      adminOrganizations,
                                      doNotForceCompletion,
                                      i18n
                                  }) => {
    let signDocOptionsTmp = []
    if (selectedOrgOption === 'all') {
        //get all courses from all organizations
        let orgSignDocs = []
        map(adminOrganizations, (org) => {
            let tmpOrgSignDocs = get(org, 'organization.signDocs')
            tmpOrgSignDocs = tmpOrgSignDocs.filter((signDoc) => {
                const orgSignDoc = find(signDoc.organizationSignDoc, {OrganizationId: get(org, 'organization.id')})
                if (doNotForceCompletion) {
                    return orgSignDoc.isValid && signDoc.doNotForceCompletion
                } else {
                    return orgSignDoc.isValid && !signDoc.doNotForceCompletion
                }
            })
            orgSignDocs = orgSignDocs.concat(tmpOrgSignDocs)
        })

        signDocOptionsTmp = map(orgSignDocs, (signDoc) => {
            let prefix = getSignDocPrefix({signDoc, i18n});
            return {
                value: signDoc.id,
                text: `${prefix} - ${signDoc.docName}`,
                type: EntityTypes.SIGN_DOC
            }
        })


    } else {
        const selectedOrganizationData = find(adminOrganizations, {organization: {id: selectedOrgOption}})
        let orgSignDocs = get(selectedOrganizationData, 'organization.signDocs')

        orgSignDocs = filter(orgSignDocs, (signDoc) => {
            const orgSignDoc = find(signDoc.organizationSignDoc, {OrganizationId: get(selectedOrganizationData, 'organization.id')})
            if (doNotForceCompletion) {
                return orgSignDoc.isValid && signDoc.doNotForceCompletion
            } else {
                return orgSignDoc.isValid && !signDoc.doNotForceCompletion
            }
        })

        signDocOptionsTmp = map(orgSignDocs, (signDoc) => {
            let prefix = getSignDocPrefix({signDoc, i18n});
            return {
                value: signDoc.id,
                text: `${prefix} - ${signDoc.docName}`,
                type: EntityTypes.SIGN_DOC
            }
        })


    }
    //unique signDocs options
    signDocOptionsTmp = filter(signDocOptionsTmp, (signDoc, index, self) => index === self.findIndex((t) => (t.value === signDoc.value)))
    signDocOptionsTmp.unshift({
        value: 'all', text: i18n.translate('allSignDocs')
    })
    //add empty option to signDocs
    return {signDocOptionsTmp};
}

export const DashboardChartLabels = ({i18n}) => {
    return {
        completed: {
            colorClass: 'colorYellow',
            label:
                i18n.translate('ReportsPage.courseLegend.completed')
        }
        ,
        inProgress: {
            colorClass: 'colorGrey',
            label:
                i18n.translate('ReportsPage.courseLegend.inProgress')
        }
        ,
        notStarted: {
            colorClass: 'colorLightGrey',
            label:
                i18n.translate('ReportsPage.courseLegend.notStarted')
        }
    }
}