import {map} from 'lodash'

export const heIL = {
    confirm: 'אישור',
    login: {
        identifier: 'מזהה',
        password: 'סיסמא',
        login: 'התחברות',
        verificationCodeFromSMS: 'קוד אימות שקיבלת ב SMS',
        messageWasSentTo: (key, {last4digits}) => `הודעת SMS נשלחה למספר המסתיים בספרות ${last4digits}`,
        validate: 'אימות',
        resendCode: 'שלח שוב',
        sendCode: 'שלח קוד אימות',
        helloX: (key, {name}) => `שלום ${name}`,
    },
    changePassword: {
        currentPassword: 'סיסמא נוכחית',
        password: 'סיסמא חדשה',
        repeatPassword: 'סיסמא חדשה',
        changePassword: 'שנה סיסמא'
    },
    logout: 'התנתק',
    menuItems: {
        logout: 'התנתק',
        changePassword: 'שנה סיסמא',
        adminPanel: `לוח מנהל`,
        home: 'מסך הבית',
        myOrgs: 'היחידות הארגוניות שלי',
        learningCenter: 'מרכז הלמידה',
        users: 'משתמשים',
        reports: 'דוחות',
        settings: 'הגדרות',
        learningManagement: 'ניהול למידה',
        filesManagement: 'ניהול קבצים',
        knowledgeCenter: 'מרכז הידע',
        superAdmin: {
            courses: 'ניהול לומדות',
            customers: 'ניהול לקוחות',
            organizations: 'ניהול יחידות ארגוניות',
            users: 'ניהול משתמשים',

        }
    },
    languages: {
        label: 'בחר שפה',
        languageKeys: {
            heIL: 'עברית',
            enUS: 'English',
            arIL: 'العربية',
            ruRU: 'Русский'
        }
    },
    learningCenter: {
        title: 'מרכז הלמידה שלי',
        subTitle: 'משימות וסטטוס הביצוע שלהן',
        coursesToDo: 'לומדות שטרם סיימתי',
        coursesCompleted: 'לומדות שסיימתי',
        noCoursesToDo: 'אין לומדות שטרם סיימתי',
        noCoursesCompleted: 'אין לומדות שסיימתי',
        signDocsToDo: 'מסמכים לחתימה',
        noSignDocsToDo: 'אין מסמכים לחתימה',
        noSignDocsCompleted: 'אין מסמכים שחתמתי',
        signDocsCompleted: 'מסמכים שחתמתי',
        noOpenTasks: 'אין משימות פתוחות',
        tabs: {
            myCourses: 'הלומדות שלי',
            mySignDocs: 'מסמכים לחתימה',
            myTasks: 'משימות פתוחות',
        }
    },
    settingsPage: {
        title: 'הגדרות',
        subTitle: 'התאימו את המערכת',
        subTitleBold: 'לצרכי החברה שלך',
        buttons: {
            updateLogo: "עדכון לוגו",
            updateOrgName: "עדכון שמות יחידות ארגוניות",
            updateNotes: "עדכון פתקים לעובדים",
        },
        editNotesModal: {
            title: 'עריכת פתקים לעובדים',
        },
        editOrganizationsNames: 'עריכת שמות יחידות ארגוניות',
        createNote: 'יצירת פתק חדש',
        newNoteTitle: 'פתק חדש',
        SMSQuota: {
            title: 'קרדיט הודעות',
            quota: 'הודעות שנרכשו',
            sent: (key, {
                sent,
                quota
            }) => `לידיעתך, עד היום נשלחו ${sent} הודעות ונותרו לך ${quota} הודעות לשימוש עתידי`,
            lastSMSWasSentOn: (key, {date}) => `שליחת ההודעות האחרונה מהמערכת בוצעה בתאריך ${date}`,
        },
        generalSettings: {
            title: 'כללי'
        },
        reminders: {
            title: 'תזכורות',
            course: 'לומדה',
            signDoc: 'מסמך לחתימה',
            watchVideo: 'צפייה בסרטון',
            canvaPresentation: 'צפייה ב Canva',
        },
        admins: {
            title: 'מנהלים',
            subTitle: 'ניהול והגדרות'
        }
    },
    HomePage: {
        courseLegend: {
            completed: 'סיימתי',
            inProgress: 'בתהליך',
            notStarted: 'לא התחלתי'
        },
    },
    home: {
        title: 'לומדות אפשריות עבורך',
        faq: 'שאלות נפוצות',
        welcome: 'ברוכים הבאים',
        // learningSubtitle:(key,{companyName}) => `לסביבת הלמידה האישית שלכם, עבור חברת ${companyName}`,
        learningSubtitle: "לסביבת הלמידה האישית, עבור",
        userChartLabels: {
            finishedCourses: 'קורסים שסיימתי',
            inProgressCourses: 'קורסים בתהליך',
            notStartedCourses: 'קורסים שלא התחלתי',
            label: 'התקדמות קורסים'
        },
        superAdmin: 'ממשק מנהל מערכת',
    },
    faq: {
        question1: 'האם ניתן לחזור ללומדות בכל שלב?',
        answer1: 'כן. אפשר ללמוד בקצב אישי מכל מקום ובכל זמן, מהפלאפון הנייד או מהמחשב (ממליצים לפתוח בדפדפן Google Chrome). שימו לב, ברגע שמתחילים לומדה יש לסיים לבצע אותה מאותו המכשיר.',
        question2: 'מה עושים בסיום הלומדות?',
        answer2: 'בסיום הלומדה יש ללחוץ על כפתור הסיום ולאשר שביצעת את הלומדה. יש לוודא שלא נשארו משימות פתוחות.',
        question3: 'צריכים עזרה?',
        answer3: 'לתמיכה ניתן לשלוח הודעת Whatsapp למספר 052-9663062, או בכתובת האימייל info@nakarmedic.co.il',
    },
    gender: {
        male: 'זכר',
        female: 'נקבה'
    },

    OrganizationsPage: {
        title: 'היחידות הארגוניות שלי',
        subTitle: 'ניהול והגדרות',
        organizations: 'יחידות ארגוניות בניהולי',
        importUsersFile: "ייבא קובץ משתמשים",
        editLogo: 'ערוך לוגו',
        dropLogoHere: 'גרור לוגו לכאן',
        currentLogo: 'לוגו נוכחי',
        changeForAllMyOrganizations: 'שנה לכל היחידות הארגוניות בניהולי',
        logoWillBeChangedForAllOrgs: '**הלוגו ישתנה לכל היחידות הארגוניות בניהולך',

    },
    UsersPage: {
        title: 'משתמשים',
        subTitle: 'ניהול והגדרות',
        userRowCourseMenu: {
            buttons: {
                addToCourse: 'רשום משתמש',
                removeFromCourse: 'הסר רישום משתמש',
            }
        },
        userRowSignDocMenu: {
            buttons: {
                addToSignDoc: 'רשום משתמש',
                removeFromSignDoc: 'הסר רישום',
                markCompleted: 'סמן כהושלם',
            }
        }
    },
    organizationUserTable: {
        headers: {
            name: "שם",
            permissions: "הרשאה",
            identifier: "מזהה"
        }
    },
    userType: {
        ADMIN: 'מנהל',
        USER: "משתמש"
    },
    note: {
        title: 'כותרת',
        content: 'תוכן',
        delete: 'מחיקת פתק',
        deleteNoteConfirmation: {
            title: 'מחיקת פתק',
            message: 'האם אתה בטוח שברצונך למחוק את הפתק?',
            noteName: 'שם הפתק',
            deleteNote: 'מחיקת פתק'
        }
    },
    organization: {
        organizationName: 'שם היחידה הארגונית',
    },
    courseCard: {
        youAreDone: `סיימת את הלומדה`,
        finishBy: (key, {finishByDate}) => `יש לסיים עד ל  ${finishByDate}`,
        youAreDoneWatchAgain: 'לצפייה חוזרת',
        goToCourse: 'מעבר ללומדה'
    },
    // courseProgress: {
    //     notStarted: 'לא התחלתי',
    //     inProgress: 'בתהליך',
    //     finished: 'סיימתי!',
    // },
    ReportsPage: {
        title: 'דוחות',
        subTitle: 'צפו בסטטוס הביצוע של העובדים',
        // subTitleBold: 'בתצורה גרפית.',
        courseLegend: {
            completed: 'סיימו',
            inProgress: 'בתהליך',
            notStarted: 'לא התחילו'
        },
        coursesStats: {
            title: 'סטטוס ביצוע לכל אחת מהלומדות',
        },
        signDocStats: {
            title: {
                course: 'סטטוס ביצוע לכל אחת מהלומדות',
                signDocs: 'סטטוס חתימות לכל אחד מהמסמכים'
            },
            graphs: {
                usersPerformanceNumbers: (key, {taskCount = 0}) => `מספר משתמשים לפי סטטוס ביצוע ${taskCount ? `(${taskCount} משימות)` : ''}`,
                usersPerformancePercent: (key, {taskCount = 0}) => `אחוז משתמשים לפי סטטוס ביצוע ${taskCount ? `(${taskCount} משימות)` : ''}`,
                performanceNumberByOrg: (key, {orgsCount = 0}) => `מספר ביצועים ביחידה הארגונית ${orgsCount ? `(${orgsCount} יחידות ארגוניות)` : ''}`,
                performancePercentByOrg: (key, {orgsCount = 0}) => `אחוז ביצוע ביחידה הארגונית ${orgsCount ? `(${orgsCount} יחידות ארגוניות)` : ''}`,
                signDocsCount: ' מסמכים לחתימה',
                activeCourseCount: 'פרטי למידה פעילים',
                usersCount: 'משתמשים',
                usersWithActiveTasks: 'משתמשים עם משימה פעילה',
                allSignDocsOfACompanyCount: 'פרטי למידה והדרכה',
                info: {
                    activeCourseCount: 'סה"כ פרטי למידה עם תאריך סיום עתידי (לומדות, מסמכים לחתימה ומשימות צפיה)',
                    usersCount: 'סה"כ משתמשים',
                    usersWithActiveTasks: 'סה"כ משתמשים עם לפחות משימה פעילה אחת',
                    allSignDocsOfACompanyCount: 'סה"כ פריטי למידה (לומדות, מסמכים לחתימה ומשימות צפיה)',
                    performanceNumberByOrg: 'סה"כ משימות שבוצעו מתוך כלל המשימות הנצברות\n (משימות נצברות = מספר פריטי הלמידה והדרכה כפול כמות המשתמשים שהוקצו לכל פריט)',
                    performancePercentByOrg: 'סה"כ משימות שבוצעו בכל יחידה ארגונית'
                }
            }
        },
        tabs: {

            dashboard: 'דשבורד',
            courses: 'לומדות',
            signDocs: 'מסמכים לחתימה',
            generatedReports: 'דוחות שהופקו'
        }
    },
    courseProgressMgmnt: {
        notStarted: 'לא התחיל',
        inProgress: 'בתהליך',
        finished: 'סיים',
        completedOn: 'תאריך חתימה: ',
        completedOnCourse: 'תאריך ביצוע: ',
        by: 'על ידי ',
        manual: 'ידני',
        finishBy: 'לסיום עד: ',
    },
    LearningManagementPage: {
        title: 'ניהול למידה',
        subTitle: 'הוספה, עריכה ומחיקה',
        addCourse: 'הוספת לומדה',
        editCourse: 'עריכת לומדה',
        deleteCourse: 'מחיקת לומדה',
        courseName: 'שם הלומדה',
        courseDescription: 'תיאור הלומדה',
        courseImage: 'תמונת הלומדה',
        createKnowledgeSource: 'יצירת מקור ידע',
        courseCard: {
            editCourse: 'עריכת לומדה',
        },
        signDocCard: {
            editSignDoc: {
                READ_AND_SIGN_DOC_PDF: 'עריכת מסמך',
                WATCH_AND_SIGN_YOUTUBE_LINK: 'עריכת משימת צפיה',
                WATCH_AND_SIGN_VIMEO_LINK: 'עריכת משימת צפיה',
                WATCH_AND_SIGN_VIDEO_FILE: 'עריכת משימת צפיה',
                REGULAR_COURSE: 'עריכת לומדה',
                CANVA_PRESENTATION: 'עריכת מצגת'
            },
            deleteModal: {
                title: 'מחיקת מסמך',
                text: 'האם הנכם בטוחים שברצונכם למחוק את המסמך? פעולה זו אינה ניתנת לביטול',
                confirm: 'מחיקת מסמך',
                signDocName: 'שם המסמך'
            }
        },
        users: {
            buttons: {
                downloadCsv: 'ייצוא לCSV',
                downloadCsvForOrganization: 'ייצוא משתמשים מהיחידה הארגונית שנבחרה',
                downloadCsvForAllOrganizations: 'ייצוא משתמשים מכל היחידות הארגוניות בניהולי',
            }
        },
        courseManagement: 'ניהול לומדות',
        signDocManagement: 'ניהול מסמכים',
        noSignDocs: 'אין מסמכים להצגה',
        tabs: {
            signDocs: 'מסמכים לחתימה',
            courses: 'לומדות',
            users: 'משתמשים',
            knowledgeCenter: 'מרכז הידע',
        }
    },
    editCourseModal: {
        title: 'עריכת לומדה',
        courseName: 'שם הלומדה',
        courseDescription: 'תיאור הלומדה',
        courseImage: 'תמונת הלומדה',
        save: 'שמור שינויים',
        edit: {
            courseName: 'שם הלומדה',
            courseDescription: 'תיאור הלומדה',
            dateEditNotification: 'באפשרותך לקבוע תאריך סיום אחיד לכל הרשומים לקורס בכל היחידות הארגוניות בניהולך, שינויים שתבצע כאן ידרסו שינויים פרטניים שבוצעו עבור משתמשים'
        },
        dateModal: {
            title: 'קביעת תאריך סיום',
            timeAndTimezone: 'עד 00:00 באיזור זמן: '
        }
    },
    editUserCourseModal: {
        title: (key, {userName, courseName}) => `קביעת תאריך סיום עבור קורס \"${courseName}\" למשתמש \"${userName}\"`,
        dateEditNotification: 'באפשרותך לקבוע תאריך סיום למשתמש, שינוי זה יהיה בתוקף על עוד לא תשנה את תאריך הסיום של כלל היחידה הארגונית'
    },
    AppSideMenu: {
        welcome: `ברוכים הבאים`,
    },
    superAdmin: {
        editFFModal: {
            modalTitle: "עריכת פיצ'רים לחברה",
            editFeatures: "עריכת פיצ'רים",
            featureFlags: {
                automationFF: 'אוטומציה',
                smsFF: 'SMS',
                whatsappFF: 'Whatsapp',
                emailFF: 'אימייל',
                signDocsFF: 'משימות',
                sendWelcomeMessageFF: 'שליחת הודעות למשתמש חדש',
                signDocsReadAndSignDocPdfFF: 'חתימה על PDF',
                signDocsWatchAndSignYoutubeLinkFF: 'אישור צפיה Youtube',
                signDocsWatchAndSignVimeoLinkFF: 'אישור צפיה Vimeo',
                signDocsWatchAndSignVideoFileFF: 'אישור צפיה קובץ וידאו',
                signDocCanvaPresentationFF: 'מצגת Canva',
                regularCourseFF: 'לומדות',
                notifyNewTaskToUserFF: 'הודעה על משימה חדשה',
                knowledgeCenterFF: 'מרכז הידע',
                orgAdminMgmtFF: 'ניהול הרשאות אדמינים',

            }
        },
        allUsersTable: {
            columns: {
                firstName: 'שם פרטי',
                lastName: 'שם משפחה',
                email: 'אימייל',
                phone: 'טלפון',
                roles: 'תפקידים',
                identifier: 'מזהה',
                organization: 'יחידה ארגונית',
                id: 'מזהה',
                companyName: 'חברה',
                yes: 'כן',
                no: 'לא',
                isSuperAdmin: 'מנהל מערכת',
                isCompanyAdmin: 'מנהל חברה',
                isDeleted: 'נמחק',
                edit: 'עריכה',
                permanentlyDelete: 'מחיקה לצמיתות',
                loginUrl: 'קישור להתחברות',
            },
            buttons: {
                downloadCsvForOrganization: 'הורדת קובץ Excel ליחידה ארגונית',
                downloadCsvForAllOrganizations: 'הורדת קבצי Excel לכל היחידות הארגוניות בניהולי',
                downloadCsvForOrganizationSuccess: 'הקבצים שלך יהיו מוכנים להורדה בקרוב תחת "תפריט -> ניהול קבצים"',
                deleteUsers: 'מחיקת משתמשים',
                edit: 'עריכה',
            }
        },
        allOrgsTable: {
            columns: {
                orgName: 'שם היחידה הארגונית',
                taxIdentifier: 'מזהה',
                companyId: 'מזהה חברה',
                orgAddress: 'כתובת',
                orgContactInfo: 'פרטי קשר',
                id: 'מזהה',
                companyName: 'שם החברה',
                delete: 'מחיקה',
            },
            deleteOrg: 'מחיקת יחידה ארגונית',
            deleteOrgTitle: 'מחיקת יחידה ארגונית: ',
            deleteOrgText: 'בטוח שברצונכם למחוק את היחידה הארגונית',
            deleteOrgConfirm: 'מחיקת יחידה ארגונית',


        },
        createNewCourse: {
            title: 'יצירת לומדה חדשה',
            docName: 'שם הלומדה',
            courseDescription: 'תיאור הלומדה',
            create: 'צור לומדה',
            dropZipHere: 'גרור קובץ zip של לומדה לכאן או לחץ לבחירת קובץ',
            modalTitle: 'יצירת לומדה חדשה',
            organizationsTable: {
                orgName: 'שם היחידה הארגונית',
                addOrganization: 'הוספת קורס ליחידה הארגונית',
                removeOrganization: 'הסרת קורס מהיחידה הארגונית',
                onlyOneCompanyPerCourse: 'ניתן להוסיף קורס לחברה אחת בלבד, לכן לא ניתן כעת לשנות בחירת חברה',
            }
        },

        createNewCustomer: {
            modalTitle: 'יצירת לקוח חדש',
            companyName: 'שם החברה',
            userPrefix: 'קידומת משתמשים (ייחודי לכל חברה - באנגלית בלבד)',
            identifier: 'מזהה',
            contactEmail: 'אימייל ליצירת קשר',
            contactName: 'שם ליצירת קשר',
            contactPhone: 'טלפון ליצירת קשר',
            userCreationTitle: 'צור משתמש ראשוני לחברה',
            detailsTitle: 'מלאו את פרטי החברה',
            userPassword: 'סיסמא למשתמש (אנגלית בלבד)',
            userIdentifier: 'מזהה למשתמש (אנגלית בלבד)',
            createCompany: 'צור חברה',
            userNameNoteNoPrefix: '*לפני שתוכל להזין שם משתמש יש להזין קידומת משתמשים',
            userNameNote: (key, {companyUsersPrefix, userIdentifier}) => {
                return '*שם המשתמש שיווצר בפועל יהיה: ' + companyUsersPrefix + "-" + (userIdentifier || '"מזהה משתמש"')
            },
            userPrefixError: 'קידומת משתמשים חייבת להיות באנגלית בלבד',
            userFirstName: 'שם פרטי',
            userLastName: 'שם משפחה',
            contactFirstName: 'שם פרטי ליצירת קשר',
            contactLastName: 'שם משפחה ליצירת קשר',
        },
        editCourse: {
            courseName: 'שם הלומדה',
            docName: 'שם הלומדה',
            courseDescription: 'תיאור הלומדה',
            description: 'תיאור הלומדה',
            dropZipHere: 'גרור קובץ zip של לומדה לכאן או לחץ לבחירת קובץ',
            dropZipNote: '**שימו לב, אתם במצב עריכה, לכן אם קובץ zip כבר קיים עבור הלומדה, העלאה נוספת תדרוס את הקיים*',
            saveChanges: 'שמור שינויים',
            modalTitle: 'עריכת לומדה',
        },
        editCustomer: {
            modalTitle: 'עריכת לקוח',
            adminUserDetails: 'פרטי משתמש ראשי',
            detailsTitle: 'עריכת פרטי החברה',
            organizationsTitle: 'יחידות ארגוניות של הלקוח',
            addOrganizations: 'הוספת יחידות ארגוניות ללקוח',
            addOrganizationModal: {
                organizationsList: 'רשימת יחידות ארגוניות',
                useCommaSeparatedValues: 'הזן ערכים מופרדים בפסיק, לדוגמא: "כספים, הנהלת חשבונות, פיתוח תוכנה"',
                title: 'הוספת יחידות ארגוניות ללקוח',
            }
        },
        coursesTable: {
            columns: {
                courseName: 'שם המשימה',
                description: 'תיאור המשימה',
                edit: 'עריכה',
                type: 'סוג המשימה',
                editCourse: 'עריכת לומדה',
                companyName: 'שם החברה',
                remove: 'מחיקה',
            }
        },
        customersTable: {
            columns: {
                companyName: 'שם החברה',
                taxIdentifier: 'מזהה',
                edit: 'עריכה',
                editCompany: 'עריכת חברה',
                smsQuota: 'קרדיט הודעות',
                featureFlags: "פיצ'רים",
                storageQuotaMB: 'אחסון (MB)',
                editQuota: 'עריכת מכסות'
            },
            quota: {
                editQuota: 'עריכת מכסות',
                editQuotaTitle: 'עריכת מכסות לחברה',
                editCredits: 'עריכת קרדיטים',
                MessagesCreditsToEdit: 'קרדיטי הודעות',
                storageQuotaToEdit: 'מקום אחסון ב MB',
            }

        }
    },
    dataGrid: {
        filters: {
            search: {
                input: 'חיפוש',
            },
        },
        filterLabels: {
            course: 'קורס',
            organization: 'יחידה ארגונית',
            company: 'חברה',
            signDoc: 'משימות'
        }
    },
    importUsersPage: {
        downloadExample: 'הורד קובץ תבנית ליצירת משתמשים',
        downloadUsersAsCSV: 'הורדת משתמשים כקובץ CSV',
    },
    importUsersModal: {
        title: 'ייבוא / עריכת משתמשים ויחידות',
        tabs: {
            importUsers: {
                title: 'ייבוא משתמשים והוספת יחידות',
            },
            reorganizeUsers: {
                title: 'ייבוא משתמשים ושינוי יחידות',
            }
        },
        manyUsersMessage: 'נראה שישנם משתמשים רבים בקובץ, ייתכן שהייבוא יארך מספר דקות, לאחר לחיצה על יצירה, אנא השאירו את החלון פתוח עד לסיום הטעינה ולאחריה, חכו מספר דקות לפני שכל השינויים ישוקפו במערכת',
        users: {
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            identifier: 'מזהה',
            phone: 'טלפון',
            email: 'אימייל',
            organizations: 'יחידות ארגוניות',
        },
        createUsers: 'יצירת/עריכת משתמשים',
        selectOrganization: 'בחרו יחידות ארגוניות עבורה יווצרו המשתמשים',
        selectOrganizationsToAdd: 'בחרו יחידות ארגוניות להוספה',
        organization: 'יחידה ארגונית',
        newUsersWillBeCreatedAndOrganizationsWillBeAddedToAllUsers: 'היחידות הארגוניות יתווספו לכל המשתמשים שימצאו בקובץ המצורף, משתמשים שלא קיימים יווצרו, משתמשים קיימים יעודכנו',
        newUsersWillBeCreatedAndOrganizationsWillBeOverride: 'היחידות הארגוניות ישונו עבור כל המשתמשים בקובץ המצורף (משתמשים יוסרו מיחידות שאינן מסומנות), משתמשים שלא קיימים יווצרו, משתמשים קיימים יעודכנו',
        errors: {
            missingMandatoryField: (key, {row}) => `*שדות חובה חסרים בשורה ${row}:`,
            twoSourcesForOrgs: 'לא ניתן לבחור יחידות ארגוניות משני מקורות, אנא הסירו את היחידות הארגוניות מהקובץ המיובא או מהבחירה בראש העמוד' ,
            removeOrgsFromFile: 'אנא הסירו את היחידות הארגוניות מהקובץ המיובא' ,
            missingSourceForOrgs: 'לא נבחרו יחידות ארגוניות למשתמשים, אנא בחרו יחידות ארגוניות מהבחירה בראש העמוד או הוסיפו יחידות ארגוניות על פי קובץ תבנית הייבוא',
            missingSourceForOrgsSelectFromMultiSelect: 'לא נבחרו יחידות ארגוניות למשתמשים, אנא בחרו יחידות ארגוניות מהבחירה בראש העמוד',

        }
    },
    uploadBox: {
        uploading: 'מעלה קובץ...',
        uploaded: 'הקובץ הועלה בהצלחה',
        uploadFailed: 'הקובץ אינו תקין, אנא בדוק שגודל הקובץ וסוג הקובץ לא חורגים מן המותר',
        maxFileSize: (key, {maxFileSize}) => `גודל הקובץ המקסימלי המותר הוא ${maxFileSize}`,
        onlyFileTypes: (key, {fileTypes}) => `סוגי הקבצים המותרים להעלאה הם: ${map(fileTypes, fileType => `${fileType}`).join(', ')}`,
    },
    completedCoursePage: {
        title: 'סיום לומדה',
        subTitle: 'אנא אשר כי סיימת את הלומדה',
        buttons: {
            confirmCourseCompletion: 'אני מאשר שסיימתי את הלומדה'
        },
        finishedSuccessfully: 'סיימת בהצלחה את הלומדה',
        oops: 'אופס...',
        youGotLost: 'נראה שאיבדת את דרכך, נווט בעזרת התפריט',
        courseCompletedSuccessfullyPopup: 'סיימת בהצלחה את הלומדה',
    },
    errors: {
        clientError: 'שגיאה בפרטים שהוזנו',
        serverError: 'שגיאה בפניית השרת',
        generalServerError: 'שגיאה כללית בפנייה לשרת, נסו לטעון מחדש את העמוד, אם הבעיה נמשכת פנו לתמיכה הטכנית',
        notFound: 'המשאב לא נמצא',
        forbidden: 'אין לך הרשאה לגשת למשאב זה',
        privateRouteUserNotLoggedIn: 'המשתמש אינו מחובר ולכן לא ניתן לראות את התכנים, אנא התחבר ולאחר מכן נסה שוב'
    },
    alertDialog: {
        ok: 'אישור',
        cancel: 'ביטול',
    },
    createEditUserModal: {
        createUser: 'צור משתמש',
        titleCreate: 'יצירת/עדכון משתמש',
        titleEdit: 'עריכת משתמש',
        selectOrganization: 'בחירת יחידה ארגונית',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        identifier: 'מזהה',
        phone: 'טלפון',
        email: 'אימייל',
        editUser: 'עריכת משתמש',
        deleteUserTitle: 'מחיקת משתמש',
        deleteUserText: 'האם בטוח שברצונך למחוק את המשתמש',
        deleteOrgConfirm: 'מחק משתמש',
        deleteUserButton: 'מחק משתמש',
        userIsCompanyAdmin: 'מנהל בחברה',
        mfaRequired: 'נדרש אימות דו שלבי',
        sendWelcomeMessage: 'שליחת הודעת חיבור ראשוני למשתמשים',
        save: 'שמירת/עדכון משתמש',
        userWillBeEditedIfPhoneExists: 'אם הטלפון כבר משוייך למשתמש בחברה, המשתמש והארגונים אליו הוא משוייך יעודכנו',
    },
    allUsersTable: {
        columns: {
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            email: 'אימייל',
            phone: 'טלפון',
            roles: 'תפקידים',
            identifier: 'מזהה',
            organization: 'יחידה ארגונית',
            id: 'מזהה',
            edit: 'עריכה',
            delete: 'מחיקה',
            progress: 'התקדמות',
            notRegistered: 'לא רשום לקורס'
        }
    },
    removeCourseFromCompanyCol: {
        deleteCourseButton: 'מחק לומדה',
        removeCourseTitle: 'מחיקת לומדה',
        removeCourseText: 'האם אתה בטוח שברצונך למחוק את הלומדה',
        removeCourseConfirm: 'מחק לומדה',
    },
    CourseReminderNotificationButton: {
        alerts: {
            reminderSent: 'התזכורות יצאו לדרך!',
            reminderNotSent: 'שגיאה בשליחת תזכורת',
            sentMessages: 'הודעות שנשלחו',
            notSentMessages: 'הודעות שלא נשלחו',
            notSentMessagesInfo: '* יכולות להיות שתי סיבות לכך שלא כל ההודעות נשלחו: חוסר בפרטי איש קשר או מספר סלולרי שגוי',
            sendToUsersWhoDidNotReceiveNotification: 'שלח תזכורת למשתמשים שלא קיבלו עד כה',
            sendToAllUsers: 'שלח תזכורת לכל משתמשי הקורס שטרם סיימו',
            error: 'שגיאה',
            company_has_no_sms_quota: 'אין לחברה מספיק קרדיטי הודעות זמינים, אנא צור קשר עם שירות לקוחות על מנת לרכוש חבילת הודעות',
        }
    },
    FilesManagementPage: {
        title: 'ניהול קבצים',
        subTitle: 'צפייה, הוספה ועריכה',
        fileManagementTable: {
            columns: {
                fileName: 'קישור להורדה',
                presentationName: 'שם לתצוגה',
                isReady: 'מוכן להורדה',
                companyName: 'חברה',
                organizationNames: 'יחידות ארגוניות',
                date: 'תאריך יצירה',
                createdByUser: 'נוצר ע"י',
            },
            download: 'הורדה',
            inProgress: 'בתהליך יצירה',
            ready: 'מוכן להורדה',
            allOrganizations: 'כל היחידות הארגוניות',
        }
    },
    SMSNotificationButton: {
        title: 'שליחת תזכורת',
        subTitle: 'באופן מיידי'
    },
    sendSMSModal: {
        title: 'את מי אנחנו מתזכרים?',
        sms: 'SMS',
        whatsapp: 'WhatsApp',
        email: 'Email',
        selectMessageDeliveryWay: 'ההודעות ישלחו באמצעות:',
        sendToOptions: {
            all: 'משתמשים שטרם סיימו משימה ספציפית',
            onlyNotNotified: 'כל מי שטרם סיים את הלומדה ולא קיבל תזכורת',
            allWithAnyOpenTasks: 'משתמשים עם משימות פתוחות',
            pleaseSelect: 'אנא בחר את הקבוצה שברצונך לשלוח לה',
            label: 'למי?'
        },
        taskOptions: {
            label: 'איזו משימה?',
            pleaseSelect: 'אנא בחרו את הלומדה שברצונכם לשלוח תזכורת עבורה',
        },
        organizationOptions: {
            label: 'איזו יחידה ארגונית?',
            pleaseSelect: 'אנא בחרו את היחידה הארגונית שברצונכם לשלוח תזכורת עבורה',
        },
        smsStatusInformation: {
            statusTitle: 'מעולה!',
            statusUserToNotify: 'אז אנחנו מתזכרים סה"כ ',
            bySMS: 'באמצעות SMS',
            byWhatsApp: 'באמצעות WhatsApp',
            byEmail: 'באמצעות אימייל',
            statusCompanyQuota: 'לידיעתך, לאחר השליחה יישארו לך ',
            SMSForUse: 'הודעות לשימוש',
            employees: 'עובדים',
            theyWillReceiveItAsFollowing: 'שיקבלו את ההודעות בדרכים הבאות'
        },
        error: {
            noUsersToSendOnlyNotNotified: 'אין משתמשים לשליחה, כל המשתמשים שטרם סיימו את הלומדה קיבלו תזכורת',
            notEnoughSMS: 'אין לך מספיק קרדיטי הודעות זמינים, אנא צור קשר עם שירות לקוחות על מנת לרכוש חבילת הודעות',
            noUsersInCourse: 'אין משתמשים לשליחה, כל המשתמשים בקורס זה סיימו את הלומדה',
            youAreLeftWithQuota: 'נותרו לך עוד',
            youNeed: 'ונדרשים סה"כ',
            toCompleteTheProcess: 'להשלמת התהליך',
        },
        send: 'שלח'
    },
    SADeleteUsersModal: {
        title: 'מחיקת משתמשים',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'אימייל',
        phone: 'טלפון',
        identifier: 'מזהה',
        message: (key, {userCount}) => `האם אתה בטוח שברצונך למחוק את ${userCount} המשתמשים הבאים?`,
        deleteUsers: 'מחק משתמשים',
    },
    SARemoveUser: {
        permanentlyDeleteUser: 'מחיקה לצמיתות',
        deleteUserTitle: 'מחיקת משתמש לצמיתות',
        deleteUserText: 'האם אתה בטוח שברצונך למחוק לצמיתות את המשתמש',
        deleteOrgConfirm: 'מחק משתמש לצמיתות',
    },
    automations: {
        title: 'אוטומציות',
        subTitle: 'ניהול והגדרות',
        addAutomation: 'הוספת אוטומציה',
        editAutomation: 'עריכת אוטומציה',
        deleteAutomation: 'מחיקת אוטומציה',
        automationName: 'שם האוטומציה',
        automationDescription: 'תיאור האוטומציה',
        automationImage: 'תמונת האוטומציה',
        automationCard: {
            editAutomation: 'עריכת אוטומציה',
        },
        notifications: {
            title: 'יצירת אוטומציה',
            subTitle: 'לתזמון תזכורות',
        },
        createAutomationModal: {
            weWillSendNotificationTo: 'נתזכר את כל מי שלא סיים את לומדה ',
            allOrganizations: 'כל היחידות הארגוניות',
            organizationOptions: {
                label: 'איזו יחידה ארגונית?',
                pleaseSelect: 'אנא בחרו את היחידה הארגונית שברצונך לשלוח תזכורת עבורה',
                error: 'יש לבחור יחידה ארגונית לשליחת התזכורת'
            },
            taskOptions: {
                label: 'איזו משימה?',
                pleaseSelect: 'אנא בחרו את המשימה שברצונכם לשלוח תזכורת עבורה',
                error: 'יש לבחור לומדה לשליחת התזכורת'
            },
            selectHourLabel: 'באיזו שעה?',
            selectHourError: 'יש לבחור שעה לשליחת התזכורת',
            howManyDaysBefore: 'כמה ימים לפני?',
            howManyDaysBeforeError: 'יש לבחור כמה ימים לפני תרצו לשלוח את התזכורת',
            nameLabel: 'שם האוטומציה',
            descriptionLabel: 'תיאור האוטומציה',
            create: 'צור אוטומציה',
        }
    },
    automationsTable: {
        columns: {
            automationName: 'שם האוטומציה',
            description: 'תיאור האוטומציה',
            organization: 'יחידה ארגונית',
            task: 'משימה',
            when: 'מתי?',
            status: 'סטטוס',
            how: 'איך שולחים?',
            delete: 'מחיקה',
            edit: 'עריכה',
        },

        status: {
            SCHEDULED: 'תוזמן',
            COMPLETED: 'נשלח',
            sentAndNotSent: (key, {sent, notSent}) => `נשלחו ${sent} ולא נשלחו ${notSent}`,
        },
        sendType: {
            SMS: 'הודעת טקסט',
            EMAIL: 'אימייל',
            WHATSAPP: 'וואטסאפ',
        },
        atHour: 'בשעה',
        daysBefore: 'ימים לפני תאריך סיום הלומדה',
    },
    allOrganizations: 'כל היחידות הארגוניות',
    allSignDocs: 'כל המשימות',
    confirmationModal: {
        confirm: 'אישור',
        cancel: 'ביטול',
    },
    deleteAutomationModal: {
        title: 'מחיקת אוטומציה',
        message: 'האם אתה בטוח שברצונך למחוק את האוטומציה?',
        deleteAutomation: 'מחק אוטומציה',
        automationName: 'שם האוטומציה',
    },
    newDocSign: {
        buttonTitle: 'יצירת משימה חדשה',
        modal: {
            title: 'יצירת מסמך חדש',
            docNameLabel: 'שם המסמך',
            docDescriptionLabel: 'תיאור המסמך',
            createDocument: 'צור מסמך',
            dropSignDocHere: 'גרור קובץ של מסמך לכאן או לחץ לבחירת קובץ',
            selectSignDocType: 'סוג',
            linkUrl: 'קישור',
            youtubeLinkNotAllowed: 'לא ניתן להזין קישור ל Youtube',
            vimeoLinkNotAllowed: 'לא ניתן להזין קישור ל Vimeo',
            invalidLink: "קישור לא תקין",
            notifyUsersOfNewTask: 'שלח הודעה למשתמשים לגבי משימה חדשה',
            forceCompletion: 'נדרש לבצע',
            nonForceCompletion: 'לא נדרש לבצע',
            noDate: 'ללא תאריך סיום'
        }
    },
    signDocProgress: {
        notStarted: {
            READ_AND_SIGN_DOC_PDF: 'לא קראתי',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'לא צפיתי',
            WATCH_AND_SIGN_VIMEO_LINK: 'לא צפיתי',
            WATCH_AND_SIGN_VIDEO_FILE: 'לא צפיתי',
            REGULAR_COURSE: 'לא התחלתי',
            CANVA_PRESENTATION: 'לא צפיתי',
        },
        inProgress: {
            READ_AND_SIGN_DOC_PDF: 'קראתי',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'צפיתי',
            WATCH_AND_SIGN_VIMEO_LINK: 'צפיתי',
            WATCH_AND_SIGN_VIDEO_FILE: 'צפיתי',
            REGULAR_COURSE: 'התחלתי',
            CANVA_PRESENTATION: 'צפיתי',
        },
        finished: {
            READ_AND_SIGN_DOC_PDF: 'חתמתי!',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'חתמתי!',
            WATCH_AND_SIGN_VIMEO_LINK: 'חתמתי!',
            WATCH_AND_SIGN_VIDEO_FILE: 'חתמתי!',
            REGULAR_COURSE: 'סיימתי!',
            CANVA_PRESENTATION: 'חתמתי!',
        }
    },
    signDocProgressMgmnt: {
        notStarted: 'לא נפתח',
        inProgress: 'נפתח',
        finished: 'חתום'
    },
    signDocPage: {
        title: {
            read: {
                sign: 'אישור קריאת מסמך',
                noSign: 'קריאת מסמך',
                knowledgeOnly: 'קריאת מסמך'
            },
            watch: {
                sign: 'אישור צפייה בסרטון',
                noSign: 'צפייה בסרטון',
                knowledgeOnly: 'צפייה בסרטון'
            },
        },
        subTitle: {
            read: {
                sign: 'אשרו וחתמו כי קראתם את המסמך',
                noSign: 'קריאה חוזרת לאחר חתימה',
            },
            watch: {
                sign: 'אשרו וחתמו כי צפיתם בסרטון',
                noSign: 'צפייה חוזרת לאחר חתימה',
            },
        },
        signatureTitle: {
            read : 'חתמו בכדי לאשר כי קראתם את המסמך',
            watch: 'חתמו בכדי לאשר כי צפיתם בסרטון',
        },
        signatureSubtitle: {
            read: 'על מנת לאשר את קריאת המסמך חתמו כאן',
            watch: 'על מנת לאשר את הצפיה המסמך חתמו כאן',
        },
        signatureSubtitleNotCompleted: {
            read: 'לאחר הקריאה יופיעו משטח לחתימה וכפתור לאישר',
            watch: 'לאחר הצפיה יופיעו משטח לחתימה וכפתור לאישר',
        },
        iApproveReadingTheDoc: {
            read: 'קראתי את המסמך!',
            watch: 'צפיתי בסרטון!',
        },
        clear: 'נקה חתימה'
    },
    signDocCard: {
        youAreDone: {
            READ_AND_SIGN_DOC_PDF: 'חתמת על המסמך',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'צפית בסרטון',
            WATCH_AND_SIGN_VIMEO_LINK: 'צפית בסרטון',
            WATCH_AND_SIGN_VIDEO_FILE: 'צפית בסרטון',
            REGULAR_COURSE: 'סיימת את הלומדה',
            CANVA_PRESENTATION: 'צפית במצגת',
        },
        finishBy: {
            READ_AND_SIGN_DOC_PDF: (key, {finishByDate}) => `יש לחתום עד ל  ${finishByDate}`,
            WATCH_AND_SIGN_YOUTUBE_LINK: (key, {finishByDate}) => `יש לצפות עד ל  ${finishByDate}`,
            WATCH_AND_SIGN_VIMEO_LINK: (key, {finishByDate}) => `יש לצפות עד ל  ${finishByDate}`,
            WATCH_AND_SIGN_VIDEO_FILE: (key, {finishByDate}) => `יש לצפות עד ל  ${finishByDate}`,
            REGULAR_COURSE: (key, {finishByDate}) => `יש לסיים עד ל  ${finishByDate}`,
            CANVA_PRESENTATION: (key, {finishByDate}) => `יש לצפות עד ל  ${finishByDate}`,
        },
        youAreDoneWatchAgain: {
            READ_AND_SIGN_DOC_PDF: 'קרא שוב',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'צפה שוב',
            WATCH_AND_SIGN_VIMEO_LINK: 'צפה שוב',
            WATCH_AND_SIGN_VIDEO_FILE: 'צפה שוב',
            REGULAR_COURSE: 'צפה שוב',
            CANVA_PRESENTATION: 'צפה שוב',
        },
        goToCourse: {
            READ_AND_SIGN_DOC_PDF: 'מעבר לקריאה',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'מעבר לצפייה',
            WATCH_AND_SIGN_VIMEO_LINK: 'מעבר לצפייה',
            WATCH_AND_SIGN_VIDEO_FILE: 'מעבר לצפייה',
            REGULAR_COURSE: 'מעבר ללומדה',
            CANVA_PRESENTATION: 'מעבר לצפייה',
        },
    },
    Errors_409: {
        storage_quota_error: (key, {bytesCompanyStorageQuota, bytesCompanyUsedStorage, bytesNewUsedStorage}) => {
            const leftQuotaMB = ((bytesCompanyStorageQuota - bytesCompanyUsedStorage) / (1024 * 1024)).toFixed(2) + ' MB'
            const bytesNewUsedStorageMB = (bytesNewUsedStorage / (1024 * 1024)).toFixed(2) + ' MB'
            const bytesCompanyStorageQuotaMB = (bytesCompanyStorageQuota / (1024 * 1024)).toFixed(2) + ' MB'
            return `אין מספיק מקום אחסון זמין, נסו למחוק קבצים או לפנות למנהל המערכת. נשארו לכם ${leftQuotaMB} מתוך ${bytesCompanyStorageQuotaMB} זמינים, והקובץ שאתה מנסה להעלות גודלו ${bytesNewUsedStorageMB}`
        }
    },
    signDocTypes: {
        READ_AND_SIGN_DOC_PDF: 'מסמך PDF לחתימה',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'לינק יוטיוב לצפיה',
        WATCH_AND_SIGN_VIMEO_LINK: 'לינק VIMEO לצפיה',
        WATCH_AND_SIGN_VIDEO_FILE: 'קובץ וידאו לצפיה',
        REGULAR_COURSE: 'לומדה',
        CANVA_PRESENTATION: 'מצגת Canva לצפיה',
    },
    signDocTypesNotForCompletion: {
        READ_AND_SIGN_DOC_PDF: 'מסמך PDF',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'לינק יוטיוב',
        WATCH_AND_SIGN_VIMEO_LINK: 'לינק VIMEO',
        WATCH_AND_SIGN_VIDEO_FILE: 'קובץ וידאו',
        REGULAR_COURSE: 'לומדה',
        CANVA_PRESENTATION: 'מצגת Canva',
    },
    managementCourseList: {
        signDocButtonTitle: 'יצירת משימת צפיה'
    },
    isCompanyAdmin: {
        makeAdmin: {
            title: 'הגדרה כמנהל חברה',
            message: 'האם אתם בטוחים שברצונכם להגדיר את המשתמש כמנהל חברה?',
            confirm: 'הגדרה כמנהל חברה',
            button: 'הגדר כמנהל חברה',
        },
        removeAdmin: {
            title: 'הסרת מנהל חברה',
            message: 'האם אתה בטוחים שברצונכם להסיר את המשתמש מהתפקיד של מנהל חברה?',
            confirm: 'הסרה כמנהל חברה',
            button: 'הסר כמנהל חברה',
        },
        userName: 'שם משתמש',
    },
    general: {
        saveChanges: 'שמור שינויים',
        close: 'סגור',
        cancel: 'ביטול',
    },
    knowledgeCenter: {
        title: 'מרכז הידע',
        subTitle: 'כל המידע במקום אחד',
    },
    adminUsersTable: {
        columns: {
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            email: 'אימייל',
            phone: 'טלפון',
            roles: 'תפקידים',
            identifier: 'מזהה',
            managerOfOrganizations: 'מנהל ביחידות ארגוניות',
            id: 'מזהה',
            edit: 'עריכה',
            isCompanyAdmin: 'מנהל חברה',
            isCompanyAdminYes: 'כן',
            isCompanyAdminNo: 'לא',
        },
        addAdminUser: 'הוספת מנהל',
    },
    editAdminUserModal: {
        title: 'שיוך מנהל',
        titleEdit: 'עריכת מנהל',
        sendWelcomeMessage: 'שליחת הודעת חיבור ראשוני למנהל',
        save: 'שמור שינויים',
        selectOrganizationalUnits: 'חיפוש יחידות ארגוניות',
        searchUser: 'חיפוש משתמש',
        isCompanyAdmin: 'מנהל חברה',
        companyAdminWillBeAdminOfAllOrgs: 'מנהל חברה יהיה מנהל בכל היחידות הארגוניות',
        selectAllOrgs: 'בחר את כל היחידות הארגוניות',
    },
    autoComplete: {
        search: 'חיפוש',
        selectAll: 'בחר הכל',
    },
    UserIconMenu: {
        settings: 'הגדרות',
    }

}