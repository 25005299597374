import './LearningManagement.scss';
import {PageTitle} from "../PageTitle/PageTitle";
import React, {useContext, useMemo} from "react";
import {SignDocsList} from "./SignDocsList/SignDocsList";
import {RoutingTabs} from "../../Utils/RoutingTabs/RoutingTabs";
import {FeatureFlags} from "../../Utils/Constants";
import {Users} from "../Users/Users";
import {SignDocTypes} from "../SignDoc/SignDocLib";
import {I18nReactContext} from "../../contexts/I18nContext";

const tabs = ({i18n}) => [
    {label: i18n.translate('LearningManagementPage.tabs.users'), subPath: 'users', element: <Users/>},
    {
        label: i18n.translate('LearningManagementPage.tabs.courses'),
        subPath: 'courses',
        element: <SignDocsList
            showForceCompletion={true}
            showNonForceCompletion={false}
            key={'courses'}
            types={[SignDocTypes.REGULAR_COURSE, SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK, SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK, SignDocTypes.CANVA_PRESENTATION]}/>
    },
    {
        label: i18n.translate('LearningManagementPage.tabs.signDocs'),
        subPath: 'signDocs',
        element: <SignDocsList
            showForceCompletion={true}
            showNonForceCompletion={false}
            key={'signDocs'}
            types={[SignDocTypes.READ_AND_SIGN_DOC_PDF]}/>,
        requiredFeatureFlags: [FeatureFlags.SIGN_DOCS_READ_AND_SIGN_DOC_PDF]
    },
    {
        label: i18n.translate('LearningManagementPage.tabs.knowledgeCenter'),
        subPath: 'adminKnowledgeCenter',
        element: <SignDocsList
            showForceCompletion={false}
            showNonForceCompletion={true}
            key={'adminKnowledgeCenter'}
            types={[SignDocTypes.READ_AND_SIGN_DOC_PDF, SignDocTypes.REGULAR_COURSE, SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK, SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK, SignDocTypes.CANVA_PRESENTATION]}/>,
        requiredFeatureFlags: [FeatureFlags.KNOWLEDGE_CENTER]
    },
    // { label: i18n.translate('settingsPage.users.title'), subPath: 'users', element: <Box>Users</Box> },

]
export const LearningManagement = () => {
    const {i18n} = useContext(I18nReactContext);
    const memoizedTabs = useMemo(() => tabs({i18n}), [i18n]);

    return <div className="LearningManagement MenuPage">
        <PageTitle title={i18n.translate('LearningManagementPage.title')}
                   size="small"
                   subTitle={i18n.translate('LearningManagementPage.subTitle')}/>
        <div className="menuInnerPage">
            <div className="learningManagementTabWrapper">
                <RoutingTabs tabs={memoizedTabs} baseRoute={'learningManagement'}/>
            </div>
        </div>
    </div>
}