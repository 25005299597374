import React, {useState, useContext} from "react";
import {PageTitle} from "../PageTitle/PageTitle";
import {I18nReactContext} from "../../contexts/I18nContext";


export const SuperAdminHome = () => {
    const [organizations, setOrganizations] = useState()
    const {i18n} = useContext(I18nReactContext);

    return <div className="home MenuPage">
        <PageTitle
            title={i18n.translate('home.welcome')}
            subTitle={i18n.translate('home.superAdmin')}/>
        <div className="menuInnerPage">
            <div className="homeContent">

            </div>
        </div>

    </div>
}