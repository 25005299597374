// export const tableDateFormat = 'MMM DD, YYYY h:mm A (UTC)' //AM PM
export const tableDateFormat = 'MMM DD, YYYY HH:mm (UTC)'

export const AutomationTypes = {
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    WHATSAPP: 'WHATSAPP'
}

export const AutomationSubTypes = {
    COURSE_TO_FINISH_REMINDER: 'COURSE_TO_FINISH_REMINDER',
    DOC_TO_SIGN_REMINDER: 'DOC_TO_SIGN_REMINDER'

}

export const FeatureFlags = {
    AUTOMATION: 'automationFF',
    SMS: 'smsFF',
    EMAIL: 'emailFF',
    WHATSAPP: 'whatsappFF',
    SIGN_DOCS_READ_AND_SIGN_DOC_PDF: 'signDocsReadAndSignDocPdfFF',
    SIGN_DOCS_WATCH_AND_SIGN_YOUTUBE_LINK: 'signDocsWatchAndSignYoutubeLinkFF',
    SIGN_DOCS_WATCH_AND_SIGN_VIMEO_LINK: 'signDocsWatchAndSignVimeoLinkFF',
    SIGN_DOCS_WATCH_AND_SIGN_VIDEO_FILE: 'signDocsWatchAndSignVideoFileFF',
    SIGN_DOC_REGULAR_COURSE: 'regularCourseFF',
    SEND_WELCOME_MESSAGE: 'sendWelcomeMessageFF',
    NOTIFY_NEW_TASK_TO_USER: 'notifyNewTaskToUserFF',
    KNOWLEDGE_CENTER: 'knowledgeCenterFF',
    ORG_ADMIN_MGMT: 'orgAdminMgmtFF',
    SIGN_DOC_CANVA_PRESENTATION: 'signDocCanvaPresentationFF',
    LANGUAGES: 'languagesFF'
}

export const MessageTypes = {
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    WHATSAPP: 'WHATSAPP'
}