import './Settings.scss'
import {PageTitle} from "../PageTitle/PageTitle";
import React, {useContext} from "react";
import {SettingsTabs} from "./SettingsTabs/SettingsTabs";
import {I18nReactContext} from "../../contexts/I18nContext";

export const Settings = () => {
    const {i18n} = useContext(I18nReactContext);

    return <div className="Settings MenuPage">
        <PageTitle
            size="small"
            title={i18n.translate('settingsPage.title')}
            subTitle={i18n.translate('settingsPage.subTitle')}
            subTitleBold={i18n.translate('settingsPage.subTitleBold')}/>
        <div className="menuInnerPage">
            <div className="settingsWrapper">
                <div className="settingsTabsWrapper">
                    <SettingsTabs/>
                </div>
            </div>
        </div>

    </div>

}