import {debounce, get, keys, map, reduce} from "lodash";
import './SignDocsStats.scss'
import React, {useContext, useEffect, useRef, useState} from "react";
import {getSignDocsStatistics} from "../../../Services/api";
import {MultipleHorizontalValueBars} from "../MultiplHorizontalValueBars/MultipleHorizontalValueBars";
import {StatsWidgetWrapper} from "../StatsWidgetWrapper/StatsWidgetWrapper";
import {OrganizationFilter} from "../../../Utils/GridFilters/OrganizationFilter/OrganizationFilter";
import {useUserAdminOrgs} from "../../Hooks/UseUserAdminOrgs";
import {SignDocFilter} from "../../../Utils/GridFilters/SignDocFilter/SignDocFilter";
import {DashboardChartLabels, getSignDocOptions} from "./SignDocsStatsLib";
import {DoughnutChartLegend} from "../../Charts/DoughnutChartLegend/DoughnutChartLegend";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {I18nReactContext} from "../../../contexts/I18nContext";

export const SignDocsStats = ({types, textType}) => {
    const {i18n} = useContext(I18nReactContext);
    const [signDocStatisticsBySignDocId, setSignDocStatisticsBySignDocId] = useState()
    const [signDocStatisticsByOrganizationId, setSignDocStatisticsByOrganizationId] = useState()
    const [signDocCompleteStatusBySignDocId, setSignDocCompleteStatusBySignDocId] = useState()
    const [signDocCompleteStatusByOrganization, setSignDocCompleteStatusByOrganization] = useState()
    const [orgsFilterModel, setOrgsFilterModel] = useState({})
    const [signDocsFilterModel, setSignDocsFilterModel] = useState({})
    const [signDocCount, setSignDocCount] = useState()
    const [usersCount, setUsersCount] = useState()
    const [usersWithActiveTask, setUsersWithActiveTask] = useState()
    const [allSignDocsOfACompanyCount, setAllSignDocsOfACompanyCount] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({
        withAllOption: true,
        withCoursesAndSignDocs: true
    })
    const SignDocCompleteStatusBySignDocId = () => {
        let tmpSignDocStatistics = map(keys(signDocStatisticsBySignDocId), (signDocId, index) => {
            const signDoc = signDocStatisticsBySignDocId[signDocId]
            signDoc.data = [signDoc.completed, signDoc.inProgress, signDoc.notStarted]
            signDoc.label = signDoc.docName
            signDoc.id = signDocId
            return signDoc
        })

        const newStatisticsData = map(keys(tmpSignDocStatistics), (signDocId, index) => {
            const signDoc = tmpSignDocStatistics[signDocId]
            return {
                barId: signDocId,
                barTitle: signDoc.docName,
                values: [
                    {
                        value: signDoc.completed,
                        colorClass: 'colorYellow',
                        label: i18n.translate('ReportsPage.courseLegend.completed')
                    },
                    {
                        value: signDoc.inProgress,
                        colorClass: 'colorGrey',
                        label: i18n.translate('ReportsPage.courseLegend.inProgress')
                    },
                    {
                        value: signDoc.notStarted,
                        colorClass: 'colorLightGrey',
                        label: i18n.translate('ReportsPage.courseLegend.notStarted')
                    }
                ]
            }
        })
        setSignDocCompleteStatusBySignDocId(newStatisticsData)
    }

    const SignDocCompleteStatusByOrganization = () => {
        let tmpSignDocStatistics = map(keys(signDocStatisticsByOrganizationId), (organizationId, index) => {
            const organization = signDocStatisticsByOrganizationId[organizationId]
            organization.data = [organization.completed, organization.inProgress, organization.notStarted]
            organization.label = organization.organizationName
            organization.id = organizationId
            return organization
        })

        const newStatisticsData = map(keys(tmpSignDocStatistics), (organizationId, index) => {
            const organization = tmpSignDocStatistics[organizationId]
            return {
                barId: organizationId,
                barTitle: organization.organizationName,
                values: [
                    {
                        value: organization.completed,
                        colorClass: 'colorYellow',
                        label: i18n.translate('ReportsPage.courseLegend.completed')
                    },
                    {
                        value: organization.inProgress,
                        colorClass: 'colorGrey',
                        label: i18n.translate('ReportsPage.courseLegend.inProgress')
                    },
                    {
                        value: organization.notStarted,
                        colorClass: 'colorLightGrey',
                        label: i18n.translate('ReportsPage.courseLegend.notStarted')
                    }
                ]

            }
        })
        setSignDocCompleteStatusByOrganization(newStatisticsData)
    }
    const init = async ({orgsFilterModel, signDocsFilterModel}) => {
        setIsLoading(true)
        let {
            signDocsByOrganization,
            signDocsBySignDoc,
            signDocCount,
            userCount,
            usersWithActiveTask,
            allSignDocsOfACompany
        } = await getSignDocsStatistics({
            // types,
            organizationId: get(orgsFilterModel, 'filterValues.organizationId'),
            signDocId: get(signDocsFilterModel, 'filterValues.signDocId')
        })
        setSignDocStatisticsBySignDocId(signDocsBySignDoc)
        setSignDocStatisticsByOrganizationId(signDocsByOrganization)
        setSignDocCount(signDocCount)
        setUsersCount(userCount)
        setUsersWithActiveTask(usersWithActiveTask)
        setAllSignDocsOfACompanyCount(get(allSignDocsOfACompany, 'length'))
        setIsLoading(false)
    }
    const debouncedInitRef = useRef(debounce(init, 500));

    useEffect(() => {
        if (adminOrganizationOptions) {
            setOrgsFilterModel({
                ...orgsFilterModel,
                filterData: {
                    ...orgsFilterModel.filterData,
                    organizations: adminOrganizationOptions
                },
                filterValues: {
                    ...orgsFilterModel.filterValues,
                    organizationId: get(adminOrganizationOptions, '[0].value')
                }
            })
        }

    }, [adminOrganizationOptions])

    useEffect(() => {
        const {signDocOptionsTmp} = getSignDocOptions({
            selectedOrgOption: get(orgsFilterModel, 'filterValues.organizationId'),
            adminOrganizations,
            doNotForceCompletion: false,
            i18n
        })
        const tmpFilterModel = {
            ...signDocsFilterModel,
            filterData: {
                ...signDocsFilterModel.filterData,
                signDocs: signDocOptionsTmp
            },
            filterValues: {
                ...signDocsFilterModel.filterValues,
                signDocId: get(signDocOptionsTmp, '[0].value')
            },
            signDocFilterKey: Math.floor(Math.random() * 10000) + 1
        }
        setSignDocsFilterModel(tmpFilterModel)
    }, [adminOrganizations, get(orgsFilterModel, 'filterValues.organizationId')])

    useEffect(() => {
        debouncedInitRef.current({signDocsFilterModel, orgsFilterModel})
    }, [signDocsFilterModel]);

    useEffect(() => {
        SignDocCompleteStatusBySignDocId()
    }, [signDocStatisticsBySignDocId]);

    useEffect(() => {
        SignDocCompleteStatusByOrganization()
    }, [signDocStatisticsByOrganizationId]);


    return <div className="SignDocStats">
        <div className="filtersRow">
            {orgsFilterModel && orgsFilterModel.filterValues ?
                <OrganizationFilter filterModel={orgsFilterModel} setFilterModel={setOrgsFilterModel}/> : ''}
            {signDocsFilterModel && signDocsFilterModel.filterValues ?
                <SignDocFilter key={signDocsFilterModel.signDocFilterKey} filterModel={signDocsFilterModel}
                               setFilterModel={setSignDocsFilterModel}/> : ''}
            <div className="legend">
                <DoughnutChartLegend
                    items={DashboardChartLabels({i18n})}
                />
            </div>
        </div>
        {isLoading ? <LoadingSpinner/> : ''}
        <div className="row numberWidgetsRow">
            <div className="col-50 margin-left row">
                <div className="col-50 margin-left">
                    {usersCount ? <StatsWidgetWrapper
                        info={i18n.translate(`ReportsPage.signDocStats.graphs.info.usersCount`)}
                        bottomTitle={i18n.translate(`ReportsPage.signDocStats.graphs.usersCount`)}>
                        <div className="counter">
                            {reduce(usersCount, (sum, item) => 1 + sum, 0)}
                        </div>
                    </StatsWidgetWrapper> : ''}
                </div>
                <div className="col-50">
                    {allSignDocsOfACompanyCount ? <StatsWidgetWrapper
                        info={i18n.translate(`ReportsPage.signDocStats.graphs.info.allSignDocsOfACompanyCount`)}
                        bottomTitle={i18n.translate(`ReportsPage.signDocStats.graphs.allSignDocsOfACompanyCount`)}>
                        <div className="counter">
                            {allSignDocsOfACompanyCount}
                        </div>
                    </StatsWidgetWrapper> : ''}
                </div>
            </div>
            <div className="col-50 margin-right row">
                <div className="col-50 margin-left">
                    {usersWithActiveTask ? <StatsWidgetWrapper
                        info={i18n.translate(`ReportsPage.signDocStats.graphs.info.usersWithActiveTasks`)}
                        bottomTitle={i18n.translate(`ReportsPage.signDocStats.graphs.usersWithActiveTasks`)}>
                        <div className="counter">
                            {usersWithActiveTask}
                        </div>
                    </StatsWidgetWrapper> : ''}
                </div>
                <div className="col-50">
                    {signDocCount ? <StatsWidgetWrapper
                        info={i18n.translate(`ReportsPage.signDocStats.graphs.info.activeCourseCount`)}
                        bottomTitle={i18n.translate(`ReportsPage.signDocStats.graphs.activeCourseCount`)}>
                        <div className="counter">
                            {get(signDocCount, `['COURSE'].length`, 0) + get(signDocCount, `['SIGN_DOC'].length`, 0)}
                        </div>
                    </StatsWidgetWrapper> : ''}
                </div>
            </div>

        </div>
        <div className="statsWidgetsContainers">
            {signDocCompleteStatusBySignDocId && signDocCompleteStatusBySignDocId.length ?
                <div className="row widgetRow">

                    <div className="col-50 margin-left">
                        <StatsWidgetWrapper
                            title={i18n.translate(`ReportsPage.signDocStats.graphs.usersPerformancePercent`, {context: {}})}>
                            <MultipleHorizontalValueBars barsData={signDocCompleteStatusBySignDocId}
                                                         showPercent={true}/>
                        </StatsWidgetWrapper>
                    </div>
                    <div className="col-50 margin-right">
                        <StatsWidgetWrapper
                            title={i18n.translate(`ReportsPage.signDocStats.graphs.usersPerformanceNumbers`, {context: {}})}>
                            <MultipleHorizontalValueBars barsData={signDocCompleteStatusBySignDocId}
                                                         showPercent={false}/>
                        </StatsWidgetWrapper>
                    </div>
                </div> : ''}
            {signDocCompleteStatusByOrganization && signDocCompleteStatusByOrganization.length ?
                <div className="row widgetRow">

                    <div className="col-50 margin-left">
                        <StatsWidgetWrapper
                            info={i18n.translate(`ReportsPage.signDocStats.graphs.info.performancePercentByOrg`)}
                            title={i18n.translate(`ReportsPage.signDocStats.graphs.performancePercentByOrg`, {context: {orgsCount: get(signDocCompleteStatusByOrganization, 'length')}})}>
                            <MultipleHorizontalValueBars barsData={signDocCompleteStatusByOrganization}
                                                         showPercent={true}/>
                        </StatsWidgetWrapper>
                    </div>
                    <div className="col-50 margin-right">
                        <StatsWidgetWrapper
                            info={i18n.translate(`ReportsPage.signDocStats.graphs.info.performanceNumberByOrg`)}
                            title={i18n.translate(`ReportsPage.signDocStats.graphs.performanceNumberByOrg`, {context: {orgsCount: get(signDocCompleteStatusByOrganization, 'length')}})}>
                            <MultipleHorizontalValueBars barsData={signDocCompleteStatusByOrganization}
                                                         showPercent={false}/>
                        </StatsWidgetWrapper>
                    </div>
                </div> : ''}
        </div>
    </div>
}