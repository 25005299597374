import {map} from 'lodash';

export const enUS = {
    confirm: 'Confirm',
    login: {
        identifier: 'Identifier',
        password: 'Password',
        login: 'Login',
        verificationCodeFromSMS: 'Verification code you received by SMS',
        messageWasSentTo: (key, {last4digits}) => `An SMS message was sent to the phone number ending in ${last4digits}`,
        validate: 'Validate',
        resendCode: 'Send again',
        sendCode: 'Send verification code',
        helloX: (key, {name}) => `Hello ${name}`,
    },
    changePassword: {
        currentPassword: 'Current Password',
        password: 'New Password',
        repeatPassword: 'New Password',
        changePassword: 'Change Password'
    },
    logout: 'Logout',
    menuItems: {
        logout: 'Logout',
        changePassword: 'Change Password',
        adminPanel: 'Admin Panel',
        home: 'Home Page',
        myOrgs: 'My Organizational Units',
        learningCenter: 'Learning Center',
        users: 'Users',
        reports: 'Reports',
        settings: 'Settings',
        learningManagement: 'Learning Management',
        filesManagement: 'Files Management',
        knowledgeCenter: 'Knowledge Center',
        superAdmin: {
            courses: 'Courses Management',
            customers: 'Customers Management',
            organizations: 'Organizational Units Management',
            users: 'Users Management',
        }
    },
    languages: {
        label: 'Choose Language',
        languageKeys: {
            heIL: 'עברית',
            enUS: 'English',
            arIL: 'العربية',
            ruRU: 'Русский'
        }
    },
    learningCenter: {
        title: 'My Learning Center',
        subTitle: 'Tasks and their completion status',
        coursesToDo: 'Courses not yet completed',
        coursesCompleted: 'Courses I completed',
        noCoursesToDo: 'No courses not yet completed',
        noCoursesCompleted: 'No courses completed',
        signDocsToDo: 'Documents to sign',
        noSignDocsToDo: 'No documents to sign',
        noSignDocsCompleted: 'No documents I have signed',
        signDocsCompleted: 'Documents I signed',
        noOpenTasks: 'No open tasks',
        tabs: {
            myCourses: 'My Courses',
            mySignDocs: 'Documents to Sign',
            myTasks: 'Open Tasks',
        }
    },
    settingsPage: {
        title: 'Settings',
        subTitle: 'Adjust the system',
        subTitleBold: 'to your company’s needs',
        buttons: {
            updateLogo: "Update Logo",
            updateOrgName: "Update Organizational Units Names",
            updateNotes: "Update Notes for Employees",
        },
        editNotesModal: {
            title: 'Edit Notes for Employees',
        },
        editOrganizationsNames: 'Edit Organizational Units Names',
        createNote: 'Create New Note',
        newNoteTitle: 'New Note',
        SMSQuota: {
            title: 'Messages Credit',
            quota: 'Purchased Messages',
            sent: (key, {
                sent,
                quota
            }) => `For your information, up to now ${sent} messages were sent and you have ${quota} messages left for future use`,
            lastSMSWasSentOn: (key, {date}) => `The last messages were sent from the system on the date ${date}`,
        },
        generalSettings: {
            title: 'General'
        },
        reminders: {
            title: 'Reminders',
            course: 'Course',
            signDoc: 'Document to Sign',
            watchVideo: 'Watch a Video',
            canvaPresentation: 'View a Canva',
        },
        admins: {
            title: 'Admins',
            subTitle: 'Management and Settings'
        }
    },
    HomePage: {
        courseLegend: {
            completed: 'Completed',
            inProgress: 'In Progress',
            notStarted: 'Not Started'
        },
    },
    home: {
        title: 'Available Courses for You',
        faq: 'Frequently Asked Questions',
        welcome: 'Welcome',
        // learningSubtitle:(key,{companyName}) => `לסביבת הלמידה האישית שלכם, עבור חברת ${companyName}`,
        learningSubtitle: "To your personal learning environment, for",
        userChartLabels: {
            finishedCourses: 'Courses I completed',
            inProgressCourses: 'Courses in progress',
            notStartedCourses: 'Courses not started',
            label: 'Courses Progress'
        },
        superAdmin: 'System Admin Interface',
    },
    faq: {
        question1: 'Can I return to the courses at any time?',
        answer1: 'Yes. You can learn at your own pace from anywhere, at any time, from your mobile phone or computer (we recommend opening in Google Chrome). Note that once you start a course, you must complete it on the same device.',
        question2: 'What do I do when I finish the courses?',
        answer2: 'When you complete a course, click the Finish button and confirm that you have completed the course. Make sure there are no open tasks left.',
        question3: 'Need help?',
        answer3: 'Feel free to contact us by phone at 054-3493062, or via email at office@nakarmedic.co.il',
    },
    gender: {
        male: 'Male',
        female: 'Female'
    },

    OrganizationsPage: {
        title: 'My Organizational Units',
        subTitle: 'Management and Settings',
        organizations: 'Organizational Units I Manage',
        importUsersFile: "Import Users File",
        editLogo: 'Edit Logo',
        dropLogoHere: 'Drag the logo here',
        currentLogo: 'Current Logo',
        changeForAllMyOrganizations: 'Change for all Organizational Units I manage',
        logoWillBeChangedForAllOrgs: '**The logo will be changed for all organizational units under your management',
    },
    UsersPage: {
        title: 'Users',
        subTitle: 'Management and Settings',
        userRowCourseMenu: {
            buttons: {
                addToCourse: 'Register User',
                removeFromCourse: 'Remove User Registration',
            }
        },
        userRowSignDocMenu: {
            buttons: {
                addToSignDoc: 'Register User',
                removeFromSignDoc: 'Remove Registration',
                markCompleted: 'Mark as Completed',
            }
        }
    },
    organizationUserTable: {
        headers: {
            name: "Name",
            permissions: "Permission",
            identifier: "Identifier"
        }
    },
    userType: {
        ADMIN: 'Admin',
        USER: "User"
    },
    note: {
        title: 'Title',
        content: 'Content',
        delete: 'Delete Note',
        deleteNoteConfirmation: {
            title: 'Delete Note',
            message: 'Are you sure you want to delete the note?',
            noteName: 'Note Name',
            deleteNote: 'Delete Note'
        }
    },
    organization: {
        organizationName: 'Organizational Unit Name',
    },
    courseCard: {
        youAreDone: `You have completed the course`,
        finishBy: (key, {finishByDate}) => `Must complete by ${finishByDate}`,
        youAreDoneWatchAgain: 'Watch Again',
        goToCourse: 'Go to Course'
    },
    courseProgress: {
        notStarted: 'Not Started',
        inProgress: 'In Progress',
        finished: 'Finished!',
    },
    ReportsPage: {
        title: 'Reports',
        subTitle: 'View employees’ completion status',
        // subTitleBold: 'in a graphical display.',
        courseLegend: {
            completed: 'Completed',
            inProgress: 'In Progress',
            notStarted: 'Not Started'
        },
        coursesStats: {
            title: 'Completion status for each course',
        },
        signDocStats: {
            title: {
                course: 'Completion status for each course',
                signDocs: 'Signature status for each document'
            },
            graphs: {
                usersPerformanceNumbers: (key, {taskCount = 0}) => `Number of users by completion status ${taskCount ? `(${taskCount} tasks)` : ''}`,
                usersPerformancePercent: (key, {taskCount = 0}) => `Percentage of users by completion status ${taskCount ? `(${taskCount} tasks)` : ''}`,
                performanceNumberByOrg: (key, {orgsCount = 0}) => `Number of completions in the organizational unit ${orgsCount ? `(${orgsCount} organizational units)` : ''}`,
                performancePercentByOrg: (key, {orgsCount = 0}) => `Completion percentage in the organizational unit ${orgsCount ? `(${orgsCount} organizational units)` : ''}`,
                signDocsCount: ' Documents to sign',
                activeCourseCount: 'Active Learning Items',
                usersCount: 'Users',
                usersWithActiveTasks: 'Users with an active task',
                allSignDocsOfACompanyCount: 'Learning and Training Items',
                info: {
                    activeCourseCount: 'Total learning items with a future end date (courses, documents to sign, and viewing tasks)',
                    usersCount: 'Total users',
                    usersWithActiveTasks: 'Total users with at least one active task',
                    allSignDocsOfACompanyCount: 'Total learning items (courses, documents to sign, and viewing tasks)',
                    performanceNumberByOrg: 'Total completed tasks out of all accumulated tasks\n (Accumulated tasks = number of learning and training items multiplied by the number of users assigned to each item)',
                    performancePercentByOrg: 'Total tasks completed in each organizational unit'
                }
            }
        },
        tabs: {
            dashboard: 'Dashboard',
            courses: 'Courses',
            signDocs: 'Documents to Sign',
            generatedReports: 'Generated Reports'
        }
    },
    courseProgressMgmnt: {
        notStarted: 'Not Started',
        inProgress: 'In Progress',
        finished: 'Completed',
        completedOn: 'Signature Date: ',
        completedOnCourse: 'Completion Date: ',
        by: 'by ',
        manual: 'Manual',
        finishBy: 'To be completed by: ',
    },
    LearningManagementPage: {
        title: 'Learning Management',
        subTitle: 'Add, edit, and delete',
        addCourse: 'Add Course',
        editCourse: 'Edit Course',
        deleteCourse: 'Delete Course',
        courseName: 'Course Name',
        courseDescription: 'Course Description',
        courseImage: 'Course Image',
        createKnowledgeSource: 'Create Knowledge Source',
        courseCard: {
            editCourse: 'Edit Course',
        },
        signDocCard: {
            editSignDoc: {
                READ_AND_SIGN_DOC_PDF: 'Edit Document',
                WATCH_AND_SIGN_YOUTUBE_LINK: 'Edit Viewing Task',
                WATCH_AND_SIGN_VIMEO_LINK: 'Edit Viewing Task',
                WATCH_AND_SIGN_VIDEO_FILE: 'Edit Viewing Task',
                REGULAR_COURSE: 'Edit Course',
                CANVA_PRESENTATION: 'Edit Presentation'
            },
            deleteModal: {
                title: 'Delete Document',
                text: 'Are you sure you want to delete this document? This action cannot be undone',
                confirm: 'Delete Document',
                signDocName: 'Document Name'
            }
        },
        users: {
            buttons: {
                downloadCsv: 'Export to CSV',
                downloadCsvForOrganization: 'Export users for the selected organizational unit',
                downloadCsvForAllOrganizations: 'Export users for all organizational units I manage',
            }
        },
        courseManagement: 'Courses Management',
        signDocManagement: 'Documents Management',
        noSignDocs: 'No documents to display',
        tabs: {
            signDocs: 'Documents to Sign',
            courses: 'Courses',
            users: 'Users',
            knowledgeCenter: 'Knowledge Center',
        }
    },
    editCourseModal: {
        title: 'Edit Course',
        courseName: 'Course Name',
        courseDescription: 'Course Description',
        courseImage: 'Course Image',
        save: 'Save Changes',
        edit: {
            courseName: 'Course Name',
            courseDescription: 'Course Description',
            dateEditNotification: 'You can set a unified end date for all users registered for the course in all the organizational units you manage. Any changes you make here will override individual changes made for users.'
        },
        dateModal: {
            title: 'Set End Date',
            timeAndTimezone: 'Until 00:00 in time zone: '
        }
    },
    editUserCourseModal: {
        title: (key, {userName, courseName}) => `Set an end date for course "${courseName}" for user "${userName}"`,
        dateEditNotification: 'You can set an end date for the user. This change will remain in effect unless you change the end date for the entire organizational unit.'
    },
    AppSideMenu: {
        welcome: `Welcome`,
    },
    superAdmin: {
        editFFModal: {
            modalTitle: "Edit Company Features",
            editFeatures: "Edit Features",
            featureFlags: {
                automationFF: 'Automation',
                smsFF: 'SMS',
                whatsappFF: 'Whatsapp',
                emailFF: 'Email',
                signDocsFF: 'Tasks',
                sendWelcomeMessageFF: 'Send New User Messages',
                signDocsReadAndSignDocPdfFF: 'Sign PDF',
                signDocsWatchAndSignYoutubeLinkFF: 'Confirm Youtube Viewing',
                signDocsWatchAndSignVimeoLinkFF: 'Confirm Vimeo Viewing',
                signDocsWatchAndSignVideoFileFF: 'Confirm Video File Viewing',
                signDocCanvaPresentationFF: 'Canva Presentation',
                regularCourseFF: 'Courses',
                notifyNewTaskToUserFF: 'Notify New Task to User',
                knowledgeCenterFF: 'Knowledge Center',
                orgAdminMgmtFF: 'Admin Permissions Management',
            }
        },
        allUsersTable: {
            columns: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                roles: 'Roles',
                identifier: 'Identifier',
                organization: 'Organizational Unit',
                id: 'ID',
                companyName: 'Company',
                yes: 'Yes',
                no: 'No',
                isSuperAdmin: 'System Admin',
                isCompanyAdmin: 'Company Admin',
                isDeleted: 'Deleted',
                edit: 'Edit',
                permanentlyDelete: 'Permanent Deletion',
                loginUrl: 'Login Link',
            },
            buttons: {
                downloadCsvForOrganization: 'Download Excel file for organizational unit',
                downloadCsvForAllOrganizations: 'Download Excel files for all organizational units I manage',
                downloadCsvForOrganizationSuccess: 'Your files will be ready for download soon under "Menu -> Files Management"',
                deleteUsers: 'Delete Users',
                edit: 'Edit',
            }
        },
        allOrgsTable: {
            columns: {
                orgName: 'Organizational Unit Name',
                taxIdentifier: 'Identifier',
                companyId: 'Company ID',
                orgAddress: 'Address',
                orgContactInfo: 'Contact Info',
                id: 'ID',
                companyName: 'Company Name',
                delete: 'Delete',
            },
            deleteOrg: 'Delete Organizational Unit',
            deleteOrgTitle: 'Delete Organizational Unit: ',
            deleteOrgText: 'Are you sure you want to delete the organizational unit',
            deleteOrgConfirm: 'Delete Organizational Unit',
        },
        createNewCourse: {
            title: 'Create a New Course',
            docName: 'Course Name',
            courseDescription: 'Course Description',
            create: 'Create Course',
            dropZipHere: 'Drag the course zip file here or click to select a file',
            modalTitle: 'Create a New Course',
            organizationsTable: {
                orgName: 'Organizational Unit Name',
                addOrganization: 'Add Course to the Organizational Unit',
                removeOrganization: 'Remove Course from the Organizational Unit',
                onlyOneCompanyPerCourse: 'A course can only be added to one company, so you cannot change the company selection now',
            }
        },

        createNewCustomer: {
            modalTitle: 'Create a New Customer',
            companyName: 'Company Name',
            userPrefix: 'User Prefix (unique for each company - English only)',
            identifier: 'Identifier',
            contactEmail: 'Contact Email',
            contactName: 'Contact Name',
            contactPhone: 'Contact Phone',
            userCreationTitle: 'Create an initial company user',
            detailsTitle: 'Fill in the company details',
            userPassword: 'Password for the user (English only)',
            userIdentifier: 'Identifier for the user (English only)',
            createCompany: 'Create Company',
            userNameNoteNoPrefix: '*You must enter a user prefix before you can enter a username',
            userNameNote: (key, {companyUsersPrefix, userIdentifier}) => {
                return '*The actual username that will be created is: ' + companyUsersPrefix + "-" + (userIdentifier || '"User Identifier"')
            },
            userPrefixError: 'The user prefix must be in English only',
            userFirstName: 'First Name',
            userLastName: 'Last Name',
            contactFirstName: 'Contact First Name',
            contactLastName: 'Contact Last Name',
        },
        editCourse: {
            courseName: 'Course Name',
            docName: 'Course Name',
            courseDescription: 'Course Description',
            description: 'Course Description',
            dropZipHere: 'Drag the course zip file here or click to select a file',
            dropZipNote: '**Note that you are in edit mode, so if a zip file already exists for this course, uploading another file will overwrite the existing one**',
            saveChanges: 'Save Changes',
            modalTitle: 'Edit Course',
        },
        editCustomer: {
            modalTitle: 'Edit Customer',
            adminUserDetails: 'Primary User Details',
            detailsTitle: 'Edit Company Details',
            organizationsTitle: 'Customer’s Organizational Units',
            addOrganizations: 'Add Organizational Units to Customer',
            addOrganizationModal: {
                organizationsList: 'List of Organizational Units',
                useCommaSeparatedValues: 'Enter comma-separated values, for example: "Finance, Accounting, Software Development"',
                title: 'Add Organizational Units to Customer',
            }
        },
        coursesTable: {
            columns: {
                courseName: 'Task Name',
                description: 'Task Description',
                edit: 'Edit',
                type: 'Task Type',
                editCourse: 'Edit Course',
                companyName: 'Company Name',
                remove: 'Delete',
            }
        },
        customersTable: {
            columns: {
                companyName: 'Company Name',
                taxIdentifier: 'Identifier',
                edit: 'Edit',
                editCompany: 'Edit Company',
                smsQuota: 'Messages Credit',
                featureFlags: 'Features',
                storageQuotaMB: 'Storage (MB)',
                editQuota: 'Edit Quotas'
            },
            quota: {
                editQuota: 'Edit Quotas',
                editQuotaTitle: 'Edit Quotas for Company',
                editCredits: 'Edit Credits',
                MessagesCreditsToEdit: 'Messages Credits',
                storageQuotaToEdit: 'Storage in MB',
            }
        }
    },
    dataGrid: {
        filters: {
            search: {
                input: 'Search',
            },
        },
        filterLabels: {
            course: 'Course',
            organization: 'Organizational Unit',
            company: 'Company',
            signDoc: 'Tasks'
        }
    },
    importUsersPage: {
        downloadExample: 'Download a Template File to Create Users',
        downloadUsersAsCSV: 'Download Users as CSV File',
    },
    importUsersModal: {
        title: 'Import / Edit Users & Units',
        tabs: {
            importUsers: {
                title: 'Import Users and Add Units',
            },
            reorganizeUsers: {
                title: 'Import Users and Change Units',
            }
        },
        manyUsersMessage: 'It seems there are many users in the file, importing may take several minutes. After clicking Create, please keep the window open until the upload is complete, and then wait a few more minutes for all changes to be reflected in the system.',
        users: {
            firstName: 'First Name',
            lastName: 'Last Name',
            identifier: 'Identifier',
            phone: 'Phone',
            email: 'Email',
            organizations: 'Organizational Units',
        },
        createUsers: 'Create/Edit Users',
        selectOrganization: 'Select organizational units for which the users will be created',
        selectOrganizationsToAdd: 'Select organizational units to add',
        organization: 'Organizational Unit',
        newUsersWillBeCreatedAndOrganizationsWillBeAddedToAllUsers: 'The organizational units will be added to all users found in the attached file. Users who do not exist will be created, existing users will be updated.',
        newUsersWillBeCreatedAndOrganizationsWillBeOverride: 'The organizational units will be changed for all users in the attached file (users will be removed from units that are not selected). Users who do not exist will be created, existing users will be updated.',
        errors: {
            missingMandatoryField: (key, {row}) => `*Missing mandatory fields in row ${row}:`,
            twoSourcesForOrgs: 'You cannot select organizational units from both sources. Please remove the organizational units from the imported file or from the selection at the top of the page',
            removeOrgsFromFile: 'Please remove the organizational units from the imported file',
            missingSourceForOrgs: 'No organizational units selected for users. Please choose units from the top of the page or add them in the import template file.',
            missingSourceForOrgsSelectFromMultiSelect: 'No organizational units selected for users. Please choose units from the selection at the top of the page.',
        }
    },
    uploadBox: {
        uploading: 'Uploading file...',
        uploaded: 'File uploaded successfully',
        uploadFailed: 'The file is invalid. Please check that the file size and file type do not exceed the allowed limit.',
        maxFileSize: (key, {maxFileSize}) => `The maximum allowed file size is ${maxFileSize}`,
        onlyFileTypes: (key, {fileTypes}) => `Allowed file types for upload are: ${map(fileTypes, fileType => `${fileType}`).join(', ')}`,
    },
    completedCoursePage: {
        title: 'Course Completion',
        subTitle: 'Please confirm that you have finished the course',
        buttons: {
            confirmCourseCompletion: 'I confirm I have finished the course'
        },
        finishedSuccessfully: 'You have successfully completed the course',
        oops: 'Oops...',
        youGotLost: 'It seems you got lost, navigate using the menu',
        courseCompletedSuccessfullyPopup: 'You have successfully completed the course',
    },
    errors: {
        clientError: 'Error in the details provided',
        serverError: 'Server request error',
        generalServerError: 'General server request error, try reloading the page. If the problem persists, contact technical support.',
        notFound: 'Resource not found',
        forbidden: 'You do not have permission to access this resource',
        privateRouteUserNotLoggedIn: 'The user is not logged in, so the content cannot be viewed. Please log in and then try again.'
    },
    alertDialog: {
        ok: 'OK',
        cancel: 'Cancel',
    },
    createEditUserModal: {
        createUser: 'Create User',
        titleCreate: 'Create/Update User',
        titleEdit: 'Edit User',
        selectOrganization: 'Select Organizational Unit',
        firstName: 'First Name',
        lastName: 'Last Name',
        identifier: 'Identifier',
        phone: 'Phone',
        email: 'Email',
        editUser: 'Edit User',
        deleteUserTitle: 'Delete User',
        deleteUserText: 'Are you sure you want to delete the user',
        deleteOrgConfirm: 'Delete User',
        deleteUserButton: 'Delete User',
        userIsCompanyAdmin: 'Company Admin',
        mfaRequired: 'Two-Factor Authentication Required',
        sendWelcomeMessage: 'Send initial login message to users',
        save: 'Save/Update User',
        userWillBeEditedIfPhoneExists: 'If the phone number is already linked to a user in the company, that user and the organizational units they belong to will be updated',
    },
    allUsersTable: {
        columns: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            roles: 'Roles',
            identifier: 'Identifier',
            organization: 'Organizational Unit',
            id: 'ID',
            edit: 'Edit',
            delete: 'Delete',
            progress: 'Progress',
            notRegistered: 'Not Registered to Course'
        }
    },
    removeCourseFromCompanyCol: {
        deleteCourseButton: 'Delete Course',
        removeCourseTitle: 'Delete Course',
        removeCourseText: 'Are you sure you want to delete the course',
        removeCourseConfirm: 'Delete Course',
    },
    CourseReminderNotificationButton: {
        alerts: {
            reminderSent: 'Reminders are on their way!',
            reminderNotSent: 'Error sending reminder',
            sentMessages: 'Messages sent',
            notSentMessages: 'Messages not sent',
            notSentMessagesInfo: '* There can be two reasons why not all messages were sent: lack of contact information or an incorrect phone number',
            sendToUsersWhoDidNotReceiveNotification: 'Send a reminder to users who have not yet received one',
            sendToAllUsers: 'Send a reminder to all course users who have not yet completed',
            error: 'Error',
            company_has_no_sms_quota: 'The company does not have enough message credits available, please contact customer service to purchase a messages package',
        }
    },
    FilesManagementPage: {
        title: 'Files Management',
        subTitle: 'View, add, and edit',
        fileManagementTable: {
            columns: {
                fileName: 'Download Link',
                presentationName: 'Display Name',
                isReady: 'Ready for Download',
                companyName: 'Company',
                organizationNames: 'Organizational Units',
                date: 'Creation Date',
                createdByUser: 'Created By',
            },
            download: 'Download',
            inProgress: 'Creation in progress',
            ready: 'Ready for download',
            allOrganizations: 'All organizational units',
        }
    },
    SMSNotificationButton: {
        title: 'Send Reminder',
        subTitle: 'Immediately'
    },
    sendSMSModal: {
        title: 'Who are we reminding?',
        sms: 'SMS',
        whatsapp: 'WhatsApp',
        email: 'Email',
        selectMessageDeliveryWay: 'Messages will be sent via:',
        sendToOptions: {
            all: 'Users who have not completed a specific task',
            onlyNotNotified: 'All who have not completed the course and have not received a reminder',
            allWithAnyOpenTasks: 'Users with open tasks',
            pleaseSelect: 'Please select the group you want to send to',
            label: 'Who?'
        },
        taskOptions: {
            label: 'Which task?',
            pleaseSelect: 'Please select the course for which you want to send a reminder',
        },
        organizationOptions: {
            label: 'Which organizational unit?',
            pleaseSelect: 'Please select the organizational unit for which you want to send a reminder',
        },
        smsStatusInformation: {
            statusTitle: 'Great!',
            statusUserToNotify: 'So in total, we’re reminding ',
            bySMS: 'via SMS',
            byWhatsApp: 'via WhatsApp',
            byEmail: 'via Email',
            statusCompanyQuota: 'For your information, after sending, you will have ',
            SMSForUse: 'messages left to use',
            employees: 'employees',
            theyWillReceiveItAsFollowing: 'who will receive the messages through the following methods'
        },
        error: {
            noUsersToSendOnlyNotNotified: 'There are no users to send to; all users who have not completed the course have already received a reminder',
            notEnoughSMS: 'You do not have enough message credits available, please contact customer service to purchase a messages package',
            noUsersInCourse: 'No users to send to; all users in this course have completed it',
            youAreLeftWithQuota: 'You still have',
            youNeed: 'and you need a total of',
            toCompleteTheProcess: 'to complete the process',
        },
        send: 'Send'
    },
    SADeleteUsersModal: {
        title: 'Delete Users',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        identifier: 'Identifier',
        message: (key, {userCount}) => `Are you sure you want to delete the following ${userCount} users?`,
        deleteUsers: 'Delete Users',
    },
    SARemoveUser: {
        permanentlyDeleteUser: 'Permanent Deletion',
        deleteUserTitle: 'Permanently Delete User',
        deleteUserText: 'Are you sure you want to permanently delete the user',
        deleteOrgConfirm: 'Permanently Delete User',
    },
    automations: {
        title: 'Automations',
        subTitle: 'Management and Settings',
        addAutomation: 'Add Automation',
        editAutomation: 'Edit Automation',
        deleteAutomation: 'Delete Automation',
        automationName: 'Automation Name',
        automationDescription: 'Automation Description',
        automationImage: 'Automation Image',
        automationCard: {
            editAutomation: 'Edit Automation',
        },
        notifications: {
            title: 'Create Automation',
            subTitle: 'To schedule reminders',
        },
        createAutomationModal: {
            weWillSendNotificationTo: 'We will remind everyone who has not completed the course ',
            allOrganizations: 'All organizational units',
            organizationOptions: {
                label: 'Which organizational unit?',
                pleaseSelect: 'Please select the organizational unit you want to send a reminder for',
                error: 'You must select an organizational unit for sending the reminder'
            },
            taskOptions: {
                label: 'Which task?',
                pleaseSelect: 'Please select the task for which you want to send a reminder',
                error: 'You must select a course for sending the reminder'
            },
            selectHourLabel: 'At what time?',
            selectHourError: 'You must select a time to send the reminder',
            howManyDaysBefore: 'How many days before?',
            howManyDaysBeforeError: 'You must select how many days before you want to send the reminder',
            nameLabel: 'Automation Name',
            descriptionLabel: 'Automation Description',
            create: 'Create Automation',
        }
    },
    automationsTable: {
        columns: {
            automationName: 'Automation Name',
            description: 'Automation Description',
            organization: 'Organizational Unit',
            task: 'Task',
            when: 'When?',
            status: 'Status',
            how: 'How is it sent?',
            delete: 'Delete',
            edit: 'Edit',
        },

        status: {
            SCHEDULED: 'Scheduled',
            COMPLETED: 'Sent',
            sentAndNotSent: (key, {sent, notSent}) => `Sent ${sent} and not sent ${notSent}`,
        },
        sendType: {
            SMS: 'Text Message',
            EMAIL: 'Email',
            WHATSAPP: 'WhatsApp',
        },
        atHour: 'at',
        daysBefore: 'days before the course end date',
    },
    allOrganizations: 'All organizational units',
    allSignDocs: 'All tasks',
    confirmationModal: {
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    deleteAutomationModal: {
        title: 'Delete Automation',
        message: 'Are you sure you want to delete the automation?',
        deleteAutomation: 'Delete Automation',
        automationName: 'Automation Name',
    },
    newDocSign: {
        buttonTitle: 'Create a New Task',
        modal: {
            title: 'Create a New Document',
            docNameLabel: 'Document Name',
            docDescriptionLabel: 'Document Description',
            createDocument: 'Create Document',
            dropSignDocHere: 'Drag the document file here or click to select a file',
            selectSignDocType: 'Type',
            linkUrl: 'Link',
            youtubeLinkNotAllowed: 'You cannot enter a Youtube link',
            vimeoLinkNotAllowed: 'You cannot enter a Vimeo link',
            invalidLink: "Invalid link",
            notifyUsersOfNewTask: 'Send a message to users about a new task',
            forceCompletion: 'Required to complete',
            nonForceCompletion: 'Not required to complete',
            noDate: 'No end date'
        }
    },
    signDocProgress: {
        notStarted: {
            READ_AND_SIGN_DOC_PDF: 'Not read',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Not started',
            WATCH_AND_SIGN_VIMEO_LINK: 'Not started',
            WATCH_AND_SIGN_VIDEO_FILE: 'Not started',
            REGULAR_COURSE: 'Not started',
            CANVA_PRESENTATION: 'Not started',
        },
        inProgress: {
            READ_AND_SIGN_DOC_PDF: 'Read',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Watched',
            WATCH_AND_SIGN_VIMEO_LINK: 'Watched',
            WATCH_AND_SIGN_VIDEO_FILE: 'Watched',
            REGULAR_COURSE: 'Started',
            CANVA_PRESENTATION: 'Watched',
        },
        finished: {
            READ_AND_SIGN_DOC_PDF: 'Signed!',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Signed!',
            WATCH_AND_SIGN_VIMEO_LINK: 'Signed!',
            WATCH_AND_SIGN_VIDEO_FILE: 'Signed!',
            REGULAR_COURSE: 'Finished!',
            CANVA_PRESENTATION: 'Signed!',
        }
    },
    signDocProgressMgmnt: {
        notStarted: 'Not Opened',
        inProgress: 'Opened',
        finished: 'Signed'
    },
    signDocPage: {
        title: {
            read: {
                sign: 'Document Reading Approval',
                noSign: 'Document Reading',
                knowledgeOnly: 'Document Reading'
            },
            watch: {
                sign: 'Video Viewing Approval',
                noSign: 'Video Viewing',
                knowledgeOnly: 'Video Viewing'
            },
        },
        subTitle: {
            read: {
                sign: 'Approve and sign that you have read the document',
                noSign: 'Re-reading after signing',
            },
            watch: {
                sign: 'Approve and sign that you have watched the video',
                noSign: 'Re-watching after signing',
            },
        },
        signatureTitle: {
            read: 'Sign to confirm that you have read the document',
            watch: 'Sign to confirm that you have watched the video',
        },
        signatureSubtitle: {
            read: 'In order to confirm reading the document, sign here',
            watch: 'In order to confirm watching the video, sign here',
        },
        signatureSubtitleNotCompleted: {
            read: 'After reading, a signature pad and confirmation button will appear',
            watch: 'After watching, a signature pad and confirmation button will appear',
        },
        iApproveReadingTheDoc: {
            read: 'I have read the document!',
            watch: 'I have watched the video!',
        },
        clear: 'Clear Signature'
    },
    signDocCard: {
        youAreDone: {
            READ_AND_SIGN_DOC_PDF: 'You signed the document',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'You watched the video',
            WATCH_AND_SIGN_VIMEO_LINK: 'You watched the video',
            WATCH_AND_SIGN_VIDEO_FILE: 'You watched the video',
            REGULAR_COURSE: 'You completed the course',
            CANVA_PRESENTATION: 'You watched the presentation',
        },
        finishBy: {
            READ_AND_SIGN_DOC_PDF: (key, {finishByDate}) => `Sign by ${finishByDate}`,
            WATCH_AND_SIGN_YOUTUBE_LINK: (key, {finishByDate}) => `Watch by ${finishByDate}`,
            WATCH_AND_SIGN_VIMEO_LINK: (key, {finishByDate}) => `Watch by ${finishByDate}`,
            WATCH_AND_SIGN_VIDEO_FILE: (key, {finishByDate}) => `Watch by ${finishByDate}`,
            REGULAR_COURSE: (key, {finishByDate}) => `Complete by ${finishByDate}`,
            CANVA_PRESENTATION: (key, {finishByDate}) => `Watch by ${finishByDate}`,
        },
        youAreDoneWatchAgain: {
            READ_AND_SIGN_DOC_PDF: 'Read again',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Watch again',
            WATCH_AND_SIGN_VIMEO_LINK: 'Watch again',
            WATCH_AND_SIGN_VIDEO_FILE: 'Watch again',
            REGULAR_COURSE: 'Watch again',
            CANVA_PRESENTATION: 'Watch again',
        },
        goToCourse: {
            READ_AND_SIGN_DOC_PDF: 'Go to reading',
            WATCH_AND_SIGN_YOUTUBE_LINK: 'Go to watch',
            WATCH_AND_SIGN_VIMEO_LINK: 'Go to watch',
            WATCH_AND_SIGN_VIDEO_FILE: 'Go to watch',
            REGULAR_COURSE: 'Go to course',
            CANVA_PRESENTATION: 'Go to watch',
        },
    },
    Errors_409: {
        storage_quota_error: (key, {bytesCompanyStorageQuota, bytesCompanyUsedStorage, bytesNewUsedStorage}) => {
            const leftQuotaMB = ((bytesCompanyStorageQuota - bytesCompanyUsedStorage) / (1024 * 1024)).toFixed(2) + ' MB';
            const bytesNewUsedStorageMB = (bytesNewUsedStorage / (1024 * 1024)).toFixed(2) + ' MB';
            const bytesCompanyStorageQuotaMB = (bytesCompanyStorageQuota / (1024 * 1024)).toFixed(2) + ' MB';
            return `Not enough available storage space. Try deleting files or contact the system admin. You have ${leftQuotaMB} left out of ${bytesCompanyStorageQuotaMB} available, and the file you are trying to upload is ${bytesNewUsedStorageMB} in size`;
        }
    },
    signDocTypes: {
        READ_AND_SIGN_DOC_PDF: 'PDF Document to Sign',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'Youtube Link to Watch',
        WATCH_AND_SIGN_VIMEO_LINK: 'VIMEO Link to Watch',
        WATCH_AND_SIGN_VIDEO_FILE: 'Video File to Watch',
        REGULAR_COURSE: 'Course',
        CANVA_PRESENTATION: 'Canva Presentation to Watch',
    },
    signDocTypesNotForCompletion: {
        READ_AND_SIGN_DOC_PDF: 'PDF Document',
        WATCH_AND_SIGN_YOUTUBE_LINK: 'Youtube Link',
        WATCH_AND_SIGN_VIMEO_LINK: 'VIMEO Link',
        WATCH_AND_SIGN_VIDEO_FILE: 'Video File',
        REGULAR_COURSE: 'Course',
        CANVA_PRESENTATION: 'Canva Presentation',
    },
    managementCourseList: {
        signDocButtonTitle: 'Create Viewing Task'
    },
    isCompanyAdmin: {
        makeAdmin: {
            title: 'Set as Company Admin',
            message: 'Are you sure you want to set the user as a company admin?',
            confirm: 'Set as Company Admin',
            button: 'Set as Company Admin',
        },
        removeAdmin: {
            title: 'Remove Company Admin',
            message: 'Are you sure you want to remove the user from the role of company admin?',
            confirm: 'Remove Company Admin',
            button: 'Remove Company Admin',
        },
        userName: 'Username',
    },
    general: {
        saveChanges: 'Save Changes',
        close: 'Close',
        cancel: 'Cancel',
    },
    knowledgeCenter: {
        title: 'Knowledge Center',
        subTitle: 'All information in one place',
    },
    adminUsersTable: {
        columns: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            roles: 'Roles',
            identifier: 'Identifier',
            managerOfOrganizations: 'Manager in Organizational Units',
            id: 'ID',
            edit: 'Edit',
            isCompanyAdmin: 'Company Admin',
            isCompanyAdminYes: 'Yes',
            isCompanyAdminNo: 'No',
        },
        addAdminUser: 'Add Admin',
    },
    editAdminUserModal: {
        title: 'Assign Admin',
        titleEdit: 'Edit Admin',
        sendWelcomeMessage: 'Send initial login message to admin',
        save: 'Save Changes',
        selectOrganizationalUnits: 'Search Organizational Units',
        searchUser: 'Search User',
        isCompanyAdmin: 'Company Admin',
        companyAdminWillBeAdminOfAllOrgs: 'A company admin will be admin of all organizational units',
        selectAllOrgs: 'Select all organizational units',
    },
    autoComplete: {
        search: 'Search',
        selectAll: 'Select All',
    },
    UserIconMenu: {
        settings: 'Settings',
    }
};