import './UserIconMenu.scss'
import React, {useContext, useEffect, useState} from "react";
import {I18nReactContext, languagesMap} from "../../contexts/I18nContext";
import {Language} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import {get, map} from "lodash";
import {Link} from "react-router-dom";
import {Colors} from "../../Utils/styles/Colors";


export const UserIconMenu = () => {
    const {i18n, switchLanguage, language} = useContext(I18nReactContext);
    const [languagesOptions, setLanguagesOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setLanguagesOptions(Object.keys(languagesMap).map(language => {
                return {
                    text: i18n.translate(`languages.languageKeys.${language}`),
                    value: language,
                }
            }
        ))
    }, []);


    const onSelectedLanguage = ({language}) => {
        switchLanguage({newSelectedLanguage: language})
    }

    return (
        <div className="UserIconMenu">
            <Tooltip title={i18n.translate('UserIconMenu.settings')}
                     slotProps={{
                         popper: {
                             modifiers: [
                                 {
                                     name: 'offset',
                                     options: {
                                         offset: [0, -18],
                                     },
                                 },
                             ],
                         },
                     }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{width: 32, height: 32, background: Colors['$menu-button-selected']}}/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    {i18n.translate('languages.label')}
                </MenuItem>
                <Divider/>
                {map(languagesOptions, (option) => {
                    return <MenuItem key={option.value} onClick={() => onSelectedLanguage({language: option.value})}>
                        <ListItemIcon>
                            <Language fontSize="small"/>
                        </ListItemIcon>
                        {option.text}
                    </MenuItem>
                })}
                <Divider/>
                <MenuItem onClick={handleClose}>
                    <Link to="/logout" className="logoutLink">
                        <ListItemIcon>
                            <Logout fontSize="small"/>
                        </ListItemIcon>
                        {i18n.translate('menuItems.logout')}
                    </Link>
                </MenuItem>
            </Menu>
            {/*<Language fontSize="large"/>*/}
            {/*<div className="selectBoxWrapper">*/}
            {/*    <SelectBox options={languagesOptions} value={language}*/}
            {/*               onChange={onSelectedLanguage}/>*/}
            {/*</div>*/}
        </div>
    );
}