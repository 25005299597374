import createCache from "@emotion/cache";
import {prefixer} from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import {useContext} from "react";
import {I18nReactContext} from "../../contexts/I18nContext";
import {CacheProvider} from "@emotion/react";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

const cacheLtr = createCache({
    key: 'mui',
});

export const CacheProviderWrapper = ({children}) => {
    const {isRtl} = useContext(I18nReactContext)
    return <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
        {children}
    </CacheProvider>
}