import {get} from "lodash";
import {signDocReadOrWatchMap} from "../SignDoc/SignDocLib";

export const getPageTitleAndSubTitle = ({signDoc, i18n}) => {
    const isSigned = get(signDoc, 'signed')
    const watchOrRead = signDocReadOrWatchMap[get(signDoc, 'signDoc.type')]
    const doNotForceCompletion = get(signDoc, 'signDoc.doNotForceCompletion')
    if (doNotForceCompletion) {
        return {
            title: i18n.translate(`signDocPage.title.${watchOrRead}.knowledgeOnly`),
            subTitle: null
        }
    }
    if (isSigned) {
        return {
            title: i18n.translate(`signDocPage.title.${watchOrRead}.noSign`),
            subTitle: i18n.translate(`signDocPage.subTitle.${watchOrRead}.noSign`)
        }
    }
    return {
        title: i18n.translate(`signDocPage.title.${watchOrRead}.sign`),
        subTitle: i18n.translate(`signDocPage.subTitle.${watchOrRead}.sign`)
    }

}