import './Reports.scss'
import {PageTitle} from "../PageTitle/PageTitle";
import React, {useContext, useMemo} from "react";
import {NotificationMessageButton} from "../Notifications/NotificationMessageButton/NotificationMessageButton";
import {RoutingTabs} from "../../Utils/RoutingTabs/RoutingTabs";
import {FeatureFlags} from "../../Utils/Constants";
import {SignDocsReports} from "./SignDocsReports/SignDocsReports";
import {GeneratedReports} from "../GeneratedReports/GeneratedReports";
import {SignDocTypes} from "../SignDoc/SignDocLib";
import {AdminDashboard} from "./AdminDashboard/AdminDashboard";
import {I18nReactContext} from "../../contexts/I18nContext";

const tabs = ({i18n}) => [
    {
        label: i18n.translate('ReportsPage.tabs.dashboard'),
        subPath: 'dashboard',
        element: <AdminDashboard/>,
        requiredFeatureFlags: [FeatureFlags.SIGN_DOCS_READ_AND_SIGN_DOC_PDF]
    },
    {
        label: i18n.translate('ReportsPage.tabs.courses'),
        subPath: 'courses',
        element: <SignDocsReports
            key={1}
            textType={'course'}
            types={[
                SignDocTypes.REGULAR_COURSE,
                SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK,
                SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK
            ]}/>
    },
    {
        label: i18n.translate('ReportsPage.tabs.signDocs'),
        subPath: 'signDocs',
        element: <SignDocsReports textType={'signDocs'} key={2} types={[SignDocTypes.READ_AND_SIGN_DOC_PDF]}/>,
        requiredFeatureFlags: [FeatureFlags.SIGN_DOCS_READ_AND_SIGN_DOC_PDF]
    },
    {
        label: i18n.translate('ReportsPage.tabs.generatedReports'),
        subPath: 'generatedReports',
        element: <GeneratedReports/>
    },

]
export const Reports = () => {
    const {i18n} = useContext(I18nReactContext);
    let memoizedTabs = useMemo(() => tabs({i18n}), [i18n]);


    return <div className="Reports MenuPage">
        <PageTitle title={i18n.translate('ReportsPage.title')}
                   size="small"
                   subTitle={i18n.translate('ReportsPage.subTitle')}
            // subTitleBold={i18n.translate('ReportsPage.subTitleBold')}
                   customComponent={<NotificationMessageButton/>}

        />
        <div className="menuInnerPage">
            <div className="ReportsTabWrapper">
                <RoutingTabs tabs={memoizedTabs} baseRoute={'reports'}/>
            </div>
        </div>

    </div>
}