import './GeneralSettings.scss'
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import VrpanoOutlinedIcon from "@mui/icons-material/VrpanoOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import React, {useContext, useState} from "react";
import {UploadOrgLogoModal} from "../UploadLogoModal/UploadOrgLogoModal";
import {EditNotesModal} from "../EditNotesModal/EditNotesModal";
import {EditOrgNameModal} from "../EditOrgNameModal/EditOrgNameModal";
import {UserReactContext} from "../../../contexts/UserContext";
import {I18nReactContext} from "../../../contexts/I18nContext";


export const GeneralSettings = () => {
    const [editLogoModalOpen, setEditLogoModalOpen] = useState(false)
    const [editNotesModalOpen, setEditNotesModalOpen] = useState(false)
    const [editOrgNameModalOpen, setEditOrgNameModalOpen] = useState(false)
    const userContext = useContext(UserReactContext)
    const {i18n} = useContext(I18nReactContext);

    const closeEditLogoModal = () => {
        setEditLogoModalOpen(false)
    }
    const openEditLogoModal = () => {
        setEditLogoModalOpen(true)
    }
    const openEditNotesModal = () => {
        setEditNotesModalOpen(true)
    }
    const openEditOrgNameModal = () => {
        setEditOrgNameModalOpen(true)
    }
    const closeEditNotesModal = () => {
        setEditNotesModalOpen(false)
    }
    const closeEditOrgNameModal = () => {
        setEditOrgNameModalOpen(false)
    }
    return <div className="GeneralSettings">
        <div className="ButtonsRow">
            {userContext.isManagingCompany() ? <Button color={buttonColors.GREY}

                                                       text={i18n.translate('settingsPage.buttons.updateLogo')}
                                                       icon={<VrpanoOutlinedIcon/>}
                                                       onClick={openEditLogoModal}/> : ''}
            <Button color={buttonColors.GREY}
                    icon={<BadgeOutlinedIcon/>}
                    text={i18n.translate('settingsPage.buttons.updateOrgName')}
                    onClick={openEditOrgNameModal}/>
            <Button color={buttonColors.GREY}
                    text={i18n.translate('settingsPage.buttons.updateNotes')}
                    icon={<StickyNote2OutlinedIcon/>}
                    onClick={openEditNotesModal}/>

        </div>
        <UploadOrgLogoModal
            editLogoModalOpen={editLogoModalOpen}
            closeEditLogoModal={closeEditLogoModal}
        />

        {editNotesModalOpen && <EditNotesModal
            editNotesModalOpen={editNotesModalOpen}
            closeEditNotesModal={closeEditNotesModal}/>}

        {editOrgNameModalOpen && <EditOrgNameModal
            editOrgNameModalOpen={editOrgNameModalOpen}
            closeEditOrgNameModal={closeEditOrgNameModal}/>}
    </div>
}