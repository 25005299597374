import React, {useContext, useEffect} from "react";
import './Main.scss'
import {AppSideMenu} from "../AppSideMenu/AppSideMenu";
import {useUserAdminOrgs} from "../Hooks/UseUserAdminOrgs";
import {I18nReactContext} from "../../contexts/I18nContext";

const Main = ({children, path}) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const {isRtl} = useContext(I18nReactContext);
    useEffect(() => {
        document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    }, [isRtl]);
    return (
        <div className={`root-container ${isRtl ? 'rtl' : 'ltr'}`} dir={isRtl ? 'rtl' : 'ltr'} >
            <div className="AppSideMenuWrapper">
                <AppSideMenu path={path} adminOrganizations={adminOrganizations}/>
            </div>

            <div className="MenuAndMainWrapper">
                <main
                    id={'main-container'}
                    className={`main-container`}
                >
                        {children}
                </main>
            </div>

        </div>
    );
}

export default Main;