import React, {useContext, useMemo} from 'react';
import "./LearningCenter.scss"
import {PageTitle} from "../Components/PageTitle/PageTitle";
import {FeatureFlags} from "../Utils/Constants";
import {RoutingTabs} from "../Utils/RoutingTabs/RoutingTabs";
import {MySignDocs} from "./MySignDocs/MySignDocs";
import {MyOpenTasks} from "./MyOpenTasks/MyOpenTasks";
import {SignDocTypes} from "../Components/SignDoc/SignDocLib";
import {I18nReactContext} from "../contexts/I18nContext";

const tabs = ({i18n}) => [
    {
        label: i18n.translate('learningCenter.tabs.myTasks'),
        subPath: 'myTasks',
        element: <MyOpenTasks
            showForceCompletion={true}
            showNonForceCompletion={false}
        />
    },
    {
        label: i18n.translate('learningCenter.tabs.myCourses'), subPath: 'myCourses',
        element: <MySignDocs
            referAsCourses
            showForceCompletion={true}
            showNonForceCompletion={false}
            key={1}
            types={[SignDocTypes.REGULAR_COURSE, SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK, SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK, SignDocTypes.CANVA_PRESENTATION]}/>
    },
    {
        label: i18n.translate('learningCenter.tabs.mySignDocs'),
        subPath: 'mySignDocs',
        element: <MySignDocs
            showForceCompletion={true}
            showNonForceCompletion={false}
            key={2}
            types={[SignDocTypes.READ_AND_SIGN_DOC_PDF]}/>,
        requiredFeatureFlags: [FeatureFlags.SIGN_DOCS_READ_AND_SIGN_DOC_PDF]
    },
    // { label: i18n.translate('settingsPage.users.title'), subPath: 'users', element: <Box>Users</Box> },

]
export const LearningCenter = () => {
    const {i18n} = useContext(I18nReactContext)
    const memoizedTabs = useMemo(() => tabs({ i18n }), [i18n]);

    return <div className="LearningCenter MenuPage">
        <PageTitle title={i18n.translate('learningCenter.title')} subTitle={i18n.translate('learningCenter.subTitle')}/>
        <div className="menuInnerPage">
            <div className="learningCenterTabWrapper">
                <RoutingTabs tabs={memoizedTabs} baseRoute={'learningCenter'}/>
            </div>
        </div>

    </div>
}

