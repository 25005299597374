import {Navigate} from "react-router-dom";
import React, {useContext} from "react";
import Main from "../Main/Main";
import {UserReactContext} from "../../contexts/UserContext";
import {I18nReactContext} from "../../contexts/I18nContext";

export const PrivateRoute = ({component: Component, rest, context, path, requiredPermissions, additionalProps}) => {
    const {i18n} = useContext(I18nReactContext);
    const userContext = useContext(UserReactContext)
    if (!userContext.isUserValid()) {
        alert(i18n.translate('errors.privateRouteUserNotLoggedIn'))
        return <Navigate to={{pathname: `/login`}}/>
    }
    if (userContext.isPermissionsLoaded() && requiredPermissions && !userContext.canUserPerformAction(requiredPermissions, 'or')) {
        return <div>{i18n.translate('permissions.actionNotAllowed')}</div>
    } else if (context === 'standAlone') {
        return <>
            <Component path={path} key={userContext.isUserValid()}/>
        </>
    } else {
        return <Main path={path}>
            <Component path={path} key={userContext.isUserValid()}/>
        </Main>
    }
}

