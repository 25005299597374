import './DeleteUsersTableButton.scss'
import {buttonColors} from "../Pickers/InputTheme";
import {Button} from "../Form/Button/Button";
import {useContext, useState} from "react";
import {GridReactContext} from "../../Utils/DataGridWrapper/GridContext";
import {DeleteUserConfirmationModal} from "./DeleteUserConfirmationModal/DeleteUserConfirmationModal";
import {I18nReactContext} from "../../contexts/I18nContext";

export const DeleteUsersTableButton = ({onSuccess}) => {
    const {i18n} = useContext(I18nReactContext);
    const [deleteUserConfirmationModalOpen, setDeleteUserConfirmationModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext)
    const onConfirmationModalClose = () => {
        setDeleteUserConfirmationModalOpen(false)
    }
    const onClick = () => {
        setDeleteUserConfirmationModalOpen(true)
    }

    const innerOnSuccess = () => {
        setDeleteUserConfirmationModalOpen(false)
        gridContext.setSelectionModelToContext([])
        gridContext.triggerTableRefresh()
        onSuccess && onSuccess()
    }

    return <div>
        <Button
            disabled={!gridContext.selectionModel || gridContext.selectionModel.length === 0}
            color={buttonColors.GREY}
            onClick={onClick}
            text={i18n.translate('superAdmin.allUsersTable.buttons.deleteUsers')}
        />
        {deleteUserConfirmationModalOpen ?
            <DeleteUserConfirmationModal deleteUserConfirmationModalOpen={deleteUserConfirmationModalOpen}
                                         onCloseDeleteUserConfirmationModal={onConfirmationModalClose}
                                         onSuccess={innerOnSuccess}
            /> : ''}
    </div>
}