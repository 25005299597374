import './CompleteCourseForm.scss'
import React, {useContext, useEffect} from "react";
import {I18nReactContext} from "../../contexts/I18nContext";
import {PageTitle} from "../PageTitle/PageTitle";
import {completeCourseAPI, getSignDocApi} from "../../Services/api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {buttonColors} from "../Pickers/InputTheme";
import {Button} from "../Form/Button/Button";
import {get} from "lodash";
import {Navigate} from "react-router-dom";
import {Confetti} from "../Smart/Confetti/Confetti";

export const CompleteCourseForm = () => {
    const [isLoading, setIsLoading] = React.useState(false)
    const {i18n} = useContext(I18nReactContext);
    const [signDoc, setSignDoc] = React.useState({})
    const [isCompleted, setIsCompleted] = React.useState(false)
    const [youGotLost, setYouGotLost] = React.useState(false)
    const [showConfetti, setShowConfetti] = React.useState(false)
    const init = async () => {
        setIsLoading(true)
        const courseIdFromLocalStorage = typeof window !== undefined && window.localStorage.getItem("CompletedCourse")
        if (!courseIdFromLocalStorage) {
            setYouGotLost(true)
            setIsLoading(false)
            return
        }
        window !== undefined && window.localStorage.removeItem("CompletedCourse")
        const newSignDoc = await getSignDocApi({signDocId: courseIdFromLocalStorage})
        if (get(newSignDoc, 'userSignDocs.progressPercent') === 100) {
            setIsCompleted(true)
        }
        setSignDoc(newSignDoc)
        setIsLoading(false)
    }
    useEffect(() => {
        init()
    }, []);

    useEffect(() => {
        if(signDoc && signDoc.id && !isCompleted && !youGotLost){
            confirmCourseCompletion()
        }
    }, [youGotLost, isCompleted, signDoc])

    useEffect(() => {
        if (showConfetti) {
            setTimeout(() => {
                setIsCompleted(true)
            }, 5000)
        }
    }, [showConfetti]);
    const doNotForceCompletion = get(signDoc, 'doNotForceCompletion')
    const confirmCourseCompletion = async () => {
        setIsLoading(true)
        const completedCourse = await completeCourseAPI({signDocId: signDoc.id})
        if (completedCourse.progressPercent === 100) {
            setIsLoading(false)
            setShowConfetti(true)
        }
        setIsLoading(false)
    }

    return <div className="CompleteCourseForm MenuPage">
        {isLoading ? <LoadingSpinner/> : ""}
        {!youGotLost ? <PageTitle
            title={i18n.translate('completedCoursePage.title')}
            subTitle={i18n.translate('completedCoursePage.finishedSuccessfully')}
            subTitleBold={signDoc.docName}/> :
            <PageTitle
                title={i18n.translate('completedCoursePage.oops')}
                subTitle={i18n.translate('completedCoursePage.youGotLost')}/>
        }

        {isCompleted || doNotForceCompletion ? <Navigate to={{pathname: '/learningCenter/myTasks'}} replace={true}/> : ""}
        <Confetti showConfetti={showConfetti}/>

    </div>
}